const prodApi = !(window).webpackHotUpdate;

export default class API {
    static getBaseUrl() {
        if (prodApi) {
            return "https://api.aixdesign.space";
        }  else {
            return "https://aixd-api.lab.oldsen.cloud";
        }
    }
}
