<template>
    <div id="landing-page-root">
        <div class="content-box full-page" id="creative-kitchen">
            <img id="headline-hex" src="@/assets/hexagon.svg">
            <img id="bottom-hex" src="@/assets/hexagon.svg">

            <div id="page-headline-container">
                <div>Creative <br> Kitchen</div>
                <div>Cooking the future</div>
                <div id="by-aixd">
                    <div>{{ $t("by_aixdesign") }} <router-link to="/about">aiXDesign</router-link></div>
                </div>
            </div>

            <a href="#contact-section"><img id="arrow-down" src="@/assets/arrow-down-yellow.svg"></a>
        </div>

        <div class="content-box content-box-blue full-page" id="contact-section">

            <div id="blue-text">
                {{ $t("blue_text_p1") }}<br><br>
                {{ $t("blue_text_p2") }}<br><br>
                {{ $t("blue_text_p3") }}<br><br>
                <a id="blue-text-link" href="">{{ $t("blue_text_link_text") }}</a>
            </div>

            <div id="button-container-wrapper">
                <div id="button-container-headline">Contact us!</div>

                <div id="button-container">
                    <div class="button-wrapper">
                        <div class="button-headline"></div>

                        <a href="mailto:mail@aixdesign.space" class="whats-cooking-button" target="_blank">
                            <svg id="button-svg" width="5.4413mm" height="6.6757mm" version="1.1" viewBox="0 0 5.4413 6.6757" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
                                <g transform="translate(-56.445 -73.109)" fill="none" stroke="#fff" stroke-width=".3">
                                    <path d="m56.628 74.795v3.2908l2.752 1.5398"/>
                                    <path id="upper-path" d="m56.548 74.941 2.6101-1.6203 2.6393 1.47"/>
                                    <path id="lower-path" d="m59.197 79.622 2.5091-1.3735"/>
                                </g>
                            </svg>
                            <div class="button-text"><img src="@/assets/mail-icon-white.svg">mail(at)aixdesign.space</div>
                        </a>
                    </div>

                    <div class="button-wrapper">
                        <div class="button-headline"></div>

                        <a href="https://www.instagram.com/aixdesign.space/" class="whats-cooking-button" target="_blank">
                            <svg id="button-svg" width="5.4413mm" height="6.6757mm" version="1.1" viewBox="0 0 5.4413 6.6757" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
                                <g transform="translate(-56.445 -73.109)" fill="none" stroke="#fff" stroke-width=".3">
                                    <path d="m56.628 74.795v3.2908l2.752 1.5398"/>
                                    <path id="upper-path" d="m56.548 74.941 2.6101-1.6203 2.6393 1.47"/>
                                    <path id="lower-path" d="m59.197 79.622 2.5091-1.3735"/>
                                </g>
                            </svg>
                            <div class="button-text"><img src="@/assets/instagram-logo-white.svg">Instagram</div>
                        </a>
                    </div>

                    <div class="button-wrapper">
                        <div class="button-headline"></div>

                        <a href="https://twitter.com/aixdesign_space" class="whats-cooking-button" target="_blank">
                            <svg id="button-svg" width="5.4413mm" height="6.6757mm" version="1.1" viewBox="0 0 5.4413 6.6757" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
                                <g transform="translate(-56.445 -73.109)" fill="none" stroke="#fff" stroke-width=".3">
                                    <path d="m56.628 74.795v3.2908l2.752 1.5398"/>
                                    <path id="upper-path" d="m56.548 74.941 2.6101-1.6203 2.6393 1.47"/>
                                    <path id="lower-path" d="m59.197 79.622 2.5091-1.3735"/>
                                </g>
                            </svg>
                            <div class="button-text"><img src="@/assets/twitter-icon-white.svg">Twitter</div>
                        </a>
                    </div>
                </div>
            </div>

        </div>

        <!-- anchor -->
        <div id="workshops"></div>

        <div class="content-box workshop-head-box">

            <div class="workshop-wrapper" v-for="(workshop, index) in $store.state.workshops"
                 :key="index">
                <div class="workshop-box">
                    <div class="workshop-headline">
                        <div class="workshop-headline-container">
                            <div>cooking</div>
                            <a :class="{clickable: workshop.recipeId}" :href="'/recipe/' + recipeSlugMapping[workshop.recipeId]">{{ workshop.name }}</a>
                        </div>

                        <div class="workshop-headline-container workshop-subheadline" v-if="workshop.place">
                            <div><div>@</div> {{ workshop.place }}</div>
                        </div>
                    </div>

                    <div class="workshop-text">
                         {{ $store.state.lang === 'de' ? workshop.mainText : workshop.mainTextEn }}
                    </div>

                </div>

                <div class="video-container">
                    <video class="video" loop muted ref="wsVideo">
                        <source :src="workshop.videoUrl" :type="workshop.videoType">
                    </video>

                    <div class="cooking-bubble-container-1">
                        <div class="cooking-bubble workshop-bubble" v-for="(resultImg, index) in workshop.workshopResults" :key="index">
                            <img :src="resultImg">
                            <div></div>
                        </div>
                    </div>
                </div>

                <img v-if="index % 2 == 0" class="workshop-first-hexagon" src="@/assets/hexagon.svg">
                <img v-if="index % 2 == 0" class="workshop-sec-hexagon" src="@/assets/hexagon.svg">

                <img v-if="index % 2 == 1" class="workshop-fourth-hexagon" src="@/assets/hexagon.svg">
            </div>

            <div class="workshop-wrapper credit-wrapper">
                <div id="workshop-credit-container">
                    <div>Book your<br>workshop</div>
                    <div>Drop us a line and lets get things boiling</div>
                    <div id="social-media-wrapper">

                        <a href="mailto:mail@aixdesign.space" class="whats-cooking-button" target="_blank">
                            <svg id="button-svg" width="5.4413mm" height="6.6757mm" version="1.1" viewBox="0 0 5.4413 6.6757" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
                                <g class="blue-link-stroke" transform="translate(-56.445 -73.109)" fill="none" stroke="#0000ff" stroke-width=".3">
                                    <path d="m56.628 74.795v3.2908l2.752 1.5398"/>
                                    <path id="upper-path" d="m56.548 74.941 2.6101-1.6203 2.6393 1.47"/>
                                    <path id="lower-path" d="m59.197 79.622 2.5091-1.3735"/>
                                </g>
                            </svg>
                            <div class="button-text button-text-blue"><img class="blue-link-img" src="@/assets/social-media/mail.svg">E-Mail</div>
                        </a>

                        <a href="https://www.instagram.com/aixdesign.space/" class="whats-cooking-button" target="_blank">
                            <svg id="button-svg" width="5.4413mm" height="6.6757mm" version="1.1" viewBox="0 0 5.4413 6.6757" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
                                <g class="blue-link-stroke" transform="translate(-56.445 -73.109)" fill="none" stroke="#0000ff" stroke-width=".3">
                                    <path d="m56.628 74.795v3.2908l2.752 1.5398"/>
                                    <path id="upper-path" d="m56.548 74.941 2.6101-1.6203 2.6393 1.47"/>
                                    <path id="lower-path" d="m59.197 79.622 2.5091-1.3735"/>
                                </g>
                            </svg>
                            <div class="button-text button-text-blue"><img class="blue-link-img" src="@/assets/social-media/instagram.svg">Instagram</div>
                        </a>

                        <a href="https://twitter.com/aixdesign_space" class="whats-cooking-button" target="_blank">
                            <svg id="button-svg" width="5.4413mm" height="6.6757mm" version="1.1" viewBox="0 0 5.4413 6.6757" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
                                <g class="blue-link-stroke" transform="translate(-56.445 -73.109)" fill="none" stroke="#0000ff" stroke-width=".3">
                                    <path d="m56.628 74.795v3.2908l2.752 1.5398"/>
                                    <path id="upper-path" d="m56.548 74.941 2.6101-1.6203 2.6393 1.47"/>
                                    <path id="lower-path" d="m59.197 79.622 2.5091-1.3735"/>
                                </g>
                            </svg>
                            <div class="button-text button-text-blue"><img class="blue-link-img" src="@/assets/social-media/twitter.svg">Twitter</div>
                        </a>

                    </div>
                </div>
            </div>

        </div>

        <div id="cookbook-head" class="content-box content-box-blue hex-bg">

            <div class="small-page-headline recap-headline">Cookbook</div>

            <div id="cookbook" class="four-card-wrapper">
                <div class="two-card-wrapper" v-for="(row, index) in recipeRows" :key="index">
                    <div class="cooking-card-wrapper" v-for="recipe in row" :key="recipe.id" @click="$router.push('/recipe/' + recipe.slug)">
                        <div class="cooking-card">
                            <div>{{recipe.title}}</div>
                            <div>
                                <div class="diff">
                                    <svg class="diff-dot dot-1" width="2.785mm" height="3.2mm" viewBox="0 0 2.785 3.2" xmlns="http://www.w3.org/2000/svg">
                                        <g transform="translate(-71.047 -51.817)">
                                            <path transform="matrix(.28474 -.046628 .046628 .28474 48.184 40.638)" d="m79.961 60.694-5.0249 1.8998-4.1577-3.4018 0.86716-5.3016 5.0249-1.8998 4.1577 3.4018z" fill="#0000ffff" stroke="#00f" stroke-linejoin="round" stroke-width=".7"></path>
                                        </g>
                                    </svg>
                                    <svg class="diff-dot dot-2" width="2.785mm" height="3.2mm" viewBox="0 0 2.785 3.2" xmlns="http://www.w3.org/2000/svg">
                                        <g transform="translate(-71.047 -51.817)">
                                            <path transform="matrix(.28474 -.046628 .046628 .28474 48.184 40.638)" d="m79.961 60.694-5.0249 1.8998-4.1577-3.4018 0.86716-5.3016 5.0249-1.8998 4.1577 3.4018z" :fill="recipe.codeLevel < 1 ? '#fff' : '#0000ffff'" stroke="#00f" stroke-linejoin="round" stroke-width=".7"></path>
                                        </g>
                                    </svg>
                                    <svg class="diff-dot dot-3" width="2.785mm" height="3.2mm" viewBox="0 0 2.785 3.2" xmlns="http://www.w3.org/2000/svg">
                                        <g transform="translate(-71.047 -51.817)">
                                            <path transform="matrix(.28474 -.046628 .046628 .28474 48.184 40.638)" d="m79.961 60.694-5.0249 1.8998-4.1577-3.4018 0.86716-5.3016 5.0249-1.8998 4.1577 3.4018z" :fill="recipe.codeLevel < 2 ? '#fff' : '#0000ffff'" stroke="#00f" stroke-linejoin="round" stroke-width=".7"></path>
                                        </g>
                                    </svg>
                                </div>
                                <div class="diff-font">{{ recipe.codeLevel === 0 ? "No Code" : (recipe.codeLevel === 1 ? "Low Code" : "Advanced Code") }}</div>
                            </div>
                            <div v-snip="5">{{ recipe.desc }}</div>
                        </div>
                        <img class="cooking-card-img" :src="recipe.images[0]">
                    </div>
                </div>
            </div>

        </div>

        <div class="content-box about-content-box" id="about-us-section">
            <div class="about-grid-bg">
                <img src="@/assets/grid-about.svg">
            </div>

            <div class="about-real-content">
                <div class="sticky-header-box">
                    <div class="headline-blue">About us</div>
                </div>

                <div class="about-col-wrapper">
                    <div class="about-small-box">{{ $t("aboutus-headline-1") }}</div>

                    <div class="about-text-box">
                        <div class="about-text">
                            {{ $t("aboutus-text-1") }}
                        </div>
                    </div>

                    <div class="about-small-box">{{ $t("aboutus-headline-2") }}</div>

                    <div class="about-text-box">
                        <div class="about-text">
                           {{ $t("aboutus-text-2") }}
                        </div>
                    </div>

                    <div class="about-small-box">{{ $t("aboutus-headline-3") }}</div>

                    <div class="about-text-box">
                        <div class="about-text">
                           {{ $t("aboutus-text-3") }}
                        </div>
                    </div>

                    <div class="about-small-box">{{ $t("aboutus-headline-4") }}</div>

                    <div class="about-text-box">
                        <div class="about-text">
                            {{ $t("aboutus-text-4") }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-box content-box-long">
            <img src="@/assets/single-hexagon.svg" id="aboutus-hex-1">
            <img src="@/assets/single-hexagon-cut.svg" id="aboutus-hex-2">

            <div class="small-page-headline headline-blue headline-aboutus-team">Meet the <br> Kitchen Team</div>

            <div id="aboutus-team-wrapper">
                <div class="cooking-bubble aboutus-bubble">
                    <img src="@/assets/team/tom.jpg"><div></div><div>Tom-Lucas Säger</div>
                </div>

                <div class="cooking-bubble aboutus-bubble">
                    <img src="@/assets/team/katharina.png"><div></div><div>Katharina Mumme</div>
                </div>

                <div class="cooking-bubble aboutus-bubble">
                    <img src="@/assets/team/lennart.jpg"><div></div><div>Lennart Schmidt</div>
                </div>

                <div class="cooking-bubble aboutus-bubble">
                    <img src="@/assets/team/benjamin.jpg"><div></div><div>Benjamin Bertram</div>
                </div>

                <div class="cooking-bubble aboutus-bubble">
                    <img src="@/assets/team/peter.jpg"><div></div><div>Prof. Peter Kabel</div>
                </div>

                <div class="cooking-bubble aboutus-bubble">
                    <img src="@/assets/team/sarah.png"><div></div><div>Sarah Pfeiffer</div>
                </div>

                <div class="cooking-bubble aboutus-bubble">
                    <img src="@/assets/team/hauke.jpg"><div></div><div>Hauke Oldsen</div>
                </div>

                <div class="cooking-bubble aboutus-bubble">
                    <img src="@/assets/team/kim.png"><div></div><div>Kim Lassen</div>
                </div>

                <div class="cooking-bubble aboutus-bubble">
                    <img src="@/assets/team/bello.jpg"><div></div><div>Safian Bello</div>
                </div>
            </div>
        </div>

        <div id="recap-wrapper">
            <div class="small-page-headline recap-headline">Recap</div>

            <div id="recap-button-wrapper">

                <div class="button-wrapper recap-button">
                    <router-link to="/shuttleevents" class="whats-cooking-button" target="_blank">
                        <svg id="button-svg" width="5.4413mm" height="6.6757mm" version="1.1" viewBox="0 0 5.4413 6.6757">
                            <g transform="translate(-56.445 -73.109)" fill="none" stroke="#fff" stroke-width=".3">
                                <path d="m56.628 74.795v3.2908l2.752 1.5398"/>
                                <path id="upper-path" d="m56.548 74.941 2.6101-1.6203 2.6393 1.47"/>
                                <path id="lower-path" d="m59.197 79.622 2.5091-1.3735"/>
                            </g>
                        </svg>
                        <div class="button-text">Shuttleevents</div>
                    </router-link>
                    <div class="button-headline">In the past we did a lot of cool stuff with other people. Check this out!</div>
                </div>

                <div class="button-wrapper recap-button">
                    <router-link to="/interview" class="whats-cooking-button" target="_blank">
                        <svg id="button-svg" width="5.4413mm" height="6.6757mm" version="1.1" viewBox="0 0 5.4413 6.6757">
                            <g transform="translate(-56.445 -73.109)" fill="none" stroke="#fff" stroke-width=".3">
                                <path d="m56.628 74.795v3.2908l2.752 1.5398"/>
                                <path id="upper-path" d="m56.548 74.941 2.6101-1.6203 2.6393 1.47"/>
                                <path id="lower-path" d="m59.197 79.622 2.5091-1.3735"/>
                            </g>
                        </svg>
                        <div class="button-text">Interview</div>
                    </router-link>
                    <div class="button-headline">What do you think about AI and Design? Participate in our interview!</div>
                </div>
            </div>

        </div>

        <div class="x-hexagon-container" @click="navState = !navState">
            <svg class="x-hexagon" id="x-hexagon-0" :class="{open: navState}" xmlns="http://www.w3.org/2000/svg" width="67.866" height="78.368" viewBox="0 0 67.866 78.368">
                <path  data-name="Path 201" d="M179.994,48.334V10.882L147.561-7.844,115.128,10.882V48.334L147.561,67.06Z" transform="translate(-113.628 9.576)" fill="none" stroke="#ffff00" stroke-miterlimit="10" stroke-width="3"/>
            </svg>
            <svg class="x-hexagon" id="x-hexagon-1" :class="{open: navState}" xmlns="http://www.w3.org/2000/svg" width="67.866" height="78.368" viewBox="0 0 67.866 78.368">
                <path  data-name="Path 201" d="M179.994,48.334V10.882L147.561-7.844,115.128,10.882V48.334L147.561,67.06Z" transform="translate(-113.628 9.576)" fill="none" stroke="#ffff00" stroke-miterlimit="10" stroke-width="3"/>
            </svg>
            <svg class="x-hexagon" id="x-hexagon-2" :class="{open: navState}" xmlns="http://www.w3.org/2000/svg" width="67.866" height="78.368" viewBox="0 0 67.866 78.368">
                <path  data-name="Path 201" d="M179.994,48.334V10.882L147.561-7.844,115.128,10.882V48.334L147.561,67.06Z" transform="translate(-113.628 9.576)" fill="none" stroke="#ffff00" stroke-miterlimit="10" stroke-width="3"/>
            </svg>
            <svg id="x-triangle" :class="{open: navState}" xmlns="http://www.w3.org/2000/svg" width="70.326" height="60.643" viewBox="0 0 70.326 60.643">
                <path data-name="Path 334" d="M-24532.578-12339.595l32.578,56.152,32.541-56.152Z" transform="translate(24535.182 12341.095)" fill="none" stroke="#ffff00" stroke-width="3"/>
            </svg>
        </div>

        <div class="cooking-nav" :style="{transform: navState ? 'none' : null}">
            <a id="nav-headline" href="#creative-kitchen">Creative Kitchen</a>

            <div id="nav-item-wrapper">
                <a class="cooking-nav-item" href="#contact-section" @click="navState = false">Contact</a>
                <a class="cooking-nav-item" href="#workshops" @click="navState = false">Workshops</a>
                <a class="cooking-nav-item" href="#cookbook-head" @click="navState = false">Cookbook</a>
                <a class="cooking-nav-item" href="#about-us-section" @click="navState = false">About Us</a>
                <a class="cooking-nav-item" href="#recap-wrapper" @click="navState = false">Recap</a>
            </div>

            <div id="lang-wrapper">

                <div class="language-hexagon" @click="$store.commit('setLang', 'de')" :class="{active: $store.state.lang === 'de'}">
                    <div class="language">DE</div>
                    <svg width="15.08mm" height="17.285mm" viewBox="0 0 15.08 17.285">
                        <g transform="translate(-24.232 -4.0164)">
                            <path class="hex-path" transform="rotate(.6 31.772 12.659)" d="m38.955 16.701-7.0923 4.1993-7.1829-4.0425-0.09057-8.2418 7.0923-4.1993 7.1829 4.0425z" fill="#00f" opacity="1" stroke="#00f" stroke-linejoin="round" stroke-width=".8"/>
                        </g>
                    </svg>
                </div>

                <div class="language-hexagon" @click="$store.commit('setLang', 'en')" :class="{active: $store.state.lang === 'en'}">
                    <div class="language">EN</div>
                    <svg width="15.08mm" height="17.285mm" viewBox="0 0 15.08 17.285">
                        <g transform="translate(-24.232 -4.0164)">
                            <path class="hex-path" transform="rotate(.6 31.772 12.659)" d="m38.955 16.701-7.0923 4.1993-7.1829-4.0425-0.09057-8.2418 7.0923-4.1993 7.1829 4.0425z" fill="#00f" opacity="1" stroke="#00f" stroke-linejoin="round" stroke-width=".8"/>
                        </g>
                    </svg>
                </div>

            </div>

        </div>
        <div class="cooking-nav-overlay" :class="{active: navState}" @click="navState = false"></div>

        <div id="footer">
            <div class="footer-icons-wrapper">
                <a target="_blank" class="footer-link" href="https://www.instagram.com/aixdesign.space/"><img class="footer-icon" src="@/assets/social-media/instagram.svg"></a>
                <a target="_blank" class="footer-link" href="https://twitter.com/aixdesign_space"><img class="footer-icon" src="@/assets/social-media/twitter.svg"></a>
                <a target="_blank" class="footer-link" href="mailto:mail@aixdesign.space"><img class="footer-icon" src="@/assets/social-media/mail.svg"></a>
            </div>

            <div class="footer-icons-wrapper">
                <a target="_blank" class="footer-link" href="https://www.haw-hamburg.de/">haw-hamburg</a>
                <a target="_blank" class="footer-link" href="https://design.haw-hamburg.de/home/das-department-design/">department-design</a>
                <router-link class="footer-link" to="/imprint">Datenschutz</router-link>
                <router-link class="footer-link" to="/imprint">Impressum</router-link>
            </div>

        </div>

        <div v-for="(bubble, index) in $store.state.bubbles" :key="index" class="bubble-flowbox" :style="{
            animationDelay: (-(80 / 13) * index) + 's',
            animationDuration: bubble.aDuration,
            animationName: index % 2 === 0 ? 'bubble-animation' : 'bubble-animation-2'
            }">
            <div class="bubble" :style="{animationDuration: bubble.aDuration2, animationDelay: (-3 * index) + 's'}">
                <img :src="bubble.img" v-if="bubble.img">
                <video v-if="bubble.video" autoplay muted loop>
                    <source :type="bubble.videoType" :src="bubble.video">
                </video>
                <div>
                    <div class="bubble-element">
                        <div class="bubble-link-headline"></div>
                        <a class="bubble-link" href=""></a>
                    </div>
                    <div class="bubble-element">
                        <div class="bubble-link-headline">at workshop</div>
                        <a class="bubble-link" href="">CodeWeek Hamburg</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NewsletterDialog from "@/components/NewsletterDialog";
import Dialog from "@/components/Dialog";
import Imprint from "@/views/Imprint";

export default {
    components: {Imprint, NewsletterDialog, Dialog},
    data: function () {
        return {
            navState: false,
            recipes: [],
            cookbookPopupState: false,
            selectedRecipe: "",
        };
    },
    mounted() {
        const aboutBoxes = document.querySelectorAll(".about-text-box");
        const smallBoxes = document.querySelectorAll(".about-small-box");

        for (let i = 0; i < aboutBoxes.length; i++) {
            const aboutBox = aboutBoxes[i];
            const smallBox = smallBoxes[i];

            smallBox.style.transform = "translate(" + i + "rem, " + i + "rem)";
            aboutBox.style.transform = "translate(" + i + "rem, " + i + "rem)";
        }


        document.body.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
        document.body.removeEventListener("scroll", this.handleScroll);
    },
    computed: {
        recipeRows() {
            const all = this.$store.state.recipes;
            const rows = [];
            let current = [];

            if(!all) {
                return [];
            }

            for(const recipe of all) {
                current.push(recipe);

                if (current.length === 2) {
                    rows.push(current);
                    current = [];
                }
            }

            if (current.length > 0) {
                rows.push(current);
            }

            return rows;
        },
        recipeSlugMapping() {
            const all = this.$store.state.recipes;
            let mapping = {};

            for (const r of all) {
                mapping[r.id] = r.slug;
            }

            return mapping;
        }
    },
    methods: {
        handleScroll() {
            for (const video of this.$refs.wsVideo) {
                const rect = video.getBoundingClientRect();

                const inViewport = (
                    rect.top >= 0 &&
                    rect.top <= (window.innerHeight || document.documentElement.clientHeight)
                ) || (
                    rect.bottom >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
                );

                if (inViewport) {
                    video.play();
                } else {
                    video.pause();
                }
            }
        }
    }
};
</script>

<style lang="scss">

html, body {
    overflow-x: hidden;
    scroll-behavior: smooth;
}

#landing-page-root {
    background-color: white;
    font-family: 'MuseoModerno', cursive;
}

#creative-kitchen {
    height: 100vh;
}

.link {
    color: #184380;
    transition: color .25s ease-in-out;
}

.link:hover {
    color: black;
}

.content-master {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.content-box {
    position: relative;
    width: 100%;
    font-size: 1.1rem;
}

.content-box.full-page {
    overflow: hidden;
}


.content-box-long {
    overflow-x: hidden;
}

/*
.content-box-long::-webkit-scrollbar {
    display: none;
  }
*/

.content-box-scroll-right {
    display: flex;
    overflow-x: initial;
}

.content-box-scroll-right-blue {
    display: flex;
    overflow-x: initial;
    background-color: blue;
}

.content-box-blue {
    padding: 5.5rem 8.3rem;
    position: relative;
    box-sizing: border-box;
    background-color: blue;
    color: white;
    z-index: 2;
}

@media screen and (max-width: 50rem) {
    .content-box-blue {
        padding: 6rem 6rem;
    }
}

@media screen and (max-width: 500px) {
    .content-box-blue {
        padding: 3.5rem 2rem;
    }
}

.cookbook-headline {
    padding-top: 3.4rem;
    padding-left: 8.3rem;
    line-height: 4.5rem;
    font-size: 3.8rem;
    font-weight: 500;
    color: blue;
}

#headline-hex {
    position: absolute;
    top: -13rem;
    right: -2rem;
    width: 60rem;
}

@media screen and (max-width: 100rem) {
    #headline-hex {
        top: -8rem;
        right: -4rem;
        width: 45rem;
    }
}

@media screen and (max-width: 80rem) {
    #headline-hex {
        top: 16rem;
        right: -10rem;
        width: 35rem;
    }
}

@media screen and (max-width: 50rem) {
    #headline-hex {
        width: 30rem;
        right: -17rem;
    }
}

@media screen and (max-width: 35rem) {
    #headline-hex {
        display: none;
    }
}

#bottom-hex {
    position: absolute;
    left: -25rem;
    bottom: -38rem;
    width: 43rem;
}

@media screen and (max-width: 35rem) {
    #bottom-hex {
        width: 30rem;
        left: -15rem;
        bottom: -23rem;
    }
}

#page-headline-container {
    padding-left: 8.3rem;
    padding-top: 6vh;
    font-size: 9rem;
    line-height: 105%;
    font-weight: 200;
    z-index: 5;
    position: relative;
    color: blue;
}

@media screen and (max-width: 50rem) {
    #page-headline-container {
        padding-left: 6rem;
        padding-top: 10rem;
        font-size: 8rem;
    }
}

@media screen and (max-width: 700px) {
    #page-headline-container {
        padding-left: 0;
        padding-top: 7rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
        font-size: 5rem;
    }
}


#page-headline-container > div:nth-child(2) {
    margin-top: .5rem;
    margin-left: .7rem;
    font-size: 2.5rem;
    font-weight: 600;
    line-height: initial;
}

@media screen and (max-width: 700px) {
    #page-headline-container > div:nth-child(2) {
        margin-left: 0;
    }
}

@media screen and (max-width: 700px) {
    #page-headline-container > div:nth-child(3) {
        margin-left: 0;
    }
}

@media screen and (max-width: 65rem) {
    #page-headline-container {
    font-size: 6.5rem;
    line-height:  7rem;
    }

    #page-headline-container > div:nth-child(2) {
    font-size: 1.8rem;
    }
}

@media screen and (max-width: 35rem) {
    #page-headline-container {
    font-size: 5rem;
    line-height:  5.5rem;
    }

    #page-headline-container > div:nth-child(2) {
    font-size: 1.6rem;
    }
}

.sticky-header-box {
    position: sticky;
    top: -1.5rem;
    bottom: 30rem;
    z-index: 3;

    @media screen and (max-width: 850px) {
        position: relative;
        bottom: auto;
        top: auto;
        display: flex;
        justify-content: center;
    }

    .headline-blue {
        margin-left: 8.3rem;

        @media screen and(max-width: 850px) {
            margin: 0;
            padding-left: 0;
        }
    }
}

.about-grid-bg {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        margin-top: -5rem;
        opacity: .45;
    }
}

.about-col-wrapper {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-row-gap: 30vh;
    padding-top: 10rem;

    @media screen and (max-width: 850px) {
        grid-template-columns: 1fr;
        grid-row-gap: 0;
        padding-top: 0;
    }
}

.about-small-box {
    position: sticky;
    top: 13rem;

    width: 300px;
    height: 300px;
    color: blue;
    border: 0.18rem solid blue;
    font-size: 1.3rem;
    padding: 1rem;
    box-sizing: border-box;
    justify-self: right;
    background: white;
    overflow: visible;
    margin-right: 0.2rem;
    margin-left: 1rem;
    z-index: 2;

    &::after {
        position: absolute;
        content: "";
        background-color: white;
        top: 2rem;
        bottom: 0;
        right: 0;
        width: 0.4rem;
        transform: translateX(50%);
    }

    @media screen and (max-width: 850px) {
        position: relative;
        margin: 5rem 0.5rem 0 0.5rem;
        padding: 4rem 2rem 5rem 2rem;
        height: auto;
        font-size: 1.6rem;
        transform: none !important;
        width: initial;
        top: auto;

        &::after {
            left: 1.2rem;
            right: 1.2rem;
            bottom: 0;
            top: auto;
            width: auto;
            height: 0.4rem;
            transform: translateY(50%);
        }
    }
}

.about-text-box {
    display: flex;
    flex-direction: column;
    position: relative;

    @media screen and (max-width: 850px) {
        transform: none !important;
        margin-top: -2rem;
        z-index: 2;
    }

    .about-text-header {
        font-size: 2.1rem;
        font-family: 'MuseoModerno', cursive;
        margin-bottom: 2rem;
        color: blue;
    }

    .about-text {
        font-family: 'Syne Mono', cursive;
        padding-right: 30%;
        padding-top: 2rem;
        line-height: 1.9rem;
        font-size: 1.3rem;
        color: blue;

        @media screen and (max-width: 1600px) {
            padding-right: 15%;
        }

        @media screen and (max-width: 1300px) {
            padding-right: 5rem;
        }

        @media screen and (max-width: 850px) {
            padding: 0 2rem;
        }
    }
}

.bubble-flowbox {
    top: 100vh;
    right: -15rem;
    width: 27rem;
    height: 100%;
    transform: scale(100%, 100%) translate(0, 0);
    position: absolute;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    pointer-events: none;
    z-index: 2;
}

@media screen and (max-width: 1000px) {
    .bubble-flowbox {
        width: 20rem;
    }
}

@media screen and (max-width:50rem) {
    .bubble-flowbox {
        display: none;
    }
}

@keyframes bubble-animation {
    0% {
        opacity: 0;
        transform: scale(20%, 20%) translate(0, 0);
    }

    3% {
        opacity: 1;
    }

    97% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: scale(95%, 95%) translate(calc(-60vw - 15rem), calc(-20rem - 200vh));
    }
}

@media screen and (max-width: 70rem) {
    @keyframes bubble-animation {
        0% {
            opacity: 0;
            transform: scale(20%, 20%) translate(0, 0);
        }

        3% {
            opacity: 1;
        }

        97% {
            opacity: 1;
        }

        100% {
            opacity: 0;
            transform: scale(95%, 95%) translate(calc(-40vw - 15rem), calc(-20rem - 200vh));
        }
    }
}

@keyframes bubble-animation-2 {
    0% {
        opacity: 0;
        transform: scale(15%, 15%) translate(0, 0);
    }

    3% {
        opacity: 1;
    }

    97% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: scale(95%, 95%) translate(calc(-40vw - 15rem), calc(-20rem - 200vh));
    }
}

@media screen and (max-width: 70rem) {
    @keyframes bubble-animation-2 {
        0% {
            opacity: 0;
            transform: scale(15%, 15%) translate(0, 0);
        }

        3% {
            opacity: 1;
        }

        97% {
            opacity: 1;
        }

        100% {
            opacity: 0;
            transform: scale(95%, 95%) translate(calc(-20vw - 15rem), calc(-20rem - 200vh));
        }
    }
}


.bubble {
    margin-top: 5rem;
    right: 0;
    position: relative;
    animation-name: bubble-animation-sideways;
    animation-duration: 8s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
}

.bubble > img, .bubble > video {
    position: absolute;
    margin-top: 1rem;
    width: 27rem;
    height: 27rem;
    object-position: center center;
    object-fit: cover;
    background-origin: border-box;
    box-sizing: border-box;
    z-index: 2;
    pointer-events: none;
}

@media screen and (max-width: 1000px) {
    .bubble > img, .bubble > video {
        width: 20rem;
        height: 20rem;
    }
}

@keyframes bubble-animation-sideways {
    0% {
        transform: translateX(-7%);
    }

    100% {
        transform: translateX(7%);
    }
}

.bubble > div {
    position: absolute;
    padding: 1.5rem;
    margin-left: 1rem;
    display: flex;
    box-sizing: border-box;
    justify-content: flex-end;
    flex-direction: column;
    gap: 2rem;
    width: 27rem;
    height: 27rem;
    background-color: white;
    border: 3px solid blue;
    color: blue;
}

@media screen and (max-width: 1000px) {
    .bubble > div {
        width: 20rem;
        height: 20rem;
    }
}

.bubble-element {
}

.bubble > div:hover {
   /* z-index: 4; */

}

.bubble-link {
    font-size: 1.4rem;
    color: blue;
}

.bubble-link-headline {

}

#blue-text {
    width: 50%;
    font-size: 1.3rem;
    line-height: 1.9rem;
    font-family: 'Syne Mono', cursive;
}

@media screen and (max-width: 90rem) {
    #blue-text {
        width: 80%;
    }
}

@media screen and (max-width: 70rem) {
    #blue-text {
            font-size: 1rem;
            line-height: 1.8rem;
    }
}

@media screen and (max-width: 60rem) {
    #blue-text {
            width: 90%;
    }
}

@media screen and (max-width:50rem) {
    #blue-text {
            width: 100%;
    }
}

#blue-text-link {
    color: white;
}

.whats-cooking-button {
    bottom: 4.5rem;
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color: white;
}

#button-container-wrapper {
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 50rem) {
    #button-container-wrapper {
        bottom: 3rem;
    }
}


#button-container-headline {
    font-size: 3.5rem;
}

@media screen and (max-width: 50rem) {
    #button-container-headline {
        display: block;
        font-size: 2.5rem;
    }
}



#button-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 4.5rem;
    row-gap: .5rem;
    width: 90%;
}

@media screen and (max-width: 55rem) {
    #button-container {
        column-gap: 3rem;
        row-gap: -.5rem;
    }
}

@media screen and (max-width: 50rem) {
    #button-container {
        width: 90%;
    }
}

.button-wrapper {
    color: white;
}

.button-text {
    margin-left: -2.63rem;
    font-size: 1.3rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 0.77rem;
    font-family: "Syne Mono", sans-serif;
}

@media screen and (max-width: 55rem) {
    .button-text {
        margin-left: -2rem;
        font-size: 1rem;
        font-weight: 400;
        gap: 0.77rem;
    }
}

.blue-link-stroke {
    transition: stroke .4s ease-in-out;
}

.blue-link-img {
    transition: filter .3s ease-in-out;
}

.button-text-blue {
    color: blue;
    transition: color .3s ease-in-out;
}

.whats-cooking-button:hover .button-text-blue {
    color: black;
}

.whats-cooking-button:hover .blue-link-img {
    filter: brightness(40%);
}

.whats-cooking-button:hover .blue-link-stroke {
    filter: brightness(40%);
}

.button-text img {
    display: inline;
    width: 1.5rem;
}

@media screen and (max-width: 55rem) {
    .button-text img {
        width: 1.2rem;
    }
}

.button-headline {
    margin-bottom: .5rem;
    font-size: 1.3rem;
}

#button-svg {
    width: 4rem;
    height: 4rem;
}

@media screen and (max-width: 55rem) {
    #button-svg {
        width: 3rem;
    }
}

#upper-path, #lower-path {
    stroke-dasharray: 15;
    stroke-dashoffset: 15;
}

#upper-path {
    transition: .4s linear;
}

#lower-path {
    transition: .4s linear;
}

#imprint {
    position: absolute;
    bottom: 4.2rem;
    right: 5rem;
}

@media screen and (max-width: 65rem) {
    #imprint {
        bottom: 1.9rem;
        right: 3rem;
        font-size: 1rem;
    }
}

@media screen and (max-width: 50rem) {
    #imprint {
        left: 3rem;
        bottom: 3rem;
    }
}

.whats-cooking-button:hover #upper-path {
    /*animation: dash .4s linear both;*/
    stroke-dashoffset: 0;
}

.whats-cooking-button:hover #lower-path {
    /*animation: dash .8s linear both;*/
    stroke-dashoffset: 8;
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}


#whats-cooking-arrow {
    margin-right: 2rem;
    width: 3rem;
}

.cooking-intro-box-1 {
    margin: 5rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    position: relative;
    max-width: 50rem;
    min-width: 40rem;
    min-height: 0;
    box-sizing: border-box;
    font-size: 2rem;
    font-weight: 500;
    color: blue;
}

.cooking-first-hexagon {
    position: absolute;
    width: 37rem;
    bottom: -37rem;
    left: -27rem;
}

.cooking-sec-hexagon {
    position: absolute;
    width: 34rem;
    top: -26rem;
    left: 28rem;
}

.cooking-headline {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.cooking-headline-container > div:nth-child(1) {
    margin-left: -1.7rem;
}

.cooking-headline-container > div:nth-child(2) {
    margin-top: -.5rem;
    font-size: 6rem;
    line-height: 6rem;
    font-weight: 500;
}

.cooking-text {
    font-family: 'Syne', 'Special Elite', cursive;
    line-height: 2rem;
    font-size: 1.2rem;
}

.img-container-1 {
    margin-left: 3rem;
    display: flex;
    flex-direction: row;
    position: relative;
    height: 100%;
}

.img-wrapper-1 {
    display: flex;
}

.img-wrapper-2 {
    display: flex;
    flex-direction: column;
}

.img-wrapper-2 > div:nth-child(1) {
    display: flex;
    align-items: center;
}

.img-container-1 > .img-wrapper-1 > img:nth-child(1) {
    width: 50rem;
    height: 40rem;
    object-fit: cover;
}


.img-container-1 > .img-wrapper-2 > div:nth-child(1) > img:nth-child(1) {
    margin-left: -3rem;
    width: 40rem;
    height: 25rem;
    object-fit: cover;
    z-index: 2;
}

.img-container-1 > .img-wrapper-2 > img {
    margin-top: -2rem;
    margin-left: 5rem;
    width: 45rem;
    height: 30rem;
    object-fit: cover;
    z-index: 3;
}

.cooking-bubble {
    position: relative;
}

.cooking-bubble > img {
    margin-top: .7rem;
    object-position: center center;
    object-fit: cover;
    background-origin: border-box;
    box-sizing: border-box;
    z-index: 2;
    pointer-events: none;
}

.cooking-bubble > div:nth-child(2) {
    margin-top: -15.2rem;
    margin-left: .7rem;
    box-sizing: border-box;
    background-color: white;
    border: 3px solid blue;

    @media screen and (max-width: 800px) {
        margin-top: -11.2rem;
    }
}

/** temp text content */
.cooking-bubble > div:nth-child(3) {
    box-sizing: border-box;
    position: absolute;
    left: 0.7rem;
    top: 0;
    border: 3px solid blue;
    opacity: 0;
    transition: opacity 0.2s linear;
    z-index: 3;
    background-color: white;
    padding: 1rem;
    color: blue;
    font-size: 2rem;
    line-height: 120%;
}

.cooking-bubble:hover > div:nth-child(3) {
    opacity: 1;
}

.cooking-bubble > img, .cooking-bubble > div {
    width: 14rem;
    height: 14rem;

    @media screen and (max-width: 800px) {
        width: 10rem;
        height: 10rem;
    }
}

#cooking-credits {
    margin-left: 7rem;
    height: 100%;
    min-width: 45rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    overflow: hidden;
    color: blue;
}

#cooking-credit-container {
    margin-bottom: 9rem;
    width: 31rem;
    font-size: 1.4rem;
}

#cooking-credit-container > div:nth-child(1) {
    font-size: 6rem;
    line-height: 6rem;
    font-weight: 500;
}

#cooking-credit-container > div:nth-child(2) {
    margin-top: 1.5rem;
    font-family: 'Syne', 'Special Elite', cursive;
}

#cooking-last-hexagon {
    top: -18rem;
    right: -11rem;
    width: 37rem;
    position: absolute;
}

#social-media-wrapper {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    font-family: 'Syne', 'Special Elite', cursive;
}

.social-media-card {
    display: flex;
}

.social-media-card > img {
    height: 1.4rem;
    margin-right: 1rem;
}

.small-page-headline {
    margin-top: -1rem;
    color: white;
}

.headline-blue {
    padding-top: 4rem;
    line-height: 110%;
    font-size: 3.8rem;
    font-weight: 500;
    color: blue;
}

@media screen and (max-width: 850px) {
    .headline-blue {
        padding-top: 5rem;
    }
}

@media screen and (max-width: 500px) {
    .headline-blue {
        font-size: 2.4rem;
    }
}

#aboutus-text-wrapper {
    padding: 4.4rem;
    margin-top: 9.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 5.5rem;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    color: blue;
    font-family: 'Syne', 'Special Elite', cursive;
    line-height: 1.8rem;
}

.aboutus-text-headline {
    margin-bottom: 2.5rem;
    font-size: 2rem;
    text-transform: uppercase;
    text-decoration: underline;
}

.aboutus-text-block {
    width: 42%;
}

#aboutus-hex-1 {
    position: absolute;
    top: 4rem;
    right: 0;
    height: 80%;
    margin-right: -10rem;
    max-width: 45vw;

    @media screen and (max-width: 900px) {
        display: none;
    }
}

#aboutus-hex-2 {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 80%;
    max-width: 45vw;

    @media screen and (max-width: 900px) {
        display: none;
    }
}

#aboutus-team-wrapper {
    padding: 5rem 7rem;
    display: flex;
    justify-content: center;
    gap: 2.5rem;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;
    position: relative;

    @media screen and (max-width: 800px) {
        padding: 5rem 3rem;
    }
}

#aboutus-team-wrapper > div:nth-child(1) {
    margin-left: -4rem;
}

#aboutus-team-wrapper > div:nth-child(2), #aboutus-team-wrapper > div:nth-child(7) {
    margin-top: 2.5rem;
}

#aboutus-team-wrapper > div:nth-child(3), #aboutus-team-wrapper > div:nth-child(8) {
    margin-top: 1rem;
}

#aboutus-team-wrapper > div:nth-child(4), #aboutus-team-wrapper > div:nth-child(9) {
    margin-top: 3rem;
}

.aboutus-bubble {
    margin-left: -.7rem;
}

#recap-wrapper {
    padding-left: 8.3rem;
    padding-right: 8.3rem;
    padding-bottom: 6.4rem;
    padding-top: 6.4rem;
    width: 100%;
    box-sizing: border-box;
    min-height: 20rem;
    color: white;
    background-color: blue;
    position: relative;
    z-index: 5;
}

@media screen and (max-width: 50rem) {
    #recap-wrapper {
        padding: 6rem;
    }
}

@media screen and (max-width: 500px) {
    #recap-wrapper {
        padding: 2rem;
    }
}


.recap-headline {
    position: inherit;
    line-height: 110%;
    font-size: 3.8rem;
    font-weight: 500;
}

@media screen and (max-width: 850px) {
    .recap-headline {
        text-align: center;
    }
}

@media screen and (max-width: 500px) {
    .recap-headline {
        margin-top: 2rem;
        font-size: 2.4rem;
    }
}


.recap-button {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;

    .button-headline {
        margin-top: 2rem;
    }

    #button-svg {
        width: 5.7rem;
        height: 5.7rem;
    }

    .button-text {
        font-size: 2rem;
        margin-left: -3.5rem;
    }
}

#recap-button-wrapper {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}

@media screen and (max-width: 1000px) {
    #recap-button-wrapper {
        flex-direction: column;
    }
}

.headline-aboutus-team {
    margin-top: 8rem;
    margin-bottom: 2rem;
    margin-left: 50%;
    line-height: 120%;

    @media screen and (max-width: 1000px) {
        margin-left: 0;
        margin-top: 4rem;
        margin-bottom: 0;
        text-align: center;
    }

    @media screen and (max-width: 850px) {
        margin-top: 2rem;
        margin-left: 50%;
        transform: translateX(-50%);
        padding-left: 0;
        width: 80vw;

        br {
            display: none;
        }
    }
}

#footer {
    padding: 4.4rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-color: white;
}

.footer-icon {
    width: 2.3rem;
    height: 2.3rem;
    transition: filter .2s ease-in-out;
}

.footer-link {
    color: blue;
    font-size: 1.2rem;
    transition: color .2s ease-in-out;
}

.footer-link:hover {
    color: rgb(0, 0, 53);
}

.footer-link:hover > img {
    filter: brightness(40%);
}

.footer-icons-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 4rem;
    row-gap: 1.7rem;
}

.footer-icons-wrapper:nth-child(2) {
    margin-top: 3rem;
}

.cooking-nav {
    transform: translateX(-23rem);
    transition: transform 0.5s ease-in-out;
    top: 0;
    position: fixed;
    width: 23rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    box-sizing: border-box;
    background-color: white;
    border: .2rem solid blue;
    color: blue;
    z-index: 10;
}

@media screen and (max-width: 40rem) {
    .cooking-nav {
            width: 20rem;
    }
}

#nav-headline {
    margin-top: 14.5rem;
    margin-right: 3rem;
    font-size: 2rem;
    font-weight: 800;
    color: blue;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        color: blue;
    }
}

@media screen and (max-width: 40rem) {
    #nav-headline {
            margin-right: 2rem;
    }
}


.cooking-nav-item {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: blue !important;
    display: block;
}

.cooking-nav-item:hover {
    text-decoration: underline;
}

.cooking-nav-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    display: none;
    pointer-events: none;

    &.active {
        display: block;
        pointer-events: all;
    }
}

#nav-item-wrapper {
    margin-top: 2rem;
    margin-right: 6.5rem;
}

@media screen and (max-width: 40rem) {
    #nav-item-wrapper {
        margin-top: 1rem;
        margin-right: 5.5rem;
    }
}

.x-hexagon-container {
    top: 2rem;
    left: 2rem;
    position: fixed;
    mix-blend-mode: difference;
    z-index: 20;
    cursor: pointer;
}

.x-hexagon-inner-container {
    position: relative;
}

.x-hexagon {
    position: absolute;
    top: 0;
    left: 0;
}

#x-hexagon-1 {
    transform-origin: 67px 58px;
    transform: rotateZ(0deg);
    transition: .5s ease-in-out;
    position: static;
}

#x-hexagon-1.open {
  transform: rotateZ(-180deg);
}

#x-hexagon-2 {
  transform-origin: 34px 77px;
  transform: rotateZ(0deg);
  transition: .5s ease-in-out;
}

#x-hexagon-2.open {
  transform: rotateZ(180deg);
}

#x-triangle {
    margin-top: 19px;
    width: 68px;
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    transition: .5s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
}

.x-hexagon-container:hover #x-triangle, #x-triangle.open {
  stroke-dashoffset: 1000;
}

.language-hexagon {
    position: relative;
    cursor: pointer;
}

.language-hexagon:hover .hex-path, .language-hexagon.active .hex-path {
    fill: blue;
}

.language-hexagon:hover .language, .language-hexagon.active .language {
    color: white;
}

.hex-path {
    fill: transparent;
    transition: fill .2s ease-in-out;
}

.language {
    color: blue;
    font-size: 1.8rem;
    transition: color .2s ease-in-out;
    position: absolute;
    left: 16%;
    top: 15%;
}

#lang-wrapper {
    position: absolute;
    bottom: 3rem;
    right: 3rem;
    display: flex;
}

@media screen and (max-width: 40rem) {
    #lang-wrapper {
            right: 2rem;
            bottom: 1.5rem;
    }
}


#lang-wrapper > .language-hexagon:nth-child(2) {
    margin-left: -.8mm;
}

#by-aixd {
    font-size: 1.4rem;
    font-weight: 600;
    color: blue;
    line-height: initial;
    margin-left: 0.7rem;

    a {
        color: blue;
        cursor: pointer;
        transition: color .1s ease-in-out;

        &:hover {
            color: rgb(0, 0, 58);
        }
    }
}

@media screen and (max-width: 65rem) {
    #by-aixd {
        font-size: 1rem;
    }
}

#arrow-down {
    position: absolute;
    bottom: 2rem;
    height: 5rem;
    width: 5rem;
    left: calc(50% - 2.5rem);
    mix-blend-mode: difference;

    animation-name: arrow-animation;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    animation-duration: 4s;
}

@keyframes arrow-animation {
    0% {
        transform: translateY(0rem);
    }

    40% {
        transform: translateY(0rem);
    }

    50% {
        transform: translateY(0.8rem);

    }

    60% {
        transform: translateY(0rem);
    }

    100% {
        transform: translateY(0rem);
    }
}

//WORKSHOP

.workshop-head-box {
    margin-top: 9.3rem;
}

@media screen and (max-width: 1500px) {
    .workshop-head-box {
        margin-top: 4rem;
    }
}

.workshop-wrapper:nth-last-child(2) {
    margin-bottom: 13rem !important;
}

@media screen and (max-width: 1900px) {
    .workshop-wrapper:nth-last-child(2) {
        margin-bottom: 6rem !important;
    }
}

@media screen and (max-width: 1800px) {
    .workshop-wrapper:nth-last-child(2) {
        margin-bottom: 6rem !important;
    }
}

@media screen and (max-width: 1680px) {
    .workshop-wrapper:nth-last-child(2) {
        margin-bottom: 5rem !important;
    }
}

@media screen and (max-width: 1500px) {
    .workshop-wrapper:nth-last-child(2) {
        margin-bottom: 6rem !important;
    }
}

@media screen and (max-width: 750px) {
    .workshop-wrapper:nth-last-child(2) {
        margin-bottom: 5rem !important;
    }
}

@media screen and (max-width: 500px) {
    .workshop-wrapper:nth-last-child(2) {
        margin-bottom: 3rem !important;
    }
}

.workshop-bubble > img {
    width: 13rem;
    height: 13rem;
}

.workshop-bubble > div {
    margin-top: -14.2rem !important;
    width: 13rem;
    height: 13rem;
}

@media screen and (max-width: 1600px) {
    .workshop-bubble > img {
        width: 10rem;
        height: 10rem;
    }

    .workshop-bubble > div {
        margin-top: -11.4rem !important;
        width: 10rem;
        height: 10rem;
    }
}

@media screen and (max-width: 1500px) {
    .workshop-bubble > img {
        width: 13rem;
        height: 13rem;
    }

    .workshop-bubble > div {
        margin-top: -14.2rem !important;
        width: 13rem;
        height: 13rem;
    }
}

@media screen and (max-width: 1000px) {
    .workshop-bubble > img {
        width: 10rem;
        height: 10rem;
    }

    .workshop-bubble > div {
        margin-top: -11.4rem !important;
        width: 10rem;
        height: 10rem;
    }
}

.workshop-wrapper {
    margin-left: 8.3rem;
    margin-right: 8.3rem;
    margin-bottom: 12.5em;
    position: relative;
    display: flex;
    gap: 6rem;
}

@media screen and (max-width: 1700px) {
    .workshop-wrapper {
        gap: 4rem;
        margin-bottom: 11em;
        margin-bottom: 9rem;
    }
}

@media screen and (max-width: 1500px) {
    .workshop-wrapper {
        margin-bottom: 8rem;
        flex-direction: column;
        gap: 2rem;
    }
}

@media screen and (max-width: 800px) {
    .workshop-wrapper {
        margin-left: 6rem;
        margin-right: 6rem;
        gap: 1rem;
    }
}

@media screen and (max-width: 750px) {
    .workshop-wrapper {
        margin-bottom: 3.5rem;
    }
}

@media screen and (max-width: 500px) {
    .workshop-wrapper {
        margin-bottom: 2rem;
        margin-left: 2rem;
        margin-right: 2rem;
        gap: 1rem;
    }
}

.workshop-wrapper:nth-child(2n) {
    flex-direction: row-reverse;
}

.workshop-wrapper:last-child {
    flex-direction: row;
}

@media screen and (max-width: 1500px) {
    .workshop-wrapper:nth-child(2n) {
        flex-direction: column;
    }
}

.credit-wrapper {
    margin-bottom: 7rem;
}

@media screen and (max-width: 500px) {
    .credit-wrapper {
        margin-bottom: 3rem;
    }
}

.workshop-box {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    position: relative;
    width: calc(39% - 3rem);
    min-height: 0;
    box-sizing: border-box;
    font-size: 2rem;
    font-weight: 500;
    color: blue;
}

.workshop-wrapper:first-child > .workshop-box {
    margin-top: -4.2rem;
}

@media screen and (max-width: 1600px) {
    .workshop-box {
        width: calc(54% - 3rem);
    }
}

@media screen and (max-width: 1500px) {
    .workshop-box {
        margin-top: 0;
        width: 90%;
    }
}

@media screen and (max-width: 1100px) {
    .workshop-box {
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .workshop-box {
        gap: 1.5rem;
    }
}

.workshop-first-hexagon {
    position: absolute;
    width: 37rem;
    right: -15rem;
    top: -20rem;
    z-index: 0;
}

@media screen and (max-width: 1100px) {
    .workshop-first-hexagon {
        width: 30rem;
        top: -25rem;
    }
}

.workshop-sec-hexagon {
    position: absolute;
    width: 34rem;
    top: 28rem;
    left: 28rem;
}

@media screen and (max-width: 1850px) {
    .workshop-sec-hexagon {
        width: 30rem;
        top: 30rem;
        left: 28rem;
    }
}

@media screen and (max-width: 1700px) {
    .workshop-sec-hexagon {
        width: 30rem;
        top: 5rem;
        left: 25rem;
    }
}

@media screen and (max-width: 1500px) {
    .workshop-sec-hexagon {
        width: 30rem;
        top: 35rem;
        left: -16rem;
    }
}

@media screen and (max-width: 1100px) {
    .workshop-sec-hexagon {
        width: 25rem;
        top: 25rem;
        left: -26rem;
    }
}

.workshop-third-hexagon {
    position: absolute;
    width: 31rem;
    right: -20rem;
    bottom: -28rem;
    z-index: 0;
}

@media screen and (max-width: 900px) {
    .workshop-third-hexagon {
        bottom: -24rem;
        width: 28rem;
    }
}

@media screen and (max-width: 700px) {
    .workshop-third-hexagon {
        bottom: -10rem;
        right: -21rem;
        width: 22rem;
    }
}

.workshop-fourth-hexagon {
    position: absolute;
    width: 32rem;
    top: 8rem;
    left: -11rem;
    z-index: 0;
}


@media screen and (max-width: 1500px) {
    .workshop-fourth-hexagon {
        width: 38rem;
        top: 0;
        left: 0;
        right: -33rem;
        left: inherit;
    }
}

@media screen and (max-width: 900px) {
    .workshop-fourth-hexagon {
        width: 34rem;
        right: -35rem;
    }
}

.workshop-headline {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

@media screen and (max-width: 1500px) {
    .workshop-headline {
        gap: 0;
    }
}

.workshop-headline-container > *:nth-child(1) {
    margin-left: -1.7rem;
}

@media screen and (max-width: 500px) {
    .workshop-headline-container > *:nth-child(1) {
        margin-left: -.7rem;
        font-size: 1.5rem;
    }
}

.workshop-headline-container > *:nth-child(2) {
    margin-top: -.5rem;
    font-size: 4rem;
    line-height: 90%;
    font-weight: 500;
    color: blue;

    &.clickable {
        cursor: pointer;
    }
}

@media screen and (max-width: 1680px) {
    .workshop-headline-container > *:nth-child(2) {
        font-size: 3.5rem;
    }
}

@media screen and (max-width: 1500px) {
    .workshop-headline-container > *:nth-child(2) {
        font-size: 6rem;
    }
}

@media screen and (max-width: 1100px) {
    .workshop-headline-container > *:nth-child(2) {
        font-size: 3.9rem;
    }
}

@media screen and (max-width: 500px) {
    .workshop-headline-container > *:nth-child(2) {
        font-size: 2.4rem;
    }
}

.workshop-subheadline {
    font-size: 2rem;
}

.workshop-subheadline > div:nth-child(1) {
    display: flex;
}

.workshop-subheadline > div:nth-child(1) > div {
    margin-right: .3rem;
    font-size: 1.3rem;
}


.workshop-text {
    line-height: 140%;
    font-size: 1.1rem;
    font-family: "Syne Mono", cursive;
}

.video-container {
    position: relative;
    width: calc(61% - 4rem);
    height: auto;

    > video {
        border: 2px solid blue;
    }
}

@media screen and (max-width: 1600px) {
    .video-container {
        width: calc(56% - 4rem);
    }
}

@media screen and (max-width: 1500px) {
    .video-container {
        margin-top: 0;
        width: 100%;
    }
}

.video-container > video {
    width: 100%;
    position: relative;
    z-index: 2;
}

@media screen and (max-width: 1500px) {
    .video-container > video {
        width: 90%;
    }
}

@media screen and (max-width: 1100px) {
    .video-container > video {
        width: 100%;
    }
}

.cooking-bubble-container-1, .cooking-bubble-container-2 {
    display: flex;
    gap: 2rem;
    z-index: 4;
    position: absolute;
}

@media screen and (max-width: 750px) {
    .cooking-bubble-container-1, .cooking-bubble-container-2 {
        display: none;
    }
}


.cooking-bubble-container-1 {
    right: 3rem;
    bottom: -8rem;
}

@media screen and (max-width: 1000px) {
    .cooking-bubble-container-1 {
        right: 2rem;
    }
}

.cooking-bubble-container-1 > div:nth-child(2) {
    margin-top: -3rem;
}

.cooking-bubble-container-1 > div:nth-child(3) {
    margin-top: -1.5rem;
}

.cooking-bubble-container-2 {
    left: 3rem;
    right: inherit;
    bottom: -9rem;
}

.cooking-bubble-container-2 > div:nth-child(2) {
    margin-top: 2rem;
}


#workshop-credit-container {
    width: 31rem;
    font-size: 1.4rem;
    color: blue;
}

#workshop-credit-container > div:nth-child(3) {
    margin-top: 1rem;
}

@media screen and (max-width: 700px) {
    #workshop-credit-container {
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    #workshop-credit-container {
        margin-bottom: 1rem;
    }
}


#workshop-credit-container > div:nth-child(1) {
    font-size: 5rem;
    line-height: 110%;
    font-weight: 500;
}

@media screen and (max-width: 1100px) {
    #workshop-credit-container > div:nth-child(1) {
        font-size: 3.9rem;
    }
}

@media screen and (max-width: 500px) {
    #workshop-credit-container > div:nth-child(1) {
        font-size: 2.4rem;
    }
}

#workshop-credit-container > div:nth-child(2) {
    margin-top: 1.5rem;
}

#social-media-wrapper {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-family: 'Syne', 'Special Elite', cursive;
}

@media screen and (max-width: 700px) {
    #social-media-wrapper {
        justify-content: inherit;
        gap: 3rem;
    }
}


@media screen and (max-width: 500px) {
    #social-media-wrapper {
        gap: 1.9rem;
    }
}


.social-media-card {
    display: flex;
    color: blue;
}

.social-media-card > img {
    height: 1.7rem;
    margin-right: 1rem;
}

.social-media-card:hover {
    color: rgb(0, 0, 53);
}

.social-media-card:hover > img {
    filter: brightness(40%);
}


//COOKBOOK

.hex-bg {
    background-image: url("../assets/cooking-bg-hex.svg");
    background-size: 45rem;
    background-position: 44.5%;
    box-sizing: border-box;
}

.four-card-wrapper {
    margin-top: 4.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 5rem;
    width: 100%;
}

@media screen and (max-width: 1450px) {
    .four-card-wrapper {
        row-gap: 5rem;
    }
}

.two-card-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60vw;

    @media screen and (max-width: 1300px) {
        width: 70vw;
    }

    @media screen and (max-width: 1100px) {
        width: 80vw;
    }

    @media screen and (max-width: 850px) {
        flex-direction: column;
        gap: 5rem;
        align-items: center;
    }
}

.two-card-wrapper:nth-child(2n) {
    margin-left: 38vh;

    @media screen and (max-width: 1400px) {
        margin-left: 15rem;
    }

    @media screen and (max-width: 1200px) {
        margin-left: 3rem;
    }

    @media screen and (max-width: 1000px) {
        margin-left: 0;
    }
}

.cooking-card {
    padding: 1.25rem;
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    box-sizing: border-box;
    z-index: 7;
    border: 3px solid white;
    color: white;
    flex-direction: column;
    transition: opacity .2s ease-in-out, background-color .3s linear;
    cursor: pointer;
}

.cooking-card-wrapper:hover .cooking-card {
    opacity: 1;
}

.cooking-card > div:nth-child(1) {
    transform: translateY(23vh);
    font-size: 2.3rem;
    line-height: 100%;
    font-weight: 500;
    transition: .3s linear;
}

@media screen and (max-width: 450px) {
    .cooking-card > div:nth-child(1) {
        margin-top: -.4rem;
        transform: translateY(16vh);
        font-size: 1.4rem;
    }
}

.cooking-card > div:nth-child(2) {
    margin-top: 0.9rem;
    margin-bottom: 0.5rem;
    display: flex;
    gap: 1rem;
}

@media screen and (max-width: 450px) {
    .cooking-card > div:nth-child(2) {
        display: none;
    }
}

.cooking-card > div:nth-child(3) {
    bottom: 1.5rem;
    opacity: 0;
    font-size: 1rem;
    line-height: 120%;
    color: blue;
    width: 100%;
    transition: opacity .3s linear;
}

@media screen and (max-width: 450px) {
    .cooking-card > div:nth-child(3) {
        margin-top: 1rem;
        max-height: 50%;
        overflow-y: scroll;
    }
}

.cooking-card-empty {
    width: 38.5vh;
    height: 38.5vh;
}

@media screen and (max-width: 750px) {
    .cooking-card-empty {
        width: 28vh;
        height: 28vh;
    }
}

.cooking-card-wrapper {
    position: relative;
    width: 38.5vh;
    height: 38.5vh;
}

@media screen and (max-width: 450px) {
    .cooking-card-wrapper {
        width: 28vh;
        height: 28vh;
    }
}

.cooking-card-wrapper:hover .cooking-card > div:nth-child(1) {
    /*animation: flex .3s linear forwards;*/
    transform: translateY(0);
    color: blue;
}

.cooking-card-wrapper:hover .cooking-card {
    /*animation: color .3s linear forwards;*/
    background-color: white;
    transition-delay: .2s;
}

.cooking-card-wrapper:hover .cooking-card > div:nth-child(3) {
   /* animation: opacity .3s linear forwards;*/
    transition-delay: .4s;
    opacity: 1;
}

@keyframes flex {
    to {
        transform: translateY(0);
        color: blue;
    }
}

@keyframes color {
    to {
        background-color: white;
    }
}

@keyframes opacity {
    to {
        opacity: 1;
    }
}

.cooking-card-wrapper:hover .dot-1 {
    /*animation: opacity .2s linear forwards;*/
    opacity: 1;
    transition-delay: .5s;
}

.cooking-card-wrapper:hover .dot-2 {
   /* animation: opacity .2s linear forwards;*/
    opacity: 1;
    transition-delay: .6s;
}

.cooking-card-wrapper:hover .dot-3 {
   /* animation: opacity .2s linear forwards;*/
    opacity: 1;
    transition-delay: .7s;
}

.cooking-card-wrapper:hover .diff-font {
   /* animation: opacity .2s linear forwards;*/
    opacity: 1;
    transition-delay: .9s;
}

.cooking-card-img {
    top: -.5rem;
    right: -.5rem;
    width: 33vh;
    height: 33vh;
    position: absolute;
    object-fit: cover;
    z-index: 5;
    background-color: white;
}

@media screen and (max-width: 450px) {
    .cooking-card-img {
        width: 23vh;
        height: 23vh;
    }
}

.cooking-card-title {
    color: blue;
    font-size: 2.8rem;
    line-height: 2.5rem;
    font-weight: 500;
    position: absolute;
    left: 2rem;
    bottom: 2rem;
    z-index: 6;
}

.diff {
    min-width: 4.5rem;
}

@media screen and (max-width: 1700px) {
    .diff {
        min-width: 3.9rem;
    }
}

@media screen and (max-width: 450px) {
    .diff {
        min-width: 3.6rem;
    }
}

.diff-dot {
    opacity: 0;
    width: 1.5rem;
    height: 1.5rem;
    transition: opacity .2s linear;
}

@media screen and (max-width: 1700px) {
    .diff-dot {
        width: 1.3rem;
        height: 1.3rem;
    }
}

@media screen and (max-width: 450px) {
    .diff-dot {
        width: 1.2rem;
        height: 1.2rem;
    }
}

.diff-font {
    opacity: 0;
    font-size: 1.1rem;
    color: blue;
    line-height: 130%;
    transition: opacity .2s linear;
}

//ALT

//.cookbook-container {
//  display: grid;
//  grid-template-columns: 1fr 1fr;
//  padding: 7rem 15rem;
//  align-content: center;
//  justify-items: center;
//  gap: 5rem;
//}

.cooking-card-bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
    background-color: white;
    border: 3px solid blue;
}

</style>

<i18n>
{
    "de": {
        "send": "SENDEN",
        "nl_confirm_header": "Anmeldung erfolgreich",
        "nl_confirm_text": "Dank für deine Anmeldung! Bitte überprüfe dein Postfach nach einem Bestätigungslink.",
        "blue_text_p1": "Bei aiXDesign bemühen wir uns, Designer mit der Welt der KI zu verbinden.",
        "blue_text_p2": "Kreatives Arbeiten wird sich durch intelligente Maschinen grundlegend verändern und die Kreativen bei ihrer Arbeit maßgeblich unterstützen. Gleichzeitig beeinflussen die Werkzeuge und die gewählten Zutaten immer die Ergebnisse Ihrer Arbeit, und der Geschmack eines Gerichts hängt von den Qualifikationen des Kochs und nicht von den Löffeln ab. Letztlich müssen Kreative also entscheiden, inwieweit KI in ihren Workflow integriert wird.",
        "blue_text_p3": "Sie können bei uns einen Workshop buchen, um Ihre ersten Schritte in die Welt der künstlichen Intelligenz zu machen oder um Ihre Arbeit zu verfeinern und weiter zu üben. Wir haben passende Workshops für jedes Niveau.",
        "blue_text_link_text": "Begleiten Sie uns auf eine Reise in die Zukunft!",
        "imprint": "Impressum",
        "aboutus-text-1": "Komplexe Algorithmen übernehmen unter Einbeziehung großer Datensammlungen zunehmend Aufgaben, die noch vor Kurzem kaum denkbar gewesen wären. Wir durchlaufen eine weitere digitale Transformation: Big Data, Machine Learning und andere Methoden künstlicher Intelligenz werden Bestandteil vieler Produkte und Dienstleistungen. AI verändert die Tätigkeiten in der Kreativwirtschaft und die Art zu Arbeiten von Designer*innen, Fotograf*innen, Videoschaffenden, Texter*innen, Musiker*innen und Architekt*innen. Werkzeuge, welche seit jeher die Eigenschaften der Produkte und Services beeinflussen, die durch sie geschaffen werden, werden durch AI derart leistungsfähig, dass Aufgaben, die noch vor kurzem komplex und zeitaufwändig waren, plötzlich wie von Zauberhand erledigt werden. Daher ist die Fragestellung, wie Kreativität von AI beeinflusst wird und welchen Einfluss von AI beeinflusste Arbeitsergebnisse von Kreativen auf die Gesellschaft haben, äußerst relevant. Kreativität wird durch AI erweitert – man könnte daher von Augmented Intelligence sprechen.",
        "aboutus-text-2": "Die Angst von einer künstlichen Intelligenz bald ersetzt zu werden ist ähnlich weit verbreitet wie die trotzige Behauptung, dass Menschen, insbesondere in Kreativ-Umfeldern, immer unersetzlich bleiben werden. Demgegenüber ist es faszinierend, wie gut Mustererkennung und Machine Learning funktioniert und in vielen Bereichen kreativen Arbeitens vollkommen neue Möglichkeiten eröffnet werden. Wie jeder Mensch nutzen Kreative tagtäglich bereits ganz selbstverständlich Suchalgorithmen und personalisierte Vorschläge von Newsfeeds zur Inspiration, ohne dabei an AI und irgendetwas bedrohliches zu denken. Tools zur Bearbeitung von Fotos, Videos und Sounds sind durch künstliche Intelligenz heute derart leistungsfähig, daß selbst untrainierte Laien komplexe Bildbearbeitungen mit dem Smartphone durchführen können und damit Profis in Teilen ihre Position streitig machen. Populäre Social Media Plattformen werden zur internationalen Bühne von Kreativen, die zuvor nicht jahrelang ihr Medien-Handwerk gelernt haben. Ein neuer Wettbewerb zwischen Laien und professionellen Kreativberufen führt zu einer massiven Beschleunigung von Kreativ-Ergebnissen, der ästhetisch und gesellschaftlich überwiegend positiv ist und gleichzeitig Vieles in Frage stellt.",
        "aboutus-text-3": "Die digitale Disruption verändert die gesamte Prozesskette des Kreativschaffens  (Inspiration, Produktion, Distribution) und alle Medien- und Berufssparten: Grafik Design, Video Animation, Musik, Text, Architektur, Mode und Produktdesign, Werbung und Publishing, natürlich auch Fotografie, aber auch alle Darstellenden Künste und weite Bereiche kreativen Handwerks. Wenn Kreative lernen im Zusammenspiel mit intelligenten Werkzeugen zu arbeiten, können sie Fähigkeiten hinzugewinnen und mit den Algorithmen wachsen. Doch nur wenn Kreativschaffende Tools auch praktisch anwenden, können sie deren Wirkweise wirklich begreifen und damit neben Chancen auch die mit Creative AI verbundenen Probleme kennen lernen und diese vermeiden. Gereifte Kreative müssen sich grundsätzlich weiterbilden. Junge Berufsanfänger*innen müssen anders lernen, denn der geübte Vorgang, sich allmählich über zunächst einfache, handwerkliche Aufgaben in den Beruf hineinzuarbeiten, ist künftig nicht mehr in gleicher Weise gangbar, wenn diese Aufgaben von Maschinen mühelos erledigt werden. Letztlich können nur aktive Nutzer*innen Tools und Output beeinflussen – im Sinne des kreativen Schaffens und zum Nutzen der Gesellschaft.",
        "aboutus-text-4": "AI Creative Kitchen ist ein Angebot des Forschungsprojekts aiXdesign, das im Department Design der HAW Hamburg entwickelt wird. Das Angebot AI Creative Kitchen soll es Kreativen aller Medien und Disziplinen, sowie all denjenigen, die Kreative beauftragen, ermöglichen das Zusammenspiel mit AI niedrigschwellig auszuprobieren. Die Teilnehmenden unserer Kitchens erfahren praktisch die Möglichkeiten. Sie bereiten vom Team vorbereitete Rezepte mit gut zusammen gestellten Zutaten, haben Spaß und erlangen auf diese Weise einen emanzipierte Sicht auf die große Veränderung. Spielerisch werden Fragen behandelt, die in den kommenden Jahren immer wieder auftauchen: Wie wird sich das Verhältnis von Kreativität und AI entwickeln? Welche Rolle haben künftig Kreative? Welche Haltungen sind möglich? Welche Haltungen – aus heutiger Sicht – wünschenswert und sinnvoll? Welche Rolle nehmen Kreative künftig im Spannungsfeld von Code und Daten ein?",
        "aboutus-headline-1": "Künstliche Intelligenz beschleunigt die Kreativität und verändert die Kreativwirtschaft für immer.",
        "aboutus-headline-2": "Angehörige von Kreativberufen verstehen häufig nicht, was künstliche Intelligenz überhaupt ist und für sie und ihre Arbeitsmethodik bedeuten kann.",
        "aboutus-headline-3": "Angetrieben durch immer leistungsstärkere Computer und komplexere Algorithmen baut sich derzeit ein neuer Innovationsschub auf.",
        "aboutus-headline-4": "AI Creative Kitchen ermöglicht es das Zusammenspiel zwischen Kreativität und AI niedrigschwellig auszuprobieren.",
        "by_aixdesign": "ein Projekt von"
    },
    "en": {
        "send": "SEND",
        "nl_confirm_header": "Registration successful",
        "nl_confirm_text": "Thank you for subscribing! Please check your mailbox for a confirmation link.",
        "blue_text_p1": "At aiXDesign we strive to connect designers with the world of AI.",
        "blue_text_p2": "Creative work will be fundamentally changed by intelligent machines and will significantly support creative in their work. At the same time, tools and the selected ingredients always influence the results of your work and the taste of the dish relies on the qualifications of the cook and not the spoons. So in the end creatives have to decide to what extend AI will be integrated into their workflow.",
        "blue_text_p3": "You can book a workshop with us to take your first steps into the world of artificial intelligence or to refine your work and practise even further. We have fitting workshops for every skill level.",
        "blue_text_link_text": "Join us for a journey towards the future!",
        "imprint": "Imprint",
        "aboutus-text-1": "Complex algorithms, incorporating large collections of data, are increasingly taking on tasks that would have been almost inconceivable only a short time ago. We are undergoing another digital transformation: Big Data, Machine Learning and other artificial intelligence methods are becoming part of many products and services. AI is changing what creative industries do and how designers, photographers, videographers, copywriters, musicians, and architects work. Tools that have always influenced the characteristics of the products and services they create are becoming so powerful through AI that tasks that were complex and time-consuming just a short time ago are suddenly being done as if by magic. Therefore, the question of how creativity is affected by AI and what impact AI-influenced work outputs of creatives have on society is extremely relevant. Creativity is augmented by AI - one could therefore speak of augmented intelligence.",
        "aboutus-text-2": "The fear of soon being replaced by artificial intelligence is as widespread as the defiant assertion that humans, especially in creative environments, will always remain irreplaceable. In contrast, it is fascinating how well pattern recognition and machine learning work and open up completely new possibilities in many areas of creative work. Like everyone, creatives already use search algorithms and personalized newsfeed suggestions for inspiration as a matter of course on a daily basis, without thinking about AI and anything threatening. Tools for editing photos, videos and sounds are now so powerful thanks to artificial intelligence that even untrained amateurs can perform complex image editing with their smartphones, thus contesting the position of professionals to some extent. Popular social media platforms are becoming an international stage for creatives who have not previously spent years learning their media craft. A new competition between amateurs and professional creative professions leads to a massive acceleration of creative results, which is aesthetically and socially predominantly positive and at the same time calls many things into question.",
        "aboutus-text-3": "Digital disruption is changing the entire process chain of creative work (inspiration, production, distribution) and all media and professional sectors: graphic design, video animation, music, text, architecture, fashion and product design, advertising and publishing, and of course photography, but also all performing arts and wide areas of creative crafts. When creatives learn to work in concert with intelligent tools, they can gain skills and grow with the algorithms. But only when creatives use tools in practice can they truly understand how they work, and thus learn about and avoid the problems associated with Creative AI, as well as the opportunities. Mature creatives fundamentally need to educate themselves. Young people just starting out in their careers will have to learn in a different way, because the practiced process of gradually working one's way into the profession by performing initially simple, manual tasks will no longer be viable in the same way in the future if these tasks are performed effortlessly by machines. Ultimately, only active users can influence tools and output - in the sense of creative work and for the benefit of society.",
        "aboutus-text-4": "Ai Creative Kitchen is an offer of the research project aiXdesign, which is being developed in the Department of Design at HAW Hamburg. The AI Creative Kitchen is intended to enable creatives of all media and disciplines, as well as all those who commission creatives, to try out the interaction with AI in a practical way. The participants of our Kitchens experience the possibilities hands on. They prepare recipes prepared by the team with well put together ingredients, have fun and in this way gain an emancipated view of the big change. In a playful way, questions are addressed that will come up again and again in the years to come: How will the relationship between creativity and AI evolve? What role will creatives have in the future? What attitudes are possible? Which attitudes - from today's perspective - are desirable and sensible? What role will creatives play in the future in the field of tension between code and data?",
        "aboutus-headline-1": "Artificial intelligence is accelerating creativity and changing the creative industries forever.",
        "aboutus-headline-2": "Members of creative professions often do not understand what artificial intelligence even is and can mean for you and your work methodology.",
        "aboutus-headline-3": "Driven by increasingly powerful computers and more complex algorithms, a new wave of innovation is currently building up.",
        "aboutus-headline-4": "AI Creative Kitchen makes it possible to try out the interplay between creativity and AI in a low-barrier way.",
        "by_aixdesign": "a project by"

    }
}
</i18n>
