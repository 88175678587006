<template>
    <div class="content-container" :style="{opacity: loaded ? 1 : 0}">

        <div class="content-arranger wide">
            <div id="event-headline">
                <div>aiXdesign.space/</div><div>shuttleevents</div>
            </div>
        </div>

        <div class="content-arranger wide" id="video-arranger">
            <video id="banner-video" autoplay loop muted>
                <source src="../assets/conclusion.mp4" type="video/mp4">
            </video>
        </div>

        <div class="content-arranger wide headline-arranger" style="margin-bottom: 0">
                <div id="front-headline">
                    <div> Yes, that's a wrap! The first season of our aiXdesign shuttleevents is done! Thanks to all our wonderful speakers. We had the chance to get a better understanding of what artificial intelligence (AI) actually is, in which areas it will and is already influencing the work of Designers and Creatives and which ethical questions its use raises. Don't be downhearted that it's over, we will continue our work! Follow us on social media and subscribe to our newsletter, so you don’t miss any news and updates about the future of our shuttleevents. To see what happened in our past events, just scroll down! </div>
                </div>
        </div>

        <div class="content-footer">
            <div @click="$refs.mailDialog.open(email)"><div>Get the newsletter</div></div>
            <input id="modal-email-output" class="footer-input-field" type="email" placeholder="E-Mail" v-model="email">
            <div id="send-button" @click="$refs.mailDialog.open(email)"><div>{{ $t("send") }}</div></div>
            <div class="icon-wrapper">
                <a class="footer-link" href="https://www.instagram.com/aixdesign.space" target="_blank"><img class="footer-icon" src="../assets/social-media/instagram.svg"></a>
                <a class="footer-link" href="https://www.facebook.com/aixdesign.space" target="_blank"><img class="footer-icon" src="../assets/social-media/facebook.svg"></a>
                <a class="footer-link" href="https://twitter.com/aixdesign_space" target="_blank"><img class="footer-icon" src="../assets/social-media/twitter.svg"></a>
                <a class="footer-link" href="mailto:aixdesign@haw-hamburg.de" target="_blank"><img class="footer-icon" src="../assets/social-media/mail.svg"></a>
            </div>
        </div>

        <div class="content-arranger">
            <div class="card-headline">
                <div>season 1 <span style="margin-left: 2rem">2021</span></div>
            </div>
        </div>

        <div class="content-arranger arranger-mobile-bottom arranger-speaker-cards">
            <div class="table-of-content">
                <div class="table-entry" v-for="event in backlog" :key="event.id">{{ event.speaker_name }}, {{ event.event_name }}</div>
            </div>

            <div class="card-container">

                <div class="card" v-for="event in backlog.filter(ev => !!ev.card_image)" :key="event.id">
                    <div class="next-speakers-card next-speakers-card-mod">
                        <div class="next-speakers-left">
                            <div>{{ event.speaker_name }}</div>
                            <div class="next-speakers-title">{{ event.event_name }}</div>
                        </div>
                        <div class="next-speakers-right">
                            <div class="next-speaker-date">{{ padStr("" + event.date.getDate(), 2) }}.{{ padStr("" + (event.date.getMonth() + 1), 2) }}.</div>
                            <div class="next-speaker-time">{{ padStr("" + (event.date.getHours()), 2) }}:{{ padStr("" + (event.date.getMinutes()), 2) }}</div>
                        </div>
                    </div>
                    <div class="card-text">
                        <div class="top-text">
                            <div>
                                {{ $store.state.lang === "de" ? event.bio_de : event.bio_en }}
                            </div>

                            <div class="card-icon-wrapper">
                                <div class="space-shuttle-button card-space-shuttle"
                                     @click="openEventDetailDialog('https://cms.aixdesign.space' + event.banner_image.url, $store.state.lang === 'de' ? event.bio_de : event.bio_en, $store.state.lang === 'de' ? event.description_left_de : event.description_left_en, $store.state.lang === 'de' ? event.description_right_de : event.description_right_en)"
                                >Talk</div>
                            </div>
                        </div>

                        <img class="card-img" :src="'https://cms.aixdesign.space' + event.card_image.url">
                    </div>
                </div>

            </div>

        </div>

        <div class="content-footer">
            <div @click="$refs.mailDialog.open(email)"><div>Get the newsletter</div></div>
            <input id="modal-email-output" class="footer-input-field" type="email" placeholder="E-Mail" v-model="email">
            <div id="send-button" @click="$refs.mailDialog.open(email)"><div>{{ $t("send") }}</div></div>
            <div class="icon-wrapper">
                <a class="footer-link" href="https://www.instagram.com/aixdesign.space" target="_blank"><img class="footer-icon" src="../assets/social-media/instagram.svg"></a>
                <a class="footer-link" href="https://www.facebook.com/aixdesign.space" target="_blank"><img class="footer-icon" src="../assets/social-media/facebook.svg"></a>
                <a class="footer-link" href="https://twitter.com/aixdesign_space" target="_blank"><img class="footer-icon" src="../assets/social-media/twitter.svg"></a>
                <a class="footer-link" href="mailto:aixdesign@haw-hamburg.de"><img class="footer-icon" src="../assets/social-media/mail.svg"></a>
            </div>
        </div>

        <EventDetailDialog ref="eventDetailDialog" :img="currentEventImg" :headline="currentEventHeadline" :textLeft="currentEventTextLeft" :textRight="currentEventTextRight"></EventDetailDialog>
        <NewsletterDialog ref="mailDialog" @subscribed="$refs.newsletterConfirm.open()"></NewsletterDialog>

        <Dialog ref="newsletterConfirm">
            <div class="content-sub-headline input-field-headline">{{ $t("nl_confirm_header") }}</div>
            <div>{{ $t("nl_confirm_text") }}</div>

            <input @click="$refs.newsletterConfirm.close()"
                   class="submit-button submit-button-popup" type="submit" value="OK">
        </Dialog>

        <Dialog ref="calendarDialog">
            <div class="content-sub-headline input-field-headline">{{ calendarEvent.calendar_title }}</div>
            <div>{{ calendarEvent.calendar_description }}</div>

            <div class="calendar-dialog-date">
                <div>{{ padStr("" + calendarEvent.date.getDate(), 2) }}.{{ padStr("" + (calendarEvent.date.getMonth() + 1), 2) }}.</div>
                <div>{{ padStr("" + (calendarEvent.date.getHours()), 2) }}:{{ padStr("" + (calendarEvent.date.getMinutes()), 2) }}</div>
            </div>

            <div style="flex-direction: row; display: flex; width: 100%; margin-top: 2rem">
                <input @click="closeCalendarDialog()"
                       class="submit-button submit-button-popup" style="margin-right: 0.5rem" type="submit" value="ADD TO CALENDAR">
                <input @click="$refs.calendarDialog.close()"
                       class="submit-button submit-button-popup" style="margin-left: 0.5rem" type="submit" value="CLOSE">
            </div>
        </Dialog>

        <img ref="bgImg" id="bg-img" style="opacity: .3" src="../assets/UXD.svg">
    </div>
</template>

<script>

import Vue from "vue";
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import NewsletterDialog from "@/components/NewsletterDialog";
import Dialog from "@/components/Dialog";
import * as ics from "ics";
import EventDetailDialog from "@/components/EventDetailDialog";

export default Vue.extend({
    name: "Events",
    components: {EventDetailDialog, NewsletterDialog, Hooper, Slide, HooperNavigation, Dialog },
    data() {
        return {
            email: "",
            events: [],
            loaded: false,
            calendarEvent: {date: new Date()},
            currentEventImg: "",
            currentEventHeadline: "",
            currentEventTextLeft: "",
            currentEventTextRight: "",
        };
    },
    created() {
        this.loadEvents();
    },
    methods: {
        openEventDetailDialog(img, headline, textL, textR) {
            this.currentEventHeadline = headline;
            this.currentEventImg = img;
            this.currentEventTextLeft = textL;
            this.currentEventTextRight = textR;
            this.$refs.eventDetailDialog.open();
        },
        closeCalendarDialog() {
            const ics = this.generateICS(this.calendarEvent);
            const element = document.createElement('a');
            element.setAttribute('href', 'data:text/calendar;charset=utf-8,' + encodeURIComponent(ics.value));
            element.setAttribute('download', `aixdesign-${this.calendarEvent.id}.ics`);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);

            if (this.$matomo) {
                this.$matomo.trackEvent("Events", "DownloadCalendar");
            }

            this.$refs.calendarDialog.close();
        },
        openCalendarDialog(event) {
            if (this.$matomo) {
                this.$matomo.trackEvent("Events", "OpenCalendarDialog");
            }

            if (event.calendar_title) {
                this.calendarEvent = event;
                this.$refs.calendarDialog.open();
            } else {
                this.$refs.mailDialog.open(this.email);
            }
        },
        generateICS(event) {
            const eventData = {
                start: [event.date.getFullYear(), event.date.getMonth() + 1, event.date.getDate(), event.date.getHours(), event.date.getMinutes()],
                duration: { hours: 2, minutes: 0 },
                title: event.calendar_title,
                description: event.calendar_description + "\r\n\r\nJoin the conference: " + event.zoom_link,
                location: 'Zoom conference',
                url: 'https://aixdesign.space/shuttleevents',
                status: 'CONFIRMED',
                busyStatus: 'BUSY',
                organizer: { name: 'aiXdesign', email: 'aixdesign@haw-hamburg.de' },
            }

            return ics.createEvent(eventData);
        },
        async loadEvents() {
            const resp = await fetch("https://cms.aixdesign.space/events");
            const obj = await resp.json();

            if (Array.isArray(obj)) {
                this.events = obj.map(event => {
                    event.date = new Date(event.date);

                    return event;
                });

                this.events = this.events.sort((a, b) => {
                    return a.date - b.date;
                });
            }

            setTimeout(() => {
                this.loaded = true;
            }, 200);
        },
        padStr(str, len) {
            while (str.length < len) {
                str = "0" + str;
            }

            return str;
        }
    },
    computed: {
        itemsToShow() {
            if (window.innerWidth > 1600) {
                return 4;
            } else if (window.innerWidth > 1000) {
                return 3;
            } else if (window.innerWidth > 700) {
                return 2.5;
            } else {
                return 1;
            }
        },
        topEvent() {
            if (this.events.length === 0) {
                return null;
            }

            const currentDate = new Date(new Date() - 3600000 * 3);
            let top = null;

            for (const ev of this.events.slice(0).reverse()) {
                if (ev.date > currentDate) {
                    top = ev;
                }
            }

            if (top) {
                return top;
            } else {
                return this.events[this.events.length - 1];
            }
        },
        upcomings() {
            const currentDate = new Date(new Date() - 3600000 * 3);

            return this.events.filter(ev => ev.date > currentDate);
        },
        backlog() {
            const currentDate = new Date(new Date() - 3600000 * 3);

            return this.events.filter(ev => ev.date < currentDate);
        }
    }
})
</script>

<style scoped lang="scss">
.content-container {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.headline-arranger {
    margin-bottom: 3rem;
    flex-direction: column;
}

@media screen and (max-width: 1000rem) {
    .content-arranger {
        width: 75%;
    }
}

@media screen and (max-width: 40rem) {
    .content-arranger {
        width: 85%;
    }
}

.arranger-mobile {

}

@media screen and (max-width: 65rem) {
    .arranger-mobile {
        flex-direction: column;
    }
}

.arranger-mobile-bottom {

}

@media screen and (max-width: 45rem) {
    .arranger-mobile-bottom {
        flex-direction: column;
    }
}

#event-headline {
    margin-top: 2rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    font-size: 3.8rem;
}

@media screen and (max-width: 70rem) {
    #event-headline {
        flex-direction: column;
    }
}

@media screen and (max-width: 40rem) {
    #event-headline {
        font-size: 3rem;
        line-height: 3.4rem;
    }
}

@media screen and (max-width: 30rem) {
    #event-headline {
        font-size: 2.2rem;
        line-height: 2.7rem;
    }
}

#head-img-wrapper {
    display: flex;
    flex-direction: row;
    position: relative;
    border: .1rem solid blue;
}

@media screen and (max-width: 46rem) {
    #head-img-wrapper {
        flex-direction: column;
    }
}


.head-img {
    width: 85%;
    border-right: .1rem solid blue;
    object-fit: contain;
    object-position: center center;
    background-origin: border-box;
    align-self: center;
}

@media screen and (max-width: 46rem) {
    .head-img {
        width: 100%;
        border-right: none;
        border-bottom: .1rem solid blue;
    }
}

#head-img-right {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    width: 15%;
}

@media screen and (max-width: 46rem) {
    #head-img-right {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        width: 100%;
        flex-direction: row;
    }
}

.next-speakers-title {
    font-weight: bold;
}

.space-shuttle-button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 6.5rem;
    height: 6.5rem;
    border-radius: 50%;
    font-size: 1.3rem;
    line-height: 1.4rem;
    color: white;
    border: .1rem solid white;
    cursor: pointer;
    transition: border .15s ease-in-out, color .15s ease-in-out, background-color .15s ease-in-out;
}

@media screen and (max-width: 80rem) {
    .space-shuttle-button {
        width: 5.5rem;
        height: 5.5rem;
        font-size: 1.3rem;
        line-height: 1rem;
    }
}

@media screen and (max-width: 66rem) {
    .space-shuttle-button {
        width: 4.5rem;
        height: 4.5rem;
        font-size: 1.1rem;
    }
}

.space-shuttle-button:hover {
    border: .1rem solid white;
    color: black;
    background-color: white;
}

.card-space-shuttle {
    width: 5rem;
    height: 5rem;
    font-size: 1.1rem;
}

.zoom-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6.5rem;
    height: 6.5rem;
    border: .1rem solid white;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color .15s ease-in-out;
}

.card .zoom-button {
    margin-left: 1rem;
}


@media screen and (max-width: 80rem) {
    .zoom-button {
        width: 5.5rem;
        height: 5.5rem;
        font-size: 1.3rem;
        line-height: 1rem;
    }
}

@media screen and (max-width: 66rem) {
    .zoom-button {
        width: 4.5rem;
        height: 4.5rem;
        font-size: 1.1rem;
    }
}

.zoom-button:hover {
    background-color: white;
}

.zoom-button:hover .camera-icon, .zoom-button:hover .miro-icon {
    filter: invert(1);
}

.card-zoom-button {
    width: 5rem;
    height: 5rem;
}

.camera-icon {
    width: 4rem;
    transition: filter .15s ease-in-out;
}

.card-camera-icon {
    width: 3rem;
}

@media screen and (max-width: 80rem) {
    .camera-icon {
        width: 2.8rem;
    }
}

@media screen and (max-width: 66rem) {
    .camera-icon {
        width: 2.5rem;
    }
}

.card-icon-wrapper {
    display: flex;
    align-self: flex-end;
}

@media screen and (max-width: 110rem) {
    .card-icon-wrapper {
    margin-top: 1.3rem;
    }
}

#front-headline {
    margin-bottom: 1.7rem;
    font-size: 1.1rem;
    font-weight: bold;
}

#column-text {
    margin-bottom: 2.7rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 3rem;
    row-gap: 1rem;
}

@media screen and (max-width: 45rem) {
    #column-text {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
    }
}

.speaker-shadow-left, .speaker-shadow-right {
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 10;
    width: 10%;
}

.speaker-shadow-left {
    background-image: linear-gradient(to right, rgba(0,0,0,8), rgba(0,0,0,0));
    left: 0;
}

.speaker-shadow-right {
    background-image: linear-gradient(to left, rgba(0,0,0,8), rgba(0,0,0,0));
    right: 0;
}

.top-text {
    opacity: 0;
    padding: 1.7rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: absolute;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.8);
    transition: .2s opacity ease-in-out;
}

.top-text:hover {
    opacity: 1;
}

@media screen and (max-width: 80rem) {
    .top-text {
        padding: 1rem;
    }
}

.top-text > div:nth-child(1) > div:nth-child(1) {
    margin-bottom: 2rem;
    font-weight: 600;
    text-decoration: underline;
    text-transform: uppercase;
}

#bottom-button {
    padding-top: .5rem;
    padding-bottom: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 2rem;
    color: rgb(0, 0, 255);
    font-style: italic;
    text-transform: uppercase;
    border-top: .1rem solid blue;
    transition: .2s color ease-in-out;
    cursor: pointer;
}

#bottom-button:hover {
    color: white;
}

@media screen and (max-width: 90rem) {
    #bottom-button {
        font-size: 1.3rem;
    }
}

.next-speakers-card {
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-right: .7rem;
    padding-left: .7rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: .1rem solid blue;
}

.next-speakers-card-mod {
    border-top: none;
    border-left: none;
    border-right: none;
}

.hooper-slide .next-speakers-card {
    margin: 0 1.5rem;
}

.next-speakers-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-width: 35%;
}

@media screen and (max-width: 90rem) {
    .next-speakers-right {
        font-size: 2rem;
        line-height: 2.5rem;
    }
}

.next-speaker-date {
    margin-bottom: 1.1rem;
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: 200;
}

.next-speaker-time {
    font-weight: 200;
    font-size: 1.8rem;
}

.next-speakers-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 60%;
    text-transform: uppercase;
    font-size: 1.1rem;
}

@media screen and (max-width: 90rem) {
    .next-speakers-left {
        font-size: 1rem;
    }
}

.content-footer {
    top: 100%;
    margin-top: 3.5rem;
    display: grid;
    grid-template-columns: 10% auto 20% 7% auto 10%;
    width: 100%;
    height: 3rem;
    border-top: .1rem solid white;
    border-bottom: .1rem solid white;
    font-style: italic;
}

@media screen and (max-width: 80rem) {
    .content-footer {
    grid-template-columns: 10% auto auto 10%;
    }
}

@media screen and (max-width: 50rem) {
    .content-footer {
    grid-template-columns: auto;
    }
}

.content-footer > div:nth-child(1) {
    padding-left: 1rem;
    padding-right: 1rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    grid-column: 2;
    min-width: 13rem;
    height: 3.1rem;
    text-transform: uppercase;
    border-left: .1rem solid white;
    border-right: .1rem solid white;
    transition: background-color .15s ease-in-out, color .15s ease-in-out;
    pointer-events: none;
}

.content-footer > div:nth-child(1):hover {
    color: black;
    background-color: white;
}

@media screen and (max-width: 80rem) {
    .content-footer > div:nth-child(1) {
        pointer-events: all;
    }
}

@media screen and (max-width: 50rem) {
    .content-footer > div:nth-child(1) {
        display: none;
    }
}

.icon-wrapper {
    grid-column: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: .1rem solid white;
}

@media screen and (max-width: 80rem) {
    .icon-wrapper {
        grid-column: 3;
        grid-row: 1;
    }
}

@media screen and (max-width: 50rem) {
    .icon-wrapper {
        grid-column: 1;
        border-right: none;
    }
}

.icon-wrapper > a:nth-child(2) {
    margin-right: 1.3rem;
    margin-left: 1.3rem;
}

.icon-wrapper > a:nth-child(3) {
    margin-right: 1.3rem;
}

#modal-email-output {
    display: inline-flex;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0;
    padding-bottom: 0;
    grid-column: 3;
    height: 3rem;
    background-color: black;
    color: white;
    border-style: hidden;
}

@media screen and (max-width: 80rem) {
    #modal-email-output {
        display: none;
    }
}

#send-button {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    height: 3.1rem;
    grid-column: 4;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    border-left: .1rem solid white;
    border-right: .1rem solid white;
    transition: .2s background-color ease-in-out, .2s color ease-in-out;
    cursor: pointer;
}

@media screen and (max-width: 80rem) {
    #send-button {
        display: none;
    }
}

#send-button:hover {
    background-color: white;
    color: black;
}

.footer-icon {
    height: 1.6rem;
    width: 1.6rem;
    transition: .1s opacity ease-in-out;
}

.footer-link {
    height: 1.6rem;
    width: 1.6rem;
}

.footer-link:hover .footer-icon {
    opacity: .7;
}

.card-headline {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
    font-size: 3.4rem;
    text-transform: uppercase;
}

@media screen and (max-width: 35rem) {
    .card-headline {
        margin-top: 2.5rem;
        font-size: 2.7rem;
    }
}

.table-of-content {
    margin-right: 2vw;
    border: .1rem solid blue;
    width: 24vw;
    height: fit-content;
    display: inline-block;
}

@media screen and (max-width: 68rem) {
    .table-of-content {
        width: 32vw;
    }
}

@media screen and (max-width: 68rem) {
    .table-of-content {
        width: 32vw;
    }
}

@media screen and (max-width: 1000px) {
    .table-of-content {
        width: 38.5vw;
    }
}

@media screen and (max-width: 45rem) {
    .table-of-content {
        margin-bottom: 2rem;
        width: 75%;
    }
}

@media screen and (max-width: 35rem) {
    .table-of-content {
        margin-bottom: 2rem;
        width: 100%;
    }
}

.table-of-content > div:last-child {
    border-bottom: none;
}

.table-entry {
    padding-top: .7rem;
    padding-bottom: .7rem;
    padding-left: 1.1rem;
    padding-right: 1.1rem;
    border-bottom: .1rem solid blue;
    text-transform: uppercase;
}

.card-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 45vw;
    column-gap: 2vw;
    row-gap: 2vw;
}

@media screen and (max-width: 83rem) {
    .card-container {
    grid-template-columns: 1fr;
    width: 32vw;
    }
}

@media screen and (max-width: 1000px) {
    .card-container {
        width: 38.5vw;
        row-gap: 2rem;
    }
}

@media screen and (max-width: 45rem) {
    .card-container {
        width: 75%;
    }
}

@media screen and (max-width: 35rem) {
    .card-container {
        width: 100%;
    }
}

.img-container {
    position: relative;
}

.card {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: .1rem solid blue;
}

.card-img {
    width: 100%;
    box-sizing: border-box;
    flex-grow: 1;
}

.card-text {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    transition: opacity .2s ease-in-out;
}

.hooper-navigation {
    z-index: 100;
}

.hooper-navigation svg {

}

.no-scrollbar::-webkit-scrollbar,
.top-text::-webkit-scrollbar,
.card-text::-webkit-scrollbar {
    display: none;
}

.no-scrollbar, .top-text, .card-text {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.arranger-speaker-cards {
    display: flex;
}

.miro-icon {
    width: 4rem;
    transition: filter .15s ease-in-out;
}

.card-miro-icon {
    width: 2.2rem;
}

@media screen and (max-width: 80rem) {
    .miro-icon {
        width: 2rem;
    }
}

@media screen and (max-width: 66rem) {
    .miro-icon {
        width: 1.8rem;
    }
}

.calendar-dialog-date {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 1.8rem;
    font-size: 1.8rem;
    flex-wrap: wrap;

    > div {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

#banner-video {
    width: 100%;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

#video-arranger {

}

.content-arranger.wide {
    width: 90%;
}

</style>

<i18n>
{
    "de":{
        "send": "SENDEN",
        "nl_confirm_header": "Anmeldung erfolgreich",
        "nl_confirm_text": "Dank für deine Anmeldung! Bitte überprüfe dein Postfach nach einem Bestätigungslink."
    },
    "en": {
        "send": "SEND",
        "nl_confirm_header": "Registration successful",
        "nl_confirm_text": "Thank you for subscribing! Please check your mailbox for a confirmation link."
    }
}
</i18n>
