<template>
    <div id="question-container" v-if="$store.state.questions.length > 0" @click="$emit('click')">
        <div class="question"
            v-for="(question, index) in extendedQuestions"
             :ref="`question${index}`"
             :class="{
                 activeQuestion: isActive(index),
                 questionTransition: transitionEnabled,
                 questionVisible: questionsVisible,
             }"
             :style="{
                 transform: `translateX(-${currentOffset}px)`
             }"
             :key="`qs_${index}`">
            <div>{{getLocalizedQuestionText(question)}}</div>
            <div>{{getLocalizedQuestionText(question)}}</div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
    name: "QuestionRubberband",
    data() {
        return {
            realIndex: 2,
            transitionEnabled: false,
            questionsVisible: false,
            offsetDummyValue: 0,
        }
    },
    methods: {
        isActive(index) {
            return index === this.realIndex
                || index + this.$store.state.questions.length === this.realIndex
                || index - this.$store.state.questions.length === this.realIndex;
        },
        getLocalizedQuestionText(question) {
            if (this.currentLang === "de") {
                return question.question_de;
            } else {
                return question.question_en;
            }
        },
    },
    computed: {
        currentLang() {
            return this.$store.state.lang;
        },
        currentIndex() {
            return this.$store.state.currentQuestion;
        },
        currentOffset() {
            console.log(this.offsetDummyValue);

            const elem = this.$refs[`question${this.realIndex}`];
            if (elem && elem.length > 0) {
                let offset = this.$refs[`question${this.realIndex}`][0].offsetLeft;
                offset -= window.innerWidth / 2 - this.$refs[`question${this.realIndex}`][0].offsetWidth / 2;

                return offset;
            } else {
                return 0;
            }
        },
        extendedQuestions() {
            const qs = this.$store.state.questions;
            if (qs && qs.length > 2) {
                return qs.slice(qs.length - 2, qs.length).concat(qs).concat(qs.slice(0, 2));
            } else {
                return qs;
            }
        }
    },
    watch: {
        currentLang() {
            setTimeout(() => this.offsetDummyValue = Date.now(), 200);
            console.log("Update currentLang");
        },
        currentOffset(offset) {
            if (!this.questionsVisible && offset > 0) {
                setTimeout(() => {
                    this.questionsVisible = true;
                    this.transitionEnabled = true;
                });
            }
        },
        currentIndex(index, oldIndex) {
            if (index - oldIndex > 1) {
                // left border

                this.realIndex = oldIndex + 1;
                setTimeout(() => {
                    this.transitionEnabled = false;

                    setTimeout(() => {
                        this.realIndex = index + 2;
                    }, 10);

                    setTimeout(() => {
                        this.transitionEnabled = true;
                    }, 100);
                }, 310);

            } else if (oldIndex - index > 1) {
                // right border

                this.realIndex = oldIndex + 3;
                setTimeout(() => {
                    this.transitionEnabled = false;
                    setTimeout(() => {
                        this.realIndex = index + 2;
                    }, 10);

                    setTimeout(() => {
                        this.transitionEnabled = true;
                    }, 100);
                }, 310);

            } else {
                this.realIndex = index + 2;
            }
        }
    }
})
</script>

<style scoped>

#question-container {
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.question {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    font-size: 1.3rem;
    color: white;
    height: 100%;
    max-width: 55%;
    flex-grow: 1;
    flex-shrink: 0;
    margin-right: 10rem;
    cursor: pointer;

    position: relative;
    opacity: 0;
}

@media screen and (max-width: 1200px) {
    .question {
        max-width: 80%;
        font-size: 1.1rem;
    }
}

.questionVisible {
    opacity: 1;
}

.question > div {
    transition: opacity 0.2s ease-in-out;
}

.question > div:nth-child(1) {
    font-weight: bold;
    opacity: 0;
}

.question > div:nth-child(2) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-weight: lighter;
    opacity: 1;
}

.questionTransition {
    transition: all 0.3s ease-in-out;
}

.activeQuestion > div:nth-child(1) {
    opacity: 1;
}

.activeQuestion > div:nth-child(2) {
    opacity: 0;
}

</style>
