<template>
  <div @click="handleClick" class="navLink">
    <router-link :to="$props.to">
      <slot></slot>
    </router-link>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "NavLink",
  props: {
    to: String,
  },
  methods: {
    handleClick() {
      console.log("emit navClick");
      this.$parent.$emit("navClick", this.to);
    }
  }
})
</script>

<style scoped>

.navLink * {
  
  cursor: pointer;
}

</style>
