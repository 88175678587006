<template>
    <div class="container">
        <div class="innerContainer"
             :class="{innerContainerTransition: enableTransition && !disableTransition}"
             :style="{transform: `translateY(calc(${-currentYPos}px + 50vh))`}">

            <div class="innerSlide"
                 style="visibility: visible"
                 v-for="(answer, index2) in $props.answers.slice(18, 20)"
                 :key="`pre-${index2}`"
                 :class="{hidden: answer.hidden}"
                 @click="emitClick(18 + index2, $event)">
                <Slide :answer="answer"></Slide>
            </div>

            <div class="innerSlide"
                 style="visibility: visible"
                 v-for="(answer, index2) in $props.answers.slice(0, 10)"
                 :class="{hidden: answer.hidden}"
                 :key="`1-${index2}`" @click="emitClick(index2, $event)">
                <Slide :answer="answer" :info-img-url="index2 === infoImgPosition ? infoImgUrl : ''"></Slide>
            </div>

            <div class="innerSlide" @click="emitClick(10, $event)">
                <div class="text-wrapper input" v-if="!$props.isSubmitted">

                    <div class="slide-text">
                      <textarea
                          v-model="userAnswer"
                          class="answer-input-field"
                          maxlength="300"
                          :placeholder="question + ' \n\n' + $t('placeholder')">

                      </textarea>
                        <input
                            type="submit"
                            @click="current === 10 ? sendAnswer() : emitClick(10, $event)"
                            class="submit-button disabled-submit-button"
                            :disabled="!userAnswer"
                            :value="$t('submit')">
                    </div>
                </div>

                <div class="text-wrapper" v-if="$props.isSubmitted">
                    <div class="slide-text">
                        <div v-if="!editAnswer" class="slide-guy">{{$store.state.user.occupation}}, {{$store.state.user.age}}</div>
                        <div v-if="!editAnswer" style="height: 13rem">{{$props.customAnswer}}</div>
                        <textarea
                            v-if="editAnswer"
                            v-model="userAnswer"
                            class="answer-input-field"
                            maxlength="300"
                            :placeholder="question + ' \n\n' + $t('placeholder')">
                        </textarea>
                        <input
                            type="submit"
                            @click="clickEdit"
                            class="submit-button edit-button disabled-submit-button"
                            :disabled="!userAnswer"
                            :value="editAnswer ? $t('submit') : $t('edit')">
                    </div>
                </div>
            </div>

            <div class="innerSlide"
                 style="visibility: visible"
                 v-for="(answer, index2) in $props.answers.slice(10, 20)"
                 :class="{hidden: answer.hidden}"
                 :key="`2-${index2}`"
                 @click="emitClick(11 + index2, $event)">
                <Slide :answer="answer" :info-img-url="index2 + 10 === infoImgPosition ? infoImgUrl : ''"></Slide>
            </div>

            <div class="innerSlide"
                 style="visibility: visible"
                 v-for="(answer, index2) in $props.answers.slice(0, 2)"
                 :class="{hidden: answer.hidden}"
                 :key="`post-${index2}`"
                 @click="emitClick(index2, $event)">
                <Slide :answer="answer"></Slide>
            </div>
        </div>
    </div>

</template>

<script lang="ts">

import Vue from "vue";
import Slide from "./Slide";

export default Vue.extend({
    name: "VerticalCarousel",
    components: {
        Slide,
    },
    props: {
        isSubmitted: Boolean,
        answers: Array,
        question: String,
        customAnswer: String,
        current: Number,
        disableTransition: Boolean,
        infoImgIndex: Number,
        infoImgPosition: Number,
    },
    data() {
        return {
            userAnswer: this.customAnswer || "",
            timeout: null,
            enableTransition: false,
            editAnswer: false,
        };
    },
    computed: {
        currentYPos() {
            const height = 350;
            const margin = 50;

            return (2 + this.current) * (height + margin) + (height) / 2;
        },
        infoImgUrl() {
            if (this.$store.state.infoImgs.length > this.infoImgIndex) {
                return this.$store.state.infoImgs[this.infoImgIndex];
            } else {
                return "";
            }
        }
    },
    methods: {
        clickEdit(ev) {
            if (this.editAnswer) {
                if (this.current === 10) {
                    this.sendAnswer();

                    setTimeout(() => this.editAnswer = false, 300);
                } else {
                    this.emitClick(10, ev);
                }
            } else {
                this.editAnswer = true;
            }
        },
        sendAnswer() {
            this.$emit("sendAnswer", this.userAnswer);
        },
        emitClick(index, event) {
            this.$emit("clickOnCard", {index, event});
        }
    },
    mounted() {
        this.enableTransition = this.$props.answers.length > 1;
    },
    watch: {
        answers() {
            //this.offset = ans.length;
            setTimeout(() => this.enableTransition = true, 100);
        },
        userAnswer: function(val) {
            console.log("update answer text " + val);
            this.$emit("updateAnswer", val);
        },
        customAnswer: function(val) {
            this.userAnswer = val;
        }
    }
});

</script>

<style>

.container {
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.innerContainerTransition {
    transition: transform 0.2s ease-in-out;
}

.innerContainer {
    will-change: transform;
}

.innerSlide {
    margin-top: 50px;
    margin-right: 25px;
    margin-left: 25px;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.79);
    border: .1rem #0000ff solid;
    color: rgba(255, 255, 255, 0.4);
    height: 350px !important;
    overflow: hidden;
    backface-visibility: hidden;
}

.innerSlide.hidden {
    opacity: 0;
    pointer-events: none;
}

.is-current .innerSlide, .is-current .answer-input-field {
    border: .1rem #539eff solid;
    box-shadow: #539eff 0 0 0.7rem;
    color: white;
}

.is-current .innerSlide {
    background-color: rgba(83, 158, 255, .25);
}

.is-current .answer-input-field {
    background-color: rgba(0,0,0, .2);
}

.one-line {
    line-height: 2.5ex;
    height: 5ex;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-wrapper {
    margin: 1.5rem;
    height: 500px;
}

.text-wrapper.input {
    height: 100%;
}

.answer-input-field {
    margin-top: 1rem;
    margin-bottom: .5rem;
    padding: 0.5rem 0.6rem 0.4rem 0.8rem;
    height: 13rem;
    width: 100%;
    box-sizing: border-box;
    border: 0.1rem solid #0000ff;
    background-color: rgba(0,0,0, 0);
    color: white;
    font-size: 1rem;
    resize: none;

    cursor: url(../assets/circle-white.svg), auto;
}

textarea {
    font-family: 'KoHo', sans-serif;
    font-weight: 600;
}

</style>

<i18n>
{
    "en": {
        "submit": "Submit",
        "your-answer": "Your answer",
        "edit": "Edit answer",
        "placeholder": "What is your opinion?"
    },
    "de": {
        "submit": "Abschicken",
        "your-answer": "Deine Antwort",
        "edit": "Bearbeiten",
        "placeholder": "Was ist deine Meinung?"
    }
}
</i18n>
