<template>
    <div class="filterMenu" :class="{visible, visible2}">
        <div class="filterSection">
            <div class="filterSubSection">
                <div class="headline">{{$t("profession")}}</div>

                <div class="dropdown">
                    <model-select id="professionSelector"
                                  v-model="professionField"
                                  :options="occupations"
                                  placeholder="Select item">

                    </model-select>
                </div>

                <div class="filterSelections">
                    <div v-for="(prof, index) in professionFilter" :key="prof">
                        <div>{{ prof }}</div>
                        <img src="../assets/close-icon.svg" @click="professionFilter.splice(index, 1)">
                    </div>
                </div>
            </div>
        </div>

        <div class="filterSection">
            <div class="filterSubSection">
                <div class="headline">{{ $t("country") }}</div>

                <div class="dropdown">
                    <model-select id="countrySelector"
                                  v-model="countryField"
                                  :options="countries"
                                  placeholder="Select item">

                    </model-select>
                </div>

                <div class="filterSelections">
                    <div v-for="(country, index) in countryFilter" :key="country" @click="countryFilter.splice(index, 1)">
                        <div>{{ translateCountryId(country) }}</div>
                        <img src="../assets/close-icon.svg" @click="professionFilter.splice(index, 1)">
                    </div>
                </div>
            </div>
        </div>

        <div class="filterSection">
            <div class="filterSubSection">
                <div class="headline" style="margin-bottom: 3rem">{{ $t("age") }}</div>

                <vue-slider
                    v-model="ageRange"
                    :tooltip="'always'"
                    :tooltipStyle="{border: '0.1rem solid blue', backgroundColor: 'black', color: 'white'}"
                    :railStyle="{backgroundColor: '#999'}"
                    :processStyle="{backgroundColor: 'blue'}"
                    :width="200"
                    :enable-cross="false"></vue-slider>
            </div>
            <div class="filterSubSection">
                <button class="submit-button" @click="$emit('close')">{{ $t("applyFilter") }}</button>
            </div>

        </div>
    </div>
</template>

<script>

import Vue from "vue";
import { ModelSelect } from "vue-search-select";
import VueSlider from 'vue-slider-component'
import { COUNTRIES } from "../countries.ts";

export default Vue.extend({
    name: "FilterMenu",
    props: {
        visible: Boolean,
    },
    components: {
        ModelSelect, VueSlider,
    },
    data() {
        return {
            modified: false,
            visible2: false,
            professionFilter: this.$store.state.filter.professionFilter.slice(0),
            professionField: "",
            countryFilter: this.$store.state.filter.countryFilter.slice(0),
            countryField: "",
            ageRange: this.$store.state.filter.ageRange.slice(0),
            countries: [],
            occupations: ["Design", "Kreativwirtschaft", "Tech"].map(item => ({value: item, text: item})),
            occupationsAll: [
                "Grafik-Design", "UX-Design", "UI-Design", "Mode-Design", "Produkt-Design", "Textil-Design",
                "Industrie-Design", "Motion-Design", "Web-Design", "App-Design", "Architektur",
                "Projektmanager", "Account Manager", "Creative Director", "Texter", "Geschäftsführer",
                "Front-End-Entwickler", "Projektmanager", "Back-End-Entwickler", "Full-Stack-Entwickler", "Geschäftsführer", "QA",
                "UX-Design", "Forschung",
                "Kunst, Kultur, Gestaltung / Design",
                "Medien, Marketing, Kommunikation",
                "IT, Computer, Informatik",
                "Architektur, Bau, Vermessung",
                "Mode, Textil",
                "Soziales, Bildung",
                "Öffentlicher Dienst",
                "Recht, Sicherheit",
                "Personal, Verwaltung",
                "Landwirtschaft, Umwelt, Tiere, Pflanzen",
                "Gesundheit, Pflege, Therapie, Pharma",
                "Körperpflege, Schönheit",
                "Naturwissenschaften",
                "Freizeitwirtschaft, Sport",
                "Hotellerie, Gastronomie, Tourismus",
                "Versicherung",
                "Wirtschaft, Finanzen",
                "Vertrieb, Verkauf, Einkauf",
                "Ingenieurswesen",
                "Handwerk, Fertigung, Produktion",
                "Technik, Elektronik, Maschienenbau",
                "Verkehr, Logistik"
            ].map(item => ({value: item, text: item})),
        };
    },
    mounted() {
        this.updateCountries();
    },
    watch: {
        "$store.state.questions"() {
            this.updateCountries();
        },
        visible(val) {
            setTimeout(() => {
                this.visible2 = val;
            }, 20);

            if (!val && this.modified) {
                this.updateFilterStore();
            }
        },
        professionField(filter) {
            if (filter !== "") {
                this.modified = true;
                this.professionFilter.push(filter);
                this.professionField = "";
            }
        },
        countryField(filter) {
            if (filter !== "") {
                this.modified = true;
                this.countryFilter.push(filter);
                this.countryField = "";
            }
        },
        ageRange() {
            this.modified = true;
        }
    },
    methods: {
        updateFilterStore() {
            this.$store.commit("updateFilter", {
                professionFilter: this.professionFilter.slice(0),
                countryFilter: this.countryFilter.slice(0),
                ageRange: this.ageRange.slice(0)
            });
        },
        updateCountries() {
            this.countries = COUNTRIES.map(country => {
                const qs = this.$store.state.questions;
                let counter = 0;

                for (const q of qs) {
                    for (const ans of q.answerPool) {
                        if (ans.country === country.alpha3) {
                            counter++;
                        }
                    }
                }

                return {
                    text: country.name_en + "  (" + counter + ")",
                    amount: counter,
                    value: country.alpha3
                };
            }).filter(ent => ent.amount > 0);
        },
        translateCountryId(id) {
            const country = COUNTRIES.find(c => c.alpha3 === id)

            if (this.$store.state.lang === 'de') {
                return country.name;
            } else {
                return country.name_en;
            }
        }
    }
});
</script>

<style>

.filterMenu {
    display: none;
    position: absolute;
    right: 100%;
    top: 0;
    opacity: 0;
    background-color: black;
    border: 0.1rem solid white;
    transition: 0.2s ease-in-out;
    flex-direction: row;
    cursor: default;
}

.filterMenu.visible {
    display: flex;
}

.filterMenu.visible2 {
    opacity: 1;
}

.filterSection, .filterSubSection {
    display: flex;
    flex-direction: column;
}

.filterSubSection {
    padding: 1.6rem;
}

.filterSubSection + .filterSubSection {
    border-top: 0.1rem solid white;
}

.filterSection + .filterSection {
    border-left: 0.1rem solid white;
}

.filterSection .headline {
    font-size: 1.3rem;
    margin-bottom: 2rem;
    color: white;
}

.filterSection .dropdown {
    width: 14rem;
}

.filterSelections {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.filterSelections > * {
    border: 0.1rem solid #0000ff;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    color: white;
    display: flex;
    flex-direction: row;
}

.filterSelections img {
    width: 10px;
    height: 10px;
    opacity: 1 !important;
    padding-right: 0.1rem !important;
    padding-left: 0.3rem !important;
    cursor: pointer;
}

.filterSelections img:hover {
    opacity: 0.3 !important;
}

</style>
<i18n>
{
    "en": {
        "applyFilter": "APPLY",
        "age": "Age",
        "profession": "Profession",
        "country": "Country"
    },
    "de": {
        "applyFilter": "ANWENDEN",
        "age": "Alter",
        "profession": "Beruf",
        "country": "Land"
    }
}
</i18n>
