<template>
    <Dialog ref="dialog" :lightMode="lightMode">
        <form @submit.prevent="register" :class="{light: lightMode}" id="nl-form">
            <div class="content-sub-headline input-field-headline">{{ $t("header") }}</div>
            <div class="main-text">{{ $t("mainText") }}</div>

            <label class="input-field-text" for="email">{{ $t("email") }}</label>
            <input required class="input-field input-field-popup" v-model="email" id="email" type="email">

            <label class="input-field-text" for="firstName">{{ $t("firstName") }}</label>
            <input required class="input-field input-field-popup" v-model="firstName" id="firstName" type="text">

            <label class="input-field-text" for="lastName">{{ $t("lastName") }}</label>
            <input required class="input-field input-field-popup" v-model="lastName" id="lastName" type="text">

            <input class="submit-button submit-button-popup" type="submit" :disabled="isDisabled" :value="$t('submitText')">
        </form>
    </Dialog>
</template>

<script lang="ts">
import Vue from "vue";
import Dialog from "@/components/Dialog.vue";
import API from "@/service/API.ts";

export default Vue.extend({
    name: "NewsletterDialog",
    components: {Dialog},
    props: {
        lightMode: Boolean,
    },
    data() {
        return {
            email: "",
            firstName: "",
            lastName: ""
        }
    },
    computed: {
        isDisabled() {
            return this.email.trim() === "" || this.firstName.trim() === "" || this.lastName.trim() === "";
        }
    },
    methods: {
        open(email) {
            if (email) this.email = email;
            this.$refs.dialog.open();
        },
        close() {
            this.$refs.dialog.close();
        },
        async register() {
            try {
                const resp = await fetch(API.getBaseUrl() + "/subscribe", {
                    method: "POST",
                    body: "email=" + encodeURIComponent(this.email)
                        + "&first_name=" + encodeURIComponent(this.firstName)
                        + "&last_name=" + encodeURIComponent(this.lastName)
                        + "&lang=" + encodeURIComponent(this.$store.state.lang),
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Cache-Control': 'no-store'
                    }
                });

                const respJson = await resp.json();

                if (resp.status !== 200) {
                    console.log("newsletter failed: " + respJson.msg);
                }
            } catch (err) {
                console.log(err);
            }

            this.close();
            this.$emit("subscribed");
        }
    }
});

</script>

<style scoped>

.main-text {
    margin-bottom: 2rem;
}

#nl-form.light input {
    background-color: white;
    color: blue;
}

#nl-form.light .submit-button {
    border: 0.1rem solid #0000ff;
}

#nl-form.light .submit-button:hover {
    background-color: blue;
    color: white;
}

#nl-form.light .submit-button[disabled] {
    background-color: #ccc;
    color: black;
    border: 0.1rem solid black;
}

</style>

<i18n>
{
    "en": {
        "header": "Newsletter registration",
        "mainText": "Please review your data to subscribe to our newsletter",
        "submitText": "Subscribe",
        "firstName": "First name",
        "lastName": "Last name",
        "email": "Email Adress"
    },
    "de": {
        "header": "Newsletter-Anmeldung",
        "mainText": "Bitte überprüfe deine Angaben um dich zu dem Newsletter anzumelden",
        "submitText": "Anmelden",
        "firstName": "Vorname",
        "lastName": "Nachname",
        "email": "E-Mail-Adresse"
    }
}
</i18n>
