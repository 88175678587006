import Vue from "vue";
import VueRouter from "vue-router";
import Questions from "../views/Questions.vue";
import About from "@/views/About";
import NotFound from "@/views/NotFound";
import Events from "@/views/Events";
import Imprint from "@/views/Imprint";
import Stats from "@/views/Stats";
import Landing from "@/views/TheLandingPage";
import Recipe from "@/views/Recipe";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        component: Landing,
        name: "landing"
    },
    {
        path: "/about",
        component: About,
        name: "about"
    },
    {
        path: "/events",
        redirect: "/shuttleevents"
    },
    {
        path: "/shuttleevents",
        component: Events,
        name: "events"
    },
    {
        path: "/imprint",
        component: Imprint,
        name: "imprint"
    },
    {
        path: "/stats",
        component: Stats,
        name: "stats"
    },
    {
        path: "/interview",
        component: Questions,
        name: "interview"
    },
    {
        path: "*",
        component: NotFound
    },
    {
        path: "/recipe/:id",
        component: Recipe,
        name: "recipe"
    }
];

const router = new VueRouter({
    routes,
    mode: "history"
});

export default router;
