<template>
    <div id="root" :class="{questions: $route.name === 'interview', landing: $route.name === 'landing' || $route.name === 'recipe'}" @click="handleRootClick">

        <Navigation id="nav-wrapper"
                    :style="{ opacity: controlsVisible ? 1 : 0}"
                    v-if="$route.name === 'interview'">
            <div class="nav-shadow" id="nav-left-shadow"></div>
            <div id="nav-left">
                <QuestionRubberband @click="onMitmachen"></QuestionRubberband>
            </div>
            <div class="nav-shadow" id="nav-right-shadow"></div>
            <div class="nav-arrow"></div>
            <div class="nav-arrow nav-arrow-2"></div>
        </Navigation>

        <div id="hamburger" v-if="$route.name !== 'landing' && $route.name !== 'recipe'" :class="{'main-route': $route.name === 'interview'}" :style="{ opacity: controlsVisible ? 1 : 0}" @click="mobileMenuOpen = true; menuOpen = true">
            <svg style="width:40px;height:40px" viewBox="0 0 24 24">
                <path fill="white" d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
            </svg>
        </div>

        <div @click="mobileMenuOpen = false" id="mobileOverlay" :class="{'mobile-active': mobileMenuOpen}"></div>

        <div id="header-box" v-if="$route.name !== 'landing' && $route.name !== 'recipe'" :style="{ opacity: controlsVisible ? 1 : 0}" :class="{ active: menuOpen || mobileMenuOpen || permanentMenu, 'mobile-active': mobileMenuOpen }">
            <div id="axd-logo-container">
                <a href="https://aixdesign.space/">
                    <svg alt="logo" class="axd-logo" id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 259.45 259.45">
                        <circle class="cls-1" cx="129.72" cy="129.72" r="129.72"/>
                        <path class="cls-2" d="M23.66,105s4.64-18.06,24-18.06,22.78,13.83,22.78,18.92V125.3c0,13.09-51.8-8.38-51.8,25,0,12.54,4.58,21.83,21.05,21.83H66.16c8.91,0,13.51-6,13.36-14-.12-6.36.15-50.59.15-64"/>
                        <path class="cls-2" d="M79.67,90.69c0-3.76,1.29-4.76,4.88-3.74"/>
                        <path class="cls-2" d="M232.14,90.69c0-3.76-1.29-4.76-4.87-3.74"/>
                        <path class="cls-2" d="M185.89,172.51H213.6c4.81,0,18.54-.6,18.54-13V89.78"/>
                        <path class="cls-2" d="M232.14,121.27c0,5.46-16.31,4.08-27.18,4.2-12.63.15-26.29,4.81-26.29,23.45,0,6.71,1.18,11,3.48,14.67"/>
                        <path class="cls-2" d="M86.74,87.89c2.52.52,54.35,54,76.82,75.45,9.15,8.76,14.69,9.17,27.81,9.17"/>
                        <line class="cls-2" x1="86.74" y1="172.1" x2="172.71" y2="87.35"/>
                    </svg>
                </a>
            </div>
            <div id="box-item-wrapper">
                <div id="box-item-wrapper-bg"></div>

                <div class="box-item" :class="{'border-bottom': $route.name === 'events'}">
                    <div style="padding: .75rem" @click="$store.commit('setLang', $store.state.lang === 'de' ? 'en' : 'de')">{{ $t("languages") }}</div>
                </div>

                <div id="navFilterMenuItem" class="box-item" v-if="$route.name === 'main'" :class="{'active': filterActive}" @click.stop="">
                    <div style="padding: .75rem" @click.stop="filterMenuVisible = !filterMenuVisible">
                        <div>FILTER</div>
                        <div v-if="filterActive">{{ filterAmount }} {{ $t("filtersActive")}} </div>
                    </div>
                    <img src="./assets/close-icon.svg" @click="resetFilters">
                    <FilterMenu id="filterMenuContainer" :visible="filterMenuVisible" @close="filterMenuVisible = false"></FilterMenu>
                </div>

                <div class="box-item border-bottom" v-if="$route.name !== 'events'">
                    <a class="marquee" style="padding: .6rem 0; height: 1.6rem" @click="onMitmachen">
                        <div>
                            <span>{{ $t("participate") }}</span>
                        </div>
                        <div>
                            <span>{{ $t("participate") }}</span>
                        </div>
                    </a>
                </div>

                <div id="box-extendable-menu-container">
                    <div id="box-extendable-menu" :class="{'normal-animation': (menuOpen || mobileMenuOpen || permanentMenu) && !reverseAnimationStart, 'reverse-animation': reverseAnimationStart}">
                        <router-link @click.native="mobileMenuOpen = false" to="/interview" v-if="$route.name === 'events'">{{ $t("nav-your-opinion") }}</router-link>
                        <router-link @click.native="mobileMenuOpen = false" to="/events" :class="{active: $route.name === 'events'}">{{ $t("events") }}</router-link>
                        <router-link @click.native="mobileMenuOpen = false" to="/about" :class="{active: $route.name === 'about'}">{{ $t("about-us") }}</router-link>
                    </div>
                </div>

                <div id="newsletter-minimized"
                     v-if="$store.state.floatingNewsletterState === 'minimized'"
                     @click="$store.commit('setFloatingNewsletterState', 'show')">{{ $t("newsletter") }}</div>
                <div id="box-dse-imprint" @click="$router.push('/imprint')"><div>{{ $t("imprint-link") }}</div></div>
            </div>
        </div>

        <router-view ref="routerView"/>

        <FloatingNewsletterDialog v-if="showFloatingNewsletterDialog"></FloatingNewsletterDialog>
    </div>

</template>

<script lang="ts">
import Vue from "vue";
import NavLink from "./components/NavLink.vue";
import Navigation from "./components/Navigation.vue";
import QuestionRubberband from "./components/QuestionRubberband.vue";
import FloatingNewsletterDialog from "./components/FloatingNewsletterDialog.vue";
import FilterMenu from "./components/FilterMenu.vue";

export default Vue.extend({
    name: "App",
    components: {
        FilterMenu,
        Navigation,
        NavLink,
        QuestionRubberband,
        FloatingNewsletterDialog,
    },
    data() {
        return {
            acc0Open: false,
            controlsVisible: false,
            menuOpen: false,
            mobileMenuOpen: false,
            blockToggleMenu: false,
            reverseAnimationStart: false,
            filterMenuVisible: false,
        }
    },
    mounted() {
        this.resetFilters();

        setTimeout(() => {
            this.controlsVisible = true;
        }, 200);

        if (this.$route.query.newsletter !== undefined) {
            this.$store.commit("setFloatingNewsletterState", "show");
            this.$router.replace({
                query: {},
            });
        }
    },
    computed: {
        filterActive() {
            return this.$store.state.filter.professionFilter.length > 0
                || this.$store.state.filter.countryFilter.length > 0
                || this.$store.state.filter.ageRange[0] !== 0
                || this.$store.state.filter.ageRange[1] !== 100;
        },
        permanentMenu() {
            return true;
        },
        showFloatingNewsletterDialog() {
            return this.$store.state.floatingNewsletterState === "show";
        },
        filterAmount() {
            let a = 0;
            if (this.$store.state.filter.ageRange[0] !== 0
                || this.$store.state.filter.ageRange[1] !== 100) {
                a++;
            }

            a += this.$store.state.filter.professionFilter.length;
            a += this.$store.state.filter.countryFilter.length;

            return a;
        }
    },
    methods: {
        resetFilters() {
            this.$store.commit("resetFilter");
        },
        handleRootClick() {
            if (this.filterMenuVisible) {
                this.filterMenuVisible = false;
            }
        },
        toggleMenu() {
            if (this.blockToggleMenu) return;

            if (this.menuOpen) {
                this.blockToggleMenu = true;
                this.reverseAnimationStart = true;
                setTimeout(() => {
                    this.blockToggleMenu = false;
                    this.menuOpen = false;
                }, 300);
            } else {
                this.reverseAnimationStart = false;
                this.menuOpen = true;
            }
        },
        onMitmachen() {
            if (this.$route.name === "interview") {
                this.$refs.routerView.onMitmachen();
            } else {
                this.$router.push("/").catch(err => console.log(err));
            }
        }
    },
    watch: {
        acc0Open: function(val) {
            if (val) {
                this.$refs.acc0Panel.style.maxHeight = this.$refs.acc0Panel.scrollHeight + "px";
            } else {
                this.$refs.acc0Panel.style.maxHeight = null;
            }
        },
    }
})
</script>

<style lang="scss">
html {
  scroll-behavior: smooth;
}
.content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 11rem);
    height: 100%;
    color: white;
}

@media screen and (max-width: 1000px) {
    .content-container {
        width: 100%;
    }
}

html::-webkit-scrollbar, body::-webkit-scrollbar {
    display: none;
}

html, body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

select, input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

select {
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100'><polygon fill='white' points='0,0 100,0 50,50'/></svg>") no-repeat;
    background-size: 20px;
    background-position: calc(100% - 10px) calc(50% + 5px);
    background-repeat: no-repeat;
    background-color: white;
}

#root {
    cursor: url(./assets/circle-white.svg), auto;
    width: 100%;
}

#root.questions {
    height: 100vh;
}

#root.landing {
    cursor: default;
}

.nav-arrow {
    width: 0;
    height: 0;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
    border-top: 40px solid white;

    z-index: 10;
    left: 50%;
    top: 7.95rem;
    position: absolute;
    transform: translateX(-50%);
}

.nav-arrow-2 {
    margin-top: -0.15rem;
    border-top: 40px solid black;
}

.accordion {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .6rem;
}

.accordion:hover .accordion-icon {
    opacity: 1;
}

.accordion-icon {
    position: relative;
    opacity: 1;
    font-size: 1rem;
    color: rgb(255, 255, 255);
    justify-self: flex-end;
    padding-left: 0.8rem;
}

.accordion-icon > div {
    transition: opacity .2s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
}

.panel {
    overflow: hidden;
    max-height: 0;
    transition: max-height .2s ease-out;
}

#header-box {
    top: 0;
    right: 0;
    position: fixed;
    width: 11rem;
    z-index: 20;
    cursor: url(./assets/circle-white.svg), auto;
    opacity: 0;
    display: flex;
    flex-direction: column;

    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}

@media screen and (max-width: 1000px) {

    #header-box {
        transform: translateX(100%);
    }


    #header-box.mobile-active {
        transform: translateX(0);
    }
}

#header-box.active {
    border-bottom: none;
    height: 100vh;
}

#axd-logo-container {
    width: 100%;
    height: 11rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: .1rem solid white;
    background-color: black;
}

.axd-logo {
    width: 7.9rem;
}

#axd-logo-container:hover .cls-1 {
    fill: rgb(17, 0, 255) !important;
}


#axd-logo-container:hover .cls-2 {
    stroke : white !important;
}

.cls-1 {
    fill: #fff;
    transition: fill .35s ease-in-out;
    /*animation-name: logo-bg;
    animation-iteration-count: infinite;
    animation-duration: 40s;
    animation-delay: 10s;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;*/
}

.cls-2 {
    fill: none;
    stroke: #000;
    stroke-miterlimit: 10;
    stroke-width: 2.16px;
    transition: stroke .35s ease-in-out;
    /*animation-name: logo-text;
    animation-iteration-count: infinite;
    animation-duration: 40s;
    animation-delay: 10s;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;*/
}

@keyframes logo-bg {
    0% {
        fill: white;
    }

    100% {
        fill: rgb(17, 0, 255);
    }
}
@keyframes logo-text {
    0% {
        stroke: rgba(0, 0, 0, 255);
    }

    70% {
        stroke: rgba(255, 255, 255, 255);
    }

    100% {
        stroke: white;
    }
}


#box-item-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
}

#box-item-wrapper-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 0;
    background-color: black;
    border-left: .1rem solid white;
    transition: min-height 0.3s ease-in-out;
    z-index: 20;
}

#header-box.active #box-item-wrapper-bg {
    min-height: calc(100vh - 11rem);
}

.box-item {
    cursor: pointer !important;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    color: white;
    font-weight: 600;
    border-top: .1rem solid white;
    border-left: .1rem solid white;
    z-index: 21;
}

.border-bottom {
    border-bottom: .1rem solid white;
}

.box-item, .box-item .accordion-icon {
    transition: color 0.2s ease-in-out;
}

.inner-box-item {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    color: white;
    font-weight: 600;
    border-top: .1rem solid white;
    padding: .6rem;
    cursor: pointer;
}

.box-item:hover, .box-item:hover .accordion-icon, .inner-box-item:hover {
    color: #539eff;
}

.marquee {
    position: relative;
    cursor: pointer;
    background-color: white;
    color: black;
    transition: all 0.2s ease-in-out;
    display: flex;
    flex-direction: row;
    align-items: center;
    /*animation-name: marquee-test;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-duration: 10s;*/
}

@keyframes marquee-test {
    0% {
        background-color: rgb(17, 0, 255);
        color: white;
    }

    100% {
        background-color: white;
        color: black;
    }
}

.marquee:hover {
    background-color: rgb(17, 0, 255) !important;
    color: white !important;
}

.marquee > div {
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 400;
    position: absolute;
}

.marquee > div > span {
    display: inline-block;
    padding-left: calc(100% + 0.4rem);
    animation: marquee 10s linear infinite;
    animation-delay: -5s;
}

.marquee > div:nth-child(2) > span {
    animation-delay: 0s;
}

@keyframes marquee {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-100%, 0);
    }
}

#box-extendable-menu-container {
    position: relative;
    flex-grow: 1;
    z-index: 21;
}

#box-extendable-menu-container .arrow {
    position: absolute;
    right: 100%;
    top: 5.5rem;
    width: 0;
    height: 0;
    border-bottom: 20px solid transparent;
    border-top: 20px solid transparent;
    transform: translateY(-50%);
    z-index: 10;

    border-right: 20px solid black;
    transition: border-right 0.2s ease-in-out;

}

#box-extendable-menu-container .arrow-2 {
    margin-right: -0.1rem;
    border-right: 20px solid black;
}

#header-box.active #box-extendable-menu-container .arrow {
    border-right: 20px solid white;
}

#header-box.active #box-extendable-menu-container .arrow-2 {
    border-right: 20px solid black;
}

#box-extendable-menu {
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: 0;
    transition: max-height 0.2s linear;
}

#header-box.active #box-extendable-menu {
    max-height: initial;
}

@keyframes box-animation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes box-animation-reverse {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

#box-extendable-menu > * {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: .1rem solid white;
    box-sizing: border-box;
    transition: all 0.1s ease-in-out;
    opacity: 0;
    cursor: pointer;
    pointer-events: none;
    /*height: 11rem;*/
    padding: 1rem;
    text-align: center;
    /*flex-grow: 1;*/
}

@media screen and (max-height: 850px) {
    #box-extendable-menu > * {
        /*height: 6rem;*/
    }
}

#header-box.active #box-extendable-menu > * {
    pointer-events: all;
}

#box-extendable-menu > *.active {
    height: 11rem;
}

#box-extendable-menu > *.active, #box-extendable-menu > *:hover {
    background-color: rgba(83, 158, 255, .05);
    box-shadow: inset blue 0 0 20px;
}

#box-extendable-menu.normal-animation > * {
    animation: 0.7s box-animation normal forwards;
}

#box-extendable-menu.reverse-animation > * {
    animation: 0.3s box-animation-reverse normal forwards;
    animation-delay: 0s !important;
}

#box-extendable-menu.normal-animation > *:nth-child(1) {
    animation-delay: 0s;
}

#box-extendable-menu.normal-animation > *:nth-child(2) {
    animation-delay: 0.2s;
}

#box-extendable-menu.normal-animation > *:nth-child(3) {
    animation-delay: 0.4s;
}


#nav-wrapper {
    position: fixed;
    width: 100%;
    height: 7.9rem;
    background-color: rgba(0,0,0, 1);
    border-bottom: .1rem solid white;
    z-index: 5;
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
}

#nav-left-shadow {
    position: absolute;
    top: 0;
    left: 0;
    height: 6rem;
    width: 15%;
    z-index: 6;
    background-image: linear-gradient(to right, rgba(0,0,0,8), rgba(0,0,0,0));
    pointer-events: none;
}

#nav-right-shadow {
    position: absolute;
    top: 0;
    right: 0;
    height: 6rem;
    width: 15%;
    z-index: 6;
    background-image: linear-gradient(to left, rgba(0,0,0,8), rgba(0,0,0,0));
    pointer-events: none;
}

@media screen and (max-width: 1000px) {
    .nav-shadow {
        display: none;
    }
}

#nav-left {
    width: 100%;
    height: 100%;
}

#nav-left > * {
    width: 100%;
    height: 100%;
    text-align: center;
}

#nav-right {
    margin-right: 2.5rem;
    display: flex;
    flex-direction: row;
}

.nav-item {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    text-decoration: none;
    color: white;
    font-size: 1.4rem;
    font-weight: 600;
    text-transform: uppercase;
    transition: color .1s ease-in-out;
}

.nav-item:hover {
    color: rgb(148, 148, 148);
}

.nav-item-wrapper {
    text-decoration: none !important;
}

a {
    text-decoration: none;
    /*cursor: url(/assets/circle-white.svg), auto;*/
}

footer {
    bottom: 0;
    position: absolute;
    height: 7rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    color: white;
    font-size: 1.1rem;
    z-index: 5;
    pointer-events: none;
}

#footer-left {
    display: flex;
}

.footer-item {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    color: white;
    text-decoration: none;
    pointer-events: all;
}

.footer-img {
    height: 2.5rem;
}



#hamburger {
    position: absolute;
    right: 1rem;
    top: 1rem;
    background-color: rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    z-index: 18;
    padding: 0.3rem 0.3rem 0.1rem 0.3rem;
    display: none;
    transition: opacity 0.2s ease-in-out;
}

#hamburger.main-route {
    top: 8.5rem;
}

@media screen and (max-width: 1000px) {
    #hamburger {
        display: block;
    }
}

#mobileOverlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 19;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;

    display: none;
}

#mobileOverlay.mobile-active {
    display: block;
}



::selection {
    color: white;
    background: #0b4da3;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

a {
    text-decoration: none;
    color: white;
    transition: color .2s ease-in-out;
}

a:hover {
    color: #539eff;
}

main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    max-width: 100vw;
}

.content-headline {
    margin-top: 3rem;
    font-size: 3.2rem;
    font-weight: 600;
    color: black;
    text-align: center;
    line-height: 3.8rem;
    /* color: rgba(31, 123, 255, .8); */
}

.content-sub-headline {
    margin-top: 1rem;
    margin-bottom: 3rem;
    font-size: 1.8rem;
    font-weight: 600;
    text-align: center;
    opacity: .7;
}

.intro-card-wrapper {
    width: 27rem;
    height: 27rem;
    position: relative;
}

.intro-card {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0.7rem;
    transition: opacity .8s ease-in-out, transform .4s ease-in-out;
}

.intro-card-fade {
    transform: translateX(-2px);
}

.intro-card-headline {
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 4.8rem;
    font-size: 1.4rem;
    font-weight: 700;
    background-color: rgb(15, 124, 255);
    color: white;
}

.form-div {
    margin-top: 5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.input-field {
    margin-bottom: 1.2rem;
    padding: 0.5rem 0.6rem 0.4rem 0.8rem;
    height: 2.8rem;
    width: 65%;
    box-sizing: border-box;
    border: 0.13rem solid rgb(15, 124, 255);
    font-size: 1rem;
}

.input-field-headline {
    margin-top: 2.3rem;
    margin-bottom: 1.7rem;
    opacity: 1;
    font-size: 1.3rem;
}

.input-field-text {
    margin-bottom: 1rem;
    font-weight: 600;
}

.input-field-popup {
    margin-bottom: 1.4rem;
    width: 100%;
    min-height: 2.8rem;
    background-color: rgba(0, 0, 0, 0.85);
    border: .1rem solid #0000ff;
    color: white;
}

.submit-button-popup {
    margin-top: 1rem;
    margin-bottom: 2.3rem;
}

.submit-button-wrapper {
    width: 100%;
}


.submit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    min-height: 2.8rem;
    font-size: 1rem;
    border: 0.13rem solid #0000ff;
    background-color: rgba(0,0,0,0);
    color: white;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.4s ease 0s;

}

.disabled-submit-button {
    background-color: transparent;
    border: 0.13rem solid #000088;
    color: rgb(68, 68, 68);
    cursor: inherit;
}

.is-current .submit-button {
    border: .13rem #539eff solid;
    box-shadow: #539eff 0px 0px 0.7rem;
    background-color: rgba(0,0,0,0);
    color: white;
    cursor: pointer;
}

.submit-button[disabled]  {
    background-color: transparent;
    border: 0.13rem solid #000088;
    color: rgb(68, 68, 68);
    cursor: inherit;
}


.submit-button:hover {
    background-color: #539eff;
    border: .13rem #539eff solid;
    color: white;
}

.submit-button[disabled]:hover {
    background-color: transparent;
    border: .13rem #000088 solid;
    color: rgb(68, 68, 68);
}

.is-current .submit-button[disabled] {
    border: .13rem #294c7a solid;
    background-color: rgba(0,0,0, .2);
    box-shadow: none;
}


.cookie-notice, .cookie-modal {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 100;
    color: blue;
    border: .2rem solid blue;
    background-color: white;
    padding: 1rem;
    box-sizing: border-box;
    margin: 2rem;
    max-width: 34rem;
}

.klaro > * > * {
    position: relative;
}

.cn-ok {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 35rem) {
    .cn-ok {
        flex-direction: column;
    }
}


.cn-buttons, .cm-footer-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

@media screen and (max-width: 35rem) {
    .cn-buttons {
        width: 90%;
        justify-content: space-around;
    }
}

@media screen and (max-width: 30rem) {
    .cn-buttons {
        flex-direction: column;
    }
}


.cn-learn-more {
    font-weight: bold;
    cursor: pointer;
    color: blue;
}

@media screen and (max-width: 35rem) {
    .cn-learn-more {
        margin-bottom: 1rem;
        text-align: center;
    }
}

.cn-learn-more, .cn-decline {
    transition: all 0.4s ease;
}

.cn-learn-more:hover, .cn-decline:hover {
    color: #539eff;
}

.cm-btn, .cm-header .hide {
    border-radius: 0;
    background: transparent;
    font-size: 1rem;
    margin: 0;
    position: static;
    padding: 0 1rem;
}

@media screen and (max-width: 30rem) {
    .cm-btn {
        margin-bottom: .9rem;
    }
}

.cm-btn-success, .cm-header .hide, .cm-btn-danger {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 2.8rem;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 0.4s ease 0s;
    border: .13rem solid blue;
    color: blue;
    background-color: white;
    cursor: pointer;
}

.cm-btn-success:hover, .cm-header .hide:hover {
    background-color: blue;
    color: white;
}

.cn-decline {
    margin-right: 1.5rem;
    font-size: 0.8rem;
    text-transform: none;
    margin-left: 0.5rem;
    border: .13rem solid transparent;
    box-shadow: none;
}

.cn-decline:hover {
    color: blue;
    border: .13rem solid blue;
}

.cm-header .hide {
    padding: 0.3rem;
    height: auto;
    position: absolute;
    right: 0;
    top: 0.3rem;
}

.cm-header .hide svg * {
    stroke: white;
    stroke-width: 3px;
}

.cm-header h1 {
    margin: 0;
    font-size: 1.5rem;

}

.cm-footer {
    margin-top: 3rem;
}

.cm-powered-by {
    display: none;
}

.cm-content {
    display: none;
}

.cm-content.expanded {
    display: block;
}

#box-dse-imprint {
    color: white;
    font-style: italic;
    cursor: pointer;
    padding: 1rem;
    z-index: 30;
    display: flex;
    align-items: flex-start;
    font-size: 0.8rem;
    border-top: 0.1rem solid white;
    transition: color .2s ease-in-out, text-decoration .2s ease-in-out, box-shadow .1s linear;
}

#newsletter-minimized {
    z-index: 30;
    padding: 1rem;
    color: white;
    border-top: 0.1rem solid white;
    text-transform: uppercase;
    text-align: start;
    cursor: pointer;
    transition: box-shadow 0.1s linear;
}

#newsletter-minimized:hover, #box-dse-imprint:hover {
    box-shadow: inset blue 0 0 20px;
}

#bg-img {
    top: 0;
    left: 0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: -50;
}

.cm-header h1 {
    margin-right: 25px;
}

@media screen and (max-width: 600px) {
    .cookie-modal, .cookie-notice {
        margin: 1rem;
        max-width: min(30rem, calc(100vw - 2rem));
    }
}

#navFilterMenuItem {
    position: relative;
    display: flex;
    align-items: center;
    transition: 0.2s linear;
    justify-content: space-between;
    flex-direction: row;
    padding: 0;

    img {
        opacity: 0;
        transition: 0.2s linear;
        width: 1.3rem;
        height: 1.3rem;
        padding-right: 1rem;
    }
}

#navFilterMenuItem.active {
    box-shadow: inset blue 0 0 20px;
    padding: 2rem 0;

    img {
        opacity: 1;

        &:hover {
            filter: brightness(0.5);

        }
    }
}

</style>

<i18n>
{
    "en": {
        "filter": "FILTER",
        "participate": "+++ PARTICIPATE +++ CLICK HERE",
        "language": "LANGUAGE",
        "about-us": "ABOUT US",
        "events": "EVENTS",
        "contact": "CONTACT",
        "back": "BACK",
        "languages": "DEUTSCH",
        "menu": "MENU",
        "imprint-link": "Privacy/Imprint",
        "nav-your-opinion": "YOUR OPINION?",
        "newsletter": "Newsletter",
        "filtersActive": "filters active"
    },
    "de": {
        "participate": "+++ MITMACHEN +++ HIER KLICKEN",
        "filter": "FILTER",
        "language": "SPRACHE",
        "about-us": "ÜBER UNS",
        "events": "EVENTS",
        "contact": "KONTAKT",
        "back": "ZURÜCK",
        "languages": "ENGLISH",
        "menu": "MENÜ",
        "imprint-link": "Datenschutz/Impressum",
        "nav-your-opinion": "DEINE MEINUNG?",
        "newsletter": "Newsletter",
        "filtersActive": "Filter aktiv",
        "applyFilter": "ANWENDEN"
    }
}
</i18n>
