<template>

<div class="content-container" :style="{opacity: loaded ? 1 : 0}">
    <div class="imprint-headline">{{ $t("about-us") }}</div>

    <div class="imprint-box">
        <div class="imprint-sub-headline">{{ $t("part1_header") }}</div>
        <div class="imprint-text">
            {{ $t("part1") }}
        </div>
    </div>

    <div class="imprint-box">
        <div class="imprint-sub-headline">{{ $t("part2_header") }}</div>
        <div class="imprint-text">
            {{ $t("part2") }}
        </div>
    </div>

    <div class="imprint-box">
        <div class="imprint-sub-headline">{{ $t("part3_header") }}</div>
        <div class="imprint-text">
            {{ $t("part3") }}
        </div>
    </div>

    <div class="imprint-box">
        <div class="imprint-sub-headline">{{ $t("conclusion") }}</div>
        <div class="imprint-text">
            <a id="about-link" href="https://aixdesign.space/">www.aixdesign.space</a>
        </div>
    </div>

    <div class="imprint-box">
        <div class="imprint-sub-headline">{{ $t("contact") }}</div>
        <div class="imprint-text">
            Prof. Peter Kabel<br>
            Department Design<br>
            {{ $t("haw") }}<br>
            {{ $t("haw_short") }}<br>
            <a href="mailto:aixdesign@haw-hamburg.de">aixdesign@haw-hamburg.de</a>
        </div>
    </div>

    <div class="imprint-headline second-headline">{{ $t("team") }}</div>

    <div id="team-wrapper">
        <div class="card">
            <div class="team-img-wrapper">
                <img class="team-img" src="../assets/team/peter.jpg">
            </div>
            <div class="card-text-wrapper">
                <div class="name">Prof. Peter Kabel</div>
                <div class="job">{{ $t("lead") }} </div>
            </div>
        </div>

        <div class="card">
            <div class="team-img-wrapper">
                <img class="team-img" src="../assets/team/annika.jpg">
            </div>
            <div class="card-text-wrapper">
                <div class="name">Annika Schultz</div>
                <div class="job">{{ $t("design") }}</div>
            </div>
        </div>

        <div class="card">
            <div class="team-img-wrapper">
                <img class="team-img" src="../assets/team/tom.jpg">
            </div>
            <div class="card-text-wrapper">
                <div class="name">Tom-Lucas Säger</div>
                <div class="job">{{ $t("design") }}</div>
            </div>
        </div>

        <div class="card">
            <div class="team-img-wrapper">
                <img class="team-img" src="../assets/team/kim.png">
            </div>
            <div class="card-text-wrapper">
                <div class="name">Kim Lassen</div>
                <div class="job">{{ $t("design") }}</div>
            </div>
        </div>

        <div class="card">
            <div class="team-img-wrapper">
                <img class="team-img" src="../assets/team/katharina.png">
            </div>
            <div class="card-text-wrapper">
                <div class="name">Katharina Mumme</div>
                <div class="job">{{ $t("design") }}</div>
            </div>
        </div>

        <div class="card">
            <div class="team-img-wrapper">
                <img class="team-img" src="../assets/team/sarah.png">
            </div>
            <div class="card-text-wrapper">
                <div class="name">Sarah Pfeiffer</div>
                <div class="job">{{ $t("design") }}</div>
            </div>
        </div>

        <div class="card">
            <div class="team-img-wrapper">
                <img class="team-img" src="../assets/team/hauke.jpg">
            </div>
            <div class="card-text-wrapper">
                <div class="name">Hauke Oldsen</div>
                <div class="job">{{ $t("tech") }}</div>
            </div>
        </div>

        <div class="card">
            <div class="team-img-wrapper">
                <img class="team-img" src="../assets/team/lennart.jpg">
            </div>
            <div class="card-text-wrapper">
                <div class="name">Lennart Schmidt</div>
                <div class="job">{{ $t("tech") }}</div>
            </div>
        </div>
    </div>

    <div id="about-footer">
        <img class="footer-img" src="../assets/haw-logo.png">
        <img class="footer-img" src="../assets/hoou-logo.svg">
    </div>

    <img id="bg-img" class="imprint-bg" src="../assets/UXD.svg">
</div>

</template>

<script>

export default {
    name: "About",
    components: {},
    data() {
        return {
            loaded: false,
        };
    },
    created() {
        setTimeout(() => {
            this.loaded = true;
        }, 200);
    },
    methods: {
        showKlaroUI() {
            window.klaro.show();
        }
    }
}
</script>

<style scoped>
.content-container {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

#team-wrapper {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}

@media screen and (max-width: 85rem) {
    #team-wrapper {
        width: 85%;
    }
}

.card {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 2rem;
    width: 19rem;
    height: auto;
    border: .1rem solid blue;
}

@media screen and (max-width: 102rem) {
    .card {
        width: 15rem;
    }
}

.team-img-wrapper {
    width: 19rem;
    height: 19rem;
    overflow: hidden;
    border-bottom: .1rem solid blue;
    position: relative;
}

.team-img {
    width: 100%;
    height: 19rem;
    object-fit: cover;
    object-position: center center;
    background-origin: border-box;
    transition: transform .4s ease-in-out, width .4s ease-in-out, height .4s ease-in-out, filter .6s ease-in-out;
}

@media screen and (max-width: 102rem) {
    .team-img-wrapper {
        width: auto;
        height: auto;
        border-bottom: none;
    }
}

.team-img:hover {
/*    width: 110%;
    height: 110%;*/
    filter: grayscale(.7);
/*    transform: rotate(3deg) translateX(-4%) translateY(-6%);*/
}

.card-text-wrapper {
    padding-top: .3rem;
    padding-bottom: .7rem;
    padding-left: 1rem;
    padding-right: 1rem;
    box-sizing: border-box;
}

.name {
    font-size: 1.6rem;
    font-weight: 200;
    text-transform: uppercase;
}

@media screen and (max-width: 102rem) {
    .name {
        font-size: 1.3rem;
    }
}

.job {
    font-size: 1.3rem;
    font-weight: 800;
}

.imprint-box {
    margin-bottom: 3.5rem;
    width: 50%;
}

@media screen and (max-width: 80rem) {
    .imprint-box {
        width: 90%;
    }
}

.imprint-headline {
    margin-top: 5rem;
    margin-bottom: 4rem;
    text-transform: uppercase;
    font-size: 3rem;
    font-weight: 800;
}

.second-headline {
    margin-top: 1rem;
}

.imprint-sub-headline {
    margin-bottom: 2rem;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 1.3rem;
}

.imprint-bg {
    opacity: .3;
}

.imprint-consent-notice {
    width: 50%;
    margin-bottom: 2rem;
    font-size: 1.2rem;
}

.imprint-consent-notice a {
    text-decoration: underline;
}

.imprint-consent-notice > a {
    cursor: pointer;
}

#about-link {
    text-decoration: underline;
}

#about-footer {
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 40rem) {
    #about-footer {
        flex-direction: column;
    }
}

@media screen and (max-width: 40rem) {
    #about-footer > img:nth-child(1) {
        margin-bottom: 2rem;
    }
}

.footer-img {
    margin-left: 2rem;
    margin-right: 2rem;
    width: 12.5rem;
    height: auto;
    object-fit: cover;
    object-position: center center;
    background-origin: border-box;
}

@media screen and (max-width: 55rem) {
    .footer-img {
        width: 9rem;
    }
}
</style>

<i18n>
{
    "de": {
        "part1_header": "DESIGN BEEINFLUSST GESELLSCHAFT",
        "part2_header": "AI BEEINFLUSST DESIGN",
        "part3_header": "WIE IST DIE ZUKUNFT VON DESIGN ?",
        "part1": "Komplexe Algorithmen übernehmen unter Einbeziehung großer Datensammlungen zunehmend Aufgaben, die noch vor Kurzem kaum denkbar gewesen wären. Wir durchlaufen eine weitere digitale Transformation: Big Data, Machine Learning und andere Methoden künstlicher Intelligenz werden Bestandteil vieler Produkte und Dienstleistungen. AI wird zunehmend auch ein Part von Design-Tools, welche seit jeher die Eigenschaften der Produkte und Services beeinflussen, die durch sie geschaffen werden. Daher ist die Fragestellung wie Design von AI beeinflusst wird und welchen Einfluss ein von AI beeinflusstes Design auf die Gesellschaft hat, äußerst relevant. Nicht nur für Designer und die Kreativ-Industrie, sondern die gesamte Gesellschaft.",
        "part2": "DesignerInnen und Design-AnwenderInnen verstehen häufig nicht, was künstliche Intelligenz überhaupt ist und für sie und ihre Arbeitsmethodik bedeuten kann. Die Angst von einer künstlichen Intelligenz bald ersetzt zu werden ist ähnlich weit verbreitet wie die trotzige Behauptung, dass Menschen, insbesondere in Kreativ- Umfeldern, immer unersetzlich bleiben werden. Demgegenüber ist es faszinierend, wie gut Mustererkennung und Machine Learning funktioniert und in vielen Bereichen kreativen Arbeitens vollkommen neue Möglichkeiten eröffnet werden.",
        "part3": "Angetrieben durch immer leistungsstärkere Computer und komplexere Algorithmen baut sich derzeit ein neuer Innovationsschub auf, bei dem dieses mal die gesamte Prozesskette des Designs (Inspiration,Produktion, Distribution) erfasst werden wird. Wie wird sich das Verhältnis von Design und AI voraussichtlich in den kommenden 10 Jahren entwickeln? Welche Haltungen sind möglich? Welche Haltungen – aus heutiger Sicht – wünschenswert und sinnvoll? Wie sieht die Zukunft von Design aus? Wie sieht das Design der Zukunft aus?",
        "conclusion": "DARUM GEHT ES BEI aiXdesign.",
        "contact": "Kontakt:",
        "haw": "Hochschule für Angewandte Wissenschaften Hamburg",
        "haw_short": "HAW Hamburg",
        "lead": "Leitung",
        "design": "Design",
        "tech": "Technik",
        "team": "Das Team",
        "about-us": "Über uns"
    },
    "en": {
        "part1_header": "DESIGN INFLUENCES SOCIETY",
        "part2_header": "AI INFLUENCES DESIGN",
        "part3_header": "WHAT IS THE FUTURE OF DESIGN ?",
        "part1": "Complex algorithms, incorporating big data collections, are increasingly taking on tasks that would have been almost inconceivable just a short time ago. We are undergoing another digital transformation: Big Data, Machine Learning and other artificial intelligence methods are becoming part of many products and services. AI is also increasingly becoming a part of design tools, which have always influenced the characteristics of the products and services they help to create. Therefore, the question of how design is influenced by AI and what impact AI-influenced design has on society is extremely relevant. Not only for designers and the creative industry, but the entire society.",
        "part2": "Designers and design users often do not understand what artificial intelligence even is and can mean for them and their work methodology. The fear of soon being replaced by artificial intelligence is as widespread as the defiant assertion that humans, especially in creative environments, will always remain irreplaceable. In contrast, it is fascinating how well pattern recognition and machine learning work and open up completely new possibilities in many areas of creative work.",
        "part3": "Driven by increasingly powerful computers and more complex algorithms, a new wave of innovation is currently building up, in which this time the entire process chain of design (inspiration,production, distribution) will be captured. How is the relationship between design and AI likely to evolve over the next 10 years? Which attitudes are possible? Which attitudes - from today's perspective - are desirable and reasonable? What does the future of design look like? What does the design of the future look like?",
        "conclusion": "THAT IS WHAT aiXdesign IS ABOUT!",
        "haw": "University of Applied Sciences Hamburg",
        "haw_short": "HAW Hamburg",
        "lead": "Lead",
        "design": "Design",
        "tech": "Tech",
        "team": "The team",
        "about-us": "About us"
    }
}
</i18n>
