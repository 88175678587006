<template>
    <div id="recipe-page-root" v-if="recipe">

        <div id="recipe-headline-container">
            <div>{{$t("header_top")}}</div>
            <div>Creative Kitchen</div>
            <div id="by-aixd">
                <div>{{$t("by")}} <router-link to="/about">aiXDesign</router-link></div>
            </div>
        </div>

        <div id="recipe-header-grid">
            <div id="recipe-header">
                <div>{{ $store.state.lang === 'de' ? recipe.title : (recipe.titleEn || recipe.title) }}</div>

                <div class="difficulty-container show-dots">
                    <div class="diff">
                        <svg class="diff-dot dot-1" width="2.785mm" height="3.2mm" viewBox="0 0 2.785 3.2" xmlns="http://www.w3.org/2000/svg">
                            <g transform="translate(-71.047 -51.817)">
                                <path transform="matrix(.28474 -.046628 .046628 .28474 48.184 40.638)" d="m79.961 60.694-5.0249 1.8998-4.1577-3.4018 0.86716-5.3016 5.0249-1.8998 4.1577 3.4018z" fill="#0000ffff" stroke="#00f" stroke-linejoin="round" stroke-width=".7"></path>
                            </g>
                        </svg>
                        <svg class="diff-dot dot-2" width="2.785mm" height="3.2mm" viewBox="0 0 2.785 3.2" xmlns="http://www.w3.org/2000/svg">
                            <g transform="translate(-71.047 -51.817)">
                                <path transform="matrix(.28474 -.046628 .046628 .28474 48.184 40.638)" d="m79.961 60.694-5.0249 1.8998-4.1577-3.4018 0.86716-5.3016 5.0249-1.8998 4.1577 3.4018z" :fill="recipe.codeLevel < 1 ? '#fff' : '#0000ffff'" stroke="#00f" stroke-linejoin="round" stroke-width=".7"></path>
                            </g>
                        </svg>
                        <svg class="diff-dot dot-3" width="2.785mm" height="3.2mm" viewBox="0 0 2.785 3.2" xmlns="http://www.w3.org/2000/svg">
                            <g transform="translate(-71.047 -51.817)">
                                <path transform="matrix(.28474 -.046628 .046628 .28474 48.184 40.638)" d="m79.961 60.694-5.0249 1.8998-4.1577-3.4018 0.86716-5.3016 5.0249-1.8998 4.1577 3.4018z" :fill="recipe.codeLevel < 2 ? '#fff' : '#0000ffff'" stroke="#00f" stroke-linejoin="round" stroke-width=".7"></path>
                            </g>
                        </svg>
                    </div>
                    <div class="popup-diff-headline">{{ recipe.codeLevel === 0 ? "No Code" : (recipe.codeLevel === 1 ? "Low Code" : "Advanced Code") }}</div>
                </div>

                <div id="recipe-header-text">
                     {{ $store.state.lang === 'de' ? recipe.desc : (recipe.descEn || recipe.desc) }}
                </div>
            </div>

            <div id="recipe-ingredients">
                <div>{{$t("you_need")}}</div>
                <div>
                    <div v-for="ingr in recipe.ingredients" :key="ingr.text" v-html="$store.state.lang ==='de' ? ingr.text : (ingr.textEn || ingr.text)"></div>
                </div>
            </div>

            <div id="recipe-images">
                <video muted autoplay loop id="recipe-video" v-if="recipe.video">
                    <source :type="recipe.videoMime" :src="recipe.video">
                </video>
                <div id="recipe-images-row">
                    <div class="cooking-bubble" v-for="img in recipe.images" :key="img">
                        <img :src="img">
                        <div></div>
                    </div>
                </div>
            </div>
        </div>


        <div id="recipe-instructions">
            <div>{{$t("instructions")}}</div>
            <div id="instruction-grid">
                <div v-for="(instr, index) in recipe.instructions" :key="index"
                    :class="{'section-instruction': instr.type === 'Section', 'text-instruction': instr.type === 'Text', 'code-instruction': instr.type === 'Code', 'image-instruction': instr.type === 'Image', 'video-instruction': instr.type === 'Video'}">
                    <template v-if="instr.type === 'Text'">
                        <div>{{instr.counter}}.</div>
                        <div v-html="$store.state.lang === 'de' ? instr.content : (instr.contentEn || instr.content)"></div>
                    </template>

                    <template v-if="instr.type === 'Code'">
                        <vue-code-highlight :language="instr.codeLang">
                            <pre>{{$store.state.lang === 'de' ? instr.content : (instr.contentEn || instr.content)}}</pre>
                        </vue-code-highlight>
                    </template>

                    <template v-if="instr.type === 'Image'">
                        <img :src="instr.media">
                    </template>

                    <template v-if="instr.type === 'Video'">
                        <video controls>
                            <source :type="instr.mediaMime" :src="instr.media">
                        </video>
                    </template>

                    <template v-if="instr.type === 'Section'">
                        <div v-html="$store.state.lang === 'de' ? instr.content : (instr.contentEn || instr.content)"></div>
                    </template>
                </div>
            </div>
        </div>

        <div id="recipe-footer">
            <div class="footer-header">{{$t("footer1_1")}}<br>{{$t("footer1_2")}}</div>
            <div class="footer-header">{{$t("footer2_1")}}<br>{{$t("footer2_2")}}</div>

            <div class="footer-button-container">
                <div class="button-wrapper">
                    <div class="button-headline"></div>

                    <a href="https://www.instagram.com/aixdesign.space/" class="whats-cooking-button" target="_blank">
                        <svg id="button-svg" width="5.4413mm" height="6.6757mm" version="1.1" viewBox="0 0 5.4413 6.6757" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
                            <g transform="translate(-56.445 -73.109)" fill="none" stroke="#fff" stroke-width=".3">
                                <path d="m56.628 74.795v3.2908l2.752 1.5398"/>
                                <path id="upper-path" d="m56.548 74.941 2.6101-1.6203 2.6393 1.47"/>
                                <path id="lower-path" d="m59.197 79.622 2.5091-1.3735"/>
                            </g>
                        </svg>
                        <div class="button-text"><img src="@/assets/instagram-logo-white.svg">Instagram</div>
                    </a>
                </div>

                <div class="button-wrapper">
                    <div class="button-headline"></div>

                    <a href="https://twitter.com/aixdesign_space" class="whats-cooking-button" target="_blank">
                        <svg id="button-svg" width="5.4413mm" height="6.6757mm" version="1.1" viewBox="0 0 5.4413 6.6757" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
                            <g transform="translate(-56.445 -73.109)" fill="none" stroke="#fff" stroke-width=".3">
                                <path d="m56.628 74.795v3.2908l2.752 1.5398"/>
                                <path id="upper-path" d="m56.548 74.941 2.6101-1.6203 2.6393 1.47"/>
                                <path id="lower-path" d="m59.197 79.622 2.5091-1.3735"/>
                            </g>
                        </svg>
                        <div class="button-text"><img src="@/assets/twitter-icon-white.svg">Twitter</div>
                    </a>
                </div>
            </div>
            <div class="footer-button-container">
                <div class="button-wrapper">
                    <div class="button-headline"></div>

                    <a href="mailto:mail@aixdesign.space" class="whats-cooking-button" target="_blank">
                        <svg id="button-svg" width="5.4413mm" height="6.6757mm" version="1.1" viewBox="0 0 5.4413 6.6757" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
                            <g transform="translate(-56.445 -73.109)" fill="none" stroke="#fff" stroke-width=".3">
                                <path d="m56.628 74.795v3.2908l2.752 1.5398"/>
                                <path id="upper-path" d="m56.548 74.941 2.6101-1.6203 2.6393 1.47"/>
                                <path id="lower-path" d="m59.197 79.622 2.5091-1.3735"/>
                            </g>
                        </svg>
                        <div class="button-text"><img src="@/assets/mail-icon-white.svg">mail(at)aixdesign.space</div>
                    </a>
                </div>
            </div>
        </div>

        <!-- TODO PUT IN COMPONENT -->
        <div class="x-hexagon-container" @click="navState = !navState">
            <svg class="x-hexagon" id="x-hexagon-0" :class="{open: navState}" xmlns="http://www.w3.org/2000/svg" width="67.866" height="78.368" viewBox="0 0 67.866 78.368">
                <path  data-name="Path 201" d="M179.994,48.334V10.882L147.561-7.844,115.128,10.882V48.334L147.561,67.06Z" transform="translate(-113.628 9.576)" fill="none" stroke="#ffff00" stroke-miterlimit="10" stroke-width="3"/>
            </svg>
            <svg class="x-hexagon" id="x-hexagon-1" :class="{open: navState}" xmlns="http://www.w3.org/2000/svg" width="67.866" height="78.368" viewBox="0 0 67.866 78.368">
                <path  data-name="Path 201" d="M179.994,48.334V10.882L147.561-7.844,115.128,10.882V48.334L147.561,67.06Z" transform="translate(-113.628 9.576)" fill="none" stroke="#ffff00" stroke-miterlimit="10" stroke-width="3"/>
            </svg>
            <svg class="x-hexagon" id="x-hexagon-2" :class="{open: navState}" xmlns="http://www.w3.org/2000/svg" width="67.866" height="78.368" viewBox="0 0 67.866 78.368">
                <path  data-name="Path 201" d="M179.994,48.334V10.882L147.561-7.844,115.128,10.882V48.334L147.561,67.06Z" transform="translate(-113.628 9.576)" fill="none" stroke="#ffff00" stroke-miterlimit="10" stroke-width="3"/>
            </svg>
            <svg id="x-triangle" :class="{open: navState}" xmlns="http://www.w3.org/2000/svg" width="70.326" height="60.643" viewBox="0 0 70.326 60.643">
                <path data-name="Path 334" d="M-24532.578-12339.595l32.578,56.152,32.541-56.152Z" transform="translate(24535.182 12341.095)" fill="none" stroke="#ffff00" stroke-width="3"/>
            </svg>
        </div>

        <div class="cooking-nav" :style="{transform: navState ? 'none' : null}">
            <a id="nav-headline" href="/#creative-kitchen">Creative Kitchen</a>

            <div id="nav-item-wrapper">
                <a class="cooking-nav-item" href="/#contact-section" @click="navState = false">Contact</a>
                <a class="cooking-nav-item" href="/#workshops" @click="navState = false">Workshops</a>
                <a class="cooking-nav-item" href="/#cookbook-head" @click="navState = false">Cookbook</a>
                <a class="cooking-nav-item" href="/#about-us-section" @click="navState = false">About Us</a>
                <a class="cooking-nav-item" href="/#recap-wrapper" @click="navState = false">Recap</a>
            </div>

            <div id="lang-wrapper">

                <div class="language-hexagon" @click="$store.commit('setLang', 'de')" :class="{active: $store.state.lang === 'de'}">
                    <div class="language">DE</div>
                    <svg width="15.08mm" height="17.285mm" viewBox="0 0 15.08 17.285">
                        <g transform="translate(-24.232 -4.0164)">
                            <path class="hex-path" transform="rotate(.6 31.772 12.659)" d="m38.955 16.701-7.0923 4.1993-7.1829-4.0425-0.09057-8.2418 7.0923-4.1993 7.1829 4.0425z" fill="#00f" opacity="1" stroke="#00f" stroke-linejoin="round" stroke-width=".8"/>
                        </g>
                    </svg>
                </div>

                <div class="language-hexagon" @click="$store.commit('setLang', 'en')" :class="{active: $store.state.lang === 'en'}">
                    <div class="language">EN</div>
                    <svg width="15.08mm" height="17.285mm" viewBox="0 0 15.08 17.285">
                        <g transform="translate(-24.232 -4.0164)">
                            <path class="hex-path" transform="rotate(.6 31.772 12.659)" d="m38.955 16.701-7.0923 4.1993-7.1829-4.0425-0.09057-8.2418 7.0923-4.1993 7.1829 4.0425z" fill="#00f" opacity="1" stroke="#00f" stroke-linejoin="round" stroke-width=".8"/>
                        </g>
                    </svg>
                </div>

            </div>

        </div>
        <div class="cooking-nav-overlay" :class="{active: navState}" @click="navState = false"></div>
    </div>

</template>

<script>
import Vue from "vue";
import { component as VueCodeHighlight } from 'vue-code-highlight';

export default Vue.extend({
    name: "Recipe",
    components: {
        VueCodeHighlight,
    },
    computed: {
        recipe() {
            return this.$store.state.recipes.find(re => re.slug === this.$route.params.id);
        }
    },
    mounted() {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "instant"
            });
        }, 50);
    },
    data() {
        return {
            navState: false,
        }
    },
});
</script>

<style lang="scss">

#recipe-page-root {
    background-color: white;
    font-family: 'MuseoModerno', cursive;
    min-height: 100vh;
    background-image: url("../assets/recipe-bg-hex.svg");
    background-size: 45rem;
    background-position: 44.5%;
}

#recipe-headline-container {
    color: #00f;
    font-size: 2rem;
    padding: 3rem 9.5rem 0;

    & > div:first-child {
        margin-left: 0.7rem;
    }

    & > div:nth-child(2) {
        font-size: 4rem;
        line-height: 120%;
        margin-left: -2rem;
        margin-top: -1.5rem;
        margin-bottom: -1rem;

        @media screen and (max-width: 800px) {
            margin-left: 0.5rem;
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    @media screen and (max-width: 800px) {
        padding: 3rem 7.5rem 0;
    }
}

#recipe-header {
    padding-left: 7.5rem;
    margin-top: 4rem;
    color: blue;
    grid-column: 1;
    grid-row: 1;

    & > div:first-child {
        font-size: 6rem;
        line-height: 120%;
        margin-bottom: 2rem;
    }

    @media screen and (max-width: 1500px) {
        padding-right: 7.5rem;
    }

    @media screen and (max-width: 800px) {
        padding-left: 2.5rem;
        margin-top: 0;
    }
}

#recipe-header-text {
    margin-left: 2rem;
    margin-top: 1rem;
    font-size: 1.1rem;
    line-height: 150%;
    font-family: 'Syne Mono', cursive;
}

.difficulty-container {
    display: flex;

    &.show-dots {
        margin-left: 2rem;
    }

    &.show-dots .diff-dot {
        opacity: 1;

        &:last-child {
            margin-right: 1rem;
        }
    }
}

#recipe-ingredients > div:first-child, #recipe-instructions > div:first-child {
    font-size: 2.5rem;
}

#recipe-ingredients {
    margin-top: 3rem;
    padding-left: 9.5rem;
    color: blue;
    grid-column: 1;
    grid-row: 2;

    & > div:nth-child(2) {
        & > div {
            position: relative;

            &::before {
                position: absolute;
                content: "";
                width: 3rem;
                left: -3rem;
                background-image: url("../assets/blue-hex.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                height: 0.7rem;
                top: calc(50% - 0.35rem);
            }
        }
    }

    @media screen and (max-width: 1500px) {
        padding-right: 7.5rem;
    }

    @media screen and (max-width: 800px) {
        padding-left: 4.5rem;
        padding-right: 3.5rem;
    }
}

#recipe-instructions {
    margin-top: 3rem;
    padding: 0 9.5rem;
    color: blue;

    @media screen and (max-width: 800px) {
        padding-left: 4.5rem;
        padding-right: 3.5rem;
    }
}

#instruction-grid {
    display: grid;
    grid-template-columns: 45% 55%;
    font-family: "Syne Mono", sans-serif;
    font-size: 1.1rem;

    a {
        color: blue;

        &:hover {
            color: #55f;
        }
    }

    @media screen and (max-width: 1300px) {
        grid-template-columns: 100%;
    }
}

.text-instruction {
    position: relative;
    grid-column: 1;
    padding-bottom: 2rem;
    margin-top: 2rem;

    > div:first-child {
        position: absolute;
        top: 0;
        left: -2rem;
    }
}

.section-instruction {
    width: 40rem;
    max-width: 90vw;
    grid-column: 1;
    font-size: 2rem;
}

.code-instruction {
    grid-column: 2;
    margin: -2rem 0;
    padding: 0 3rem;
    z-index: 2;

    & > div {
        border: 1px solid blue;
        padding: 1rem;
        background-color: white;
    }

    pre {
        background-color: white !important;
        margin: 0 !important;
    }

    @media screen and (max-width: 1300px) {
        grid-column: 1;
        margin-top: 0;
        margin-bottom: 2rem;
    }

    @media screen and (max-width: 800px) {
        padding: 0;
    }
}

.image-instruction, .video-instruction {
    grid-column: 1;
    border: 2px solid blue;
    width: 120%;

    img {
        width: 100%;
        height: auto;
    }

    video {
        width: 100%;
        height: auto;
    }

    @media screen and (max-width: 1300px) {
        width: 100%;
    }
}

.image-instruction + .image-instruction, .video-instruction + .video-instruction, .image-instruction + .video-instruction, .video-instruction + .image-instruction {
    margin-top: 2rem;

    @media screen and (max-width: 1300px) {
        margin-top: 0;
    }
}

.code-instruction + .text-instruction + .code-instruction {
    margin-top: 3rem;

    @media screen and (max-width: 1300px) {
        margin-top: 0;
    }
}

#recipe-footer {
    display: grid;
    background-color: blue;
    padding: 4rem 9.5rem;
    grid-template-columns: 1fr 1fr;
    margin-top: 3rem;
    gap: 3rem;

    > .footer-header {
        color: white;
        font-size: 2.5rem;

        @media screen and (max-width: 1100px) {
            font-size: 1.5rem;
        }
    }

    > .footer-button-container {
        display: flex;
        flex-direction: row;
        margin-top: 1rem;

        @media screen and (max-width: 1000px) {
            margin-top: 0;
        }

        & > div + div {
            margin-left: 3rem;
        }
    }

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto auto;

        > *:nth-child(2) {
            grid-row: 3;
        }
    }
    @media screen and (max-width: 700px) {
        padding: 4rem 3rem;
    }

}

#recipe-header-grid {
    display: grid;
    grid-template-columns: 50% 50%;

    @media screen and (max-width: 1500px) {
        grid-template-columns: 100%;

    }
}

#recipe-images {
    display: flex;
    flex-direction: column;
    padding: 5rem 3rem;
    grid-column: 2;
    grid-row: 1 / span 2;

    @media screen and (max-width: 1500px) {
        grid-column: 1;
        grid-row: 3;
        padding: 5rem 10rem;
    }

    @media screen and (max-width: 1000px) {
        padding: 5rem 4.5rem 0 3.5rem;
    }
}

#recipe-video {
    width: 100%;
    height: auto;
    border: 2px solid blue;
}

#recipe-images-row {
    display: flex;
    justify-content: flex-end;
    align-self: center;

    > div {
        margin-right: 2.5rem;

        &:nth-child(2n) {
            margin-top: -2.5rem;
        }

        &:nth-child(3n) {
            margin-top: -1rem;
        }
    }

    @media screen and (max-width: 1000px) {
        display: none;
    }
}

#recipe-video + #recipe-images-row {
    margin-top: -4rem;
}

</style>

<i18n>
{
    "de": {
        "header_top": "Probier' mal",
        "you_need": "Du brauchst:",
        "instructions": "Schritte:",
        "footer1_1": "Erfolg gehabt?",
        "footer1_2": "Teile dein Resultat!",
        "footer2_1": "Auf den Geschmack gekommen?",
        "footer2_2": "Buche jetzt einen Workshop!",
        "by": "von"
    },
    "en": {
        "header_top": "give it a taste",
        "you_need": "You need:",
        "instructions": "Instructions:",
        "footer1_1": "Had fun cooking?",
        "footer1_2": "Share your results!",
        "footer2_1": "Want more?",
        "footer2_2": "Book your workshop!",
        "by": "by"
    }
}
</i18n>
