<template>

<div class="content-container" :style="{opacity: loaded ? 1 : 0}">
    <div class="imprint-headline">Impressum</div>

    <div class="imprint-box">
        <div class="imprint-sub-headline">{{ $t("part1_header") }}</div>
        <div class="imprint-text">
            {{$t("part1_name")}}<br>
            Berliner Tor 5<br>
            20099 Hamburg<br>
            {{$t("part1_country")}}<br>
            {{$t("part1_tel")}} +49.40.428 75- 0<br>
            {{$t("part1_fax")}} +49.40.428 75 91 49<br>
            info[at]haw-hamburg.de
        </div>
    </div>

    <div class="imprint-box">
        <div class="imprint-sub-headline">{{$t("part2_header")}}</div>
        <div class="imprint-text">
            {{ $t("part2_text") }}
        </div>
    </div>

    <div class="imprint-box">
        <div class="imprint-sub-headline">{{$t("part3_header")}}</div>
        <div class="imprint-text">
            {{$t("part3_text")}}
        </div>
    </div>

    <div class="imprint-box">
        <div class="imprint-sub-headline">{{$t("part4_header")}}</div>
        <div class="imprint-text">
            {{$t("part4_text")}}
        </div>
    </div>

    <div class="imprint-box">
        <div class="imprint-sub-headline">{{$t("part5_header")}}</div>
        <div class="imprint-text">
            Prof. Peter Kabel<br>
            {{$t("part5_haw")}}<br>
            {{$t("part5_haw_dept")}}<br>
            Finkenau 35<br>
            22081 Hamburg<br>
            {{$t("part5_country")}}<br>
            {{$t("part5_phone")}} +49.40.428 75 4741<br>
            peter.kabel[at]haw-hamburg.de
        </div>
    </div>


    <div class="imprint-box">
        <div class="imprint-sub-headline">{{$t("part6_header")}}</div>
        <div class="imprint-text">
            {{ $t("part6_text")}}
            <br><br><br>
            <i>{{$t("part6_text2")}}</i>
        </div>
    </div>

    <div class="imprint-headline second-headline">{{ $t("dse_header")}}</div>

    <div class="imprint-box">
        {{$t("dse_text")}}
    </div>

    <div class="imprint-box">
        <div class="imprint-sub-headline">{{$t("dse_part1_header")}}</div>
        <div class="imprint-text">
            Prof. Peter Kabel<br>
            {{$t("part5_haw")}}<br>
            {{$t("part5_haw_dept")}}<br>
            Finkenau 35<br>
            22081 Hamburg<br>
            {{$t("part5_country")}}<br>
            {{$t("part5_phone")}} +49.40.428 75 4741<br>
            peter.kabel[at]haw-hamburg.de
        </div>
    </div>

    <div class="imprint-box">
        <div class="imprint-sub-headline">{{$t("dse_part2_header")}}</div>
        <div class="imprint-text">
            {{$t("dse_part2_text")}}
        </div>
    </div>

    <div class="imprint-box">
        <div class="imprint-sub-headline">{{$t("dse_part3_header")}}</div>
        <div class="imprint-text">
            <b>{{$t("dse_part3_text1")}}</b> <br>
            {{$t("dse_part3_text2")}}
            <br><br>
            {{ $t("dse_part3_text3") }}
            <ul>
                <li>{{ $t("dse_part3_text4") }}</li>
                <li>{{ $t("dse_part3_text5") }}</li>
                <li>{{ $t("dse_part3_text6") }}</li>
                <li>{{ $t("dse_part3_text7") }}</li>
                <li>{{ $t("dse_part3_text8") }}</li>
                <li>{{ $t("dse_part3_text9") }}</li>
                <li>{{ $t("dse_part3_text10") }}</li>
            </ul>

            {{ $t("dse_part3_text11") }}
            <ul>
                <li>{{ $t("dse_part3_text12") }}</li>
                <li>{{ $t("dse_part3_text13") }}</li>
            </ul>

            <br>{{ $t("dse_part3_text14") }}<br><br>

            <b>{{ $t("dse_part3_text15") }}</b><br>

            {{ $t("dse_part3_text16") }}
            <br><br>
            <b>{{ $t("dse_part3_text17") }}</b><br>
            {{ $t("dse_part3_text18") }}
            <br><br>
            {{ $t("dse_part3_text19") }}<br><br>
            {{ $t("dse_part3_text20") }}
            <br><br>
            <b>{{ $t("dse_part3_text21") }}</b><br>
            {{ $t("dse_part3_text22") }}
            <br><br>
            <b>{{ $t("dse_part3_text23") }}</b><br>
            {{ $t("dse_part3_text24") }}
            <br><br>
            {{ $t("dse_part3_text25") }}
            <br><br>
            {{ $t("dse_part3_text26") }}
            <br><br>
            {{ $t("dse_part3_text27") }}
            <br><a @click="showKlaroUI">{{ $t("dse_part3_text28") }}</a><br><br>
            <b>{{ $t("dse_part3_text29") }}</b><br>
            {{ $t("dse_part3_text30") }}<br>
            {{ $t("dse_part3_text31") }}<br><br>
        </div>
    </div>

    <div class="imprint-box" style="padding-bottom: 5rem">
        <div class="imprint-sub-headline">{{$t("dse_part4_header")}}</div>
        <div class="imprint-text">
            <ul>
                <li>{{$t("dse_part4_text1")}}</li>
                <li>{{$t("dse_part4_text2")}}</li>
                <li>{{$t("dse_part4_text3")}}</li>
                <li>{{$t("dse_part4_text4")}}</li>
                <li>{{$t("dse_part4_text5")}}</li>
                <li>{{$t("dse_part4_text6")}}</li>
            </ul>
            <div>
                {{$t("dse_part4_text7")}}
            </div>
        </div>
    </div>

    <img id="bg-img" class="imprint-bg" src="../assets/UXD.svg">

</div>

</template>

<script>
export default {
    name: "Imprint",
    data() {
        return {
            loaded: false,
        };
    },
    created() {
        setTimeout(() => {
            this.loaded = true;
        }, 200);
    },
    methods: {
        showKlaroUI() {
            window.klaro.show();
        }
    }
}
</script>

<style scoped>
.content-container {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.imprint-box {
    margin-bottom: 3.5rem;
    width: 50%;
}

.imprint-headline {
    margin-top: 5rem;
    margin-bottom: 4rem;
    text-transform: uppercase;
    font-size: 3rem;
    font-weight: 800;
}

.second-headline {
    margin-top: 3rem;
}

.imprint-sub-headline {
    margin-bottom: 2rem;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 1.3rem;
}

.imprint-bg {
    opacity: .3;
}

.imprint-consent-notice {
    width: 50%;
    margin-bottom: 2rem;
    font-size: 1.2rem;
}

.imprint-consent-notice a {
    text-decoration: underline;
}

.imprint-consent-notice > a {
    cursor: pointer;
}
</style>

<i18n>
{
    "de": {
        "imprint": "Impressum",
        "part1_header": "Rechtlich verantwortlich",
        "part1_name": "Hochschule für Angewandte Wissenschaften Hamburg (HAW Hamburg)",
        "part1_tel": "Tel.",
        "part1_fax": "Fax",
        "part1_country": "Deutschland",
        "part2_header": "Rechtsform und gesetzliche Vertretung",
        "part2_text": "Die HAW Hamburg ist eine Körperschaft des Öffentlichen Rechts. Sie wird gesetzlich vertreten durch Prof. Dr. Micha Teuscher, Präsident der HAW Hamburg, Berliner Tor 5, D-20099 Hamburg.",
        "part3_header": "Zuständige Aufsichtsbehörde",
        "part3_text": "Behörde für Wissenschaft und Forschung der Freien und Hansestadt Hamburg, Hamburger Straße 37, D-22083 Hamburg",
        "part4_header": "Umsatzsteueridentifikationsnummer",
        "part4_text": "DE 246571598",
        "part5_header": "Inhaltlich und Projektverantwortlich für aiXdesign",
        "part5_haw": "c/o Hochschule für Angewandte Wissenschaften Hamburg (HAW Hamburg)",
        "part5_haw_dept": "Department Design, Medien und Information",
        "part5_country": "Deutschland",
        "part5_phone": "Tel.",
        "part6_header": "Nutzungsrechte/Lizenz",
        "part6_text": "Sofern nicht anders angegeben, stehen die Inhalte dieses Webangebots freu zur Weiternutzung bereit unter der Creative Commons Zero (CC0).",
        "part6_text2": "aiXdesign ist ein Projekt der Hamburg Open Online University.",
        "dse_header": "Datenschutzerklärung",
        "dse_text": "Diese Datenschutzerklärung informiert über die Verarbeitung deiner personenbezogenen Daten, die im Zuge der Nutzung dieser Website erhoben und verarbeitet werden.",
        "dse_part1_header": "Verantwortlicher für die Datenverarbeitung im Sinne der EU- Datenschutzgrundverordnung (DSGVO) und anderer relevanter Datenschutzbestimmungen:",
        "dse_part2_header": "Matomo",
        "dse_part2_text": "Unsere Website verwendet Matomo, ein Open-Source-Programm für Webanalytik, was der Webseitenoptimierung dient. Matomo verwendet sog. „Cookies“, das sind Textdateien, die auf Ihrem Computer gespeichert werden und die unsererseits eine Analyse der Benutzung der Webseite ermöglichen. Zu diesem Zweck werden die durch den Cookie erzeugten Nutzungsinformationen (einschließlich Ihrer gekürzten IP-Adresse) an unseren eigenen Server übertragen und zu Nutzungsanalysezwecken gespeichert. Ihre IP-Adresse wird bei diesem Vorgang umgehend anonymisiert, so dass Sie als Nutzer für uns anonym bleiben. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Webseite werden nicht an Dritte weitergegeben! Wenn Sie mit der Speicherung und Auswertung dieser Daten aus Ihrem Besuch nicht einverstanden sind, dann können Sie der Speicherung und Nutzung nachfolgend per Mausklick jederzeit widersprechen. In diesem Fall wird in Ihrem Browser ein sog. Opt-Out-Cookie abgelegt, was zur Folge hat, dass Matomo keinerlei Sitzungsdaten erhebt. Achtung: Wenn Sie all Ihre Cookies im Browser löschen, so hat dies zur Folge, dass auch das Opt-Out-Cookie gelöscht wird und ggf. von Ihnen erneut aktiviert werden muss.",
        "dse_part3_header": "Datenerhebung bei Zugriff auf die Website",
        "dse_part3_text1": "Logfiles",
        "dse_part3_text2": "Bei jedem Zugriff eines Nutzers auf dies Website und bei jedem Abruf einer Datei werden Daten über diesen Vorgang vorübergehend in einer Protokolldatei gespeichert und verarbeitet.",
        "dse_part3_text3": "Im Einzelnen werden über jeden Zugriff folgende (Logfile-)Daten erhoben/gespeichert:",
        "dse_part3_text4": "IP-Adresse",
        "dse_part3_text5": "Datum und Uhrzeit des Zugriffs",
        "dse_part3_text6": "aufgerufene Seite",
        "dse_part3_text7": "übertragene Datei",
        "dse_part3_text8": "Betriebssystem",
        "dse_part3_text9": "der verwendete Browser (Typ und Version)",
        "dse_part3_text10": "Name des Internet-Access-Providers",
        "dse_part3_text11": "Diese Daten werden lediglich für folgende Zwecke genutzt:",
        "dse_part3_text12": "statistische Erhebungen",
        "dse_part3_text13": "Optimierung des Webangebotes",
        "dse_part3_text14": "Logfile-Daten werden aus Sicherheitsgründen für die Dauer von max. 7 Tagen gespeichert und anschließend gelöscht.",
        "dse_part3_text15": "Rechtsgrundlage",
        "dse_part3_text16": "Die Datenverarbeitung im Zuge der Speicherung der Logfiles beruht auf Artikel 6 Abs. 1 Buchst. f DSGVO (berechtigte Interessen).",
        "dse_part3_text17": "Datenverarbeitung im Zusammenhang mit der Beantwortung der Fragen zu AI/Design",
        "dse_part3_text18": "Auf dieser Website besteht die Gelegenheit, (bis zu) 7 Fragen zum Themenkomplex 'Künstliche Intelligenz und Design' zu beantworten. Wenn du eine oder mehrere Antworten eingegeben hast und auf 'Absenden' klickst, erscheint ein Eingabefeld. Darin wirst du gebeten, dein Berufsfeld, Alter und Land deines Wohnsitzes anzugeben. Ebenfalls wird deine E-Mail-Adresse abgefragt.",
        "dse_part3_text19": "Der Zweck dieser Datenverarbeitung besteht darin, zu wissenschaftlichen Zwecken öffentlich einsehbar Einschätzungen aus verschiedenen Berufsfeldern zum Thema 'Künstliche Intelligenz und Design' zusammen zu tragen und diese filterbar (nach Berufsfeld, Alter und Land) zu machen. Besucher:innen der Website können sich die Antworten anderer ansehen oder selber eigene Antworten auf die Fragen eingeben.",
        "dse_part3_text20": "Die Angabe deiner E-Mail-Adresse erfolgt, um dir Zugang zu den von dir gegebenen Antworten zu ermöglichen sowie Kontakt zu dir herstellen zu können.",
        "dse_part3_text21": "Rechtsgrundlage",
        "dse_part3_text22": "Die Datenverarbeitung im Zusammenhang mit deinen Angaben beruht auf Artikel 6 Abs. 1 S. 1 Buchst. a DSGVO (deine Einwilligung). Die Einwilligung erfolgt auf freiwilliger Basis und kann jederzeit mit Wirkung für die Zukunft ganz oder teilweise widerrufen werden. Durch den Widerruf deiner Einwilligung wird die Rechtmäßigkeit der bis dahin erfolgte Verarbeitung nicht berührt.",
        "dse_part3_text23": "Verwendung des Webanalyse-Tools Matomo",
        "dse_part3_text24": "Diese Webseite benutzt Matomo, eine Open-Source-Software zur statistischen Auswertung der Besucherzugriffe. Matomo verwendet sog. Cookies, Textdateien, die auf deinem Computer gespeichert werden und eine Analyse der Benutzung der Website durch dich ermöglichen.",
        "dse_part3_text25": "Die durch die Cookies erzeugten Informationen über deine Nutzung dieses Webangebots werden auf dem Server dieses Anbieters in Deutschland gespeichert. Du kannst das Setzen der Cookies durch eine entsprechende Einstellung in deinem Browser verhindern; wir weisen jedoch darauf hin, dass du in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Webseite vollumfänglich nutzen kannst.",
        "dse_part3_text26": "Du kannst dich hier entscheiden, ob in deinem Browser ein Webanalyse-Cookie abgelegt werden darf, um dem Betreiber der Website die Erfassung und Analyse verschiedener statistischer Daten zu ermöglichen.",
        "dse_part3_text27": "Wenn du sich dagegen entscheiden möchtest, klicke den folgenden Link, um den Matomo-Deaktivierungs-Cookie in deinem Browser abzulegen.",
        "dse_part3_text28": "Einstellungen",
        "dse_part3_text29": "Dauer der Speicherung",
        "dse_part3_text30": "Die im Zusammenhang mit der Nutzung unseres Webangebots gespeicherten Daten werden nur für die oben genannten Zwecke und nur so lange wie für den jeweiligen Zweck oder gesetzlich erforderlich gespeichert.",
        "dse_part3_text31": "Von dir übermittelte Angaben werden vorbehaltlich deines Widerrufs dauerhaft zweckgebunden gespeichert.",
        "dse_part4_header": "Nutzer:innen dieser Website haben folgende Rechte:",
        "dse_part4_text1": "Recht auf Auskunft über die bei uns zu ihrer Person gespeicherten Daten (Art. 15 DSGVO)",
        "dse_part4_text2": "Recht auf Berichtigung unrichtiger oder unvollständiger personenbezogener Daten (Art. 16 DSGVO)",
        "dse_part4_text3": "Recht auf Löschung gespeicherter personenbezogener Daten, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist (Art. 17 DSGVO)",
        "dse_part4_text4": "Recht auf Einschränkung der Verarbeitung personenbezogener Daten (Art. 18 DSGVO)",
        "dse_part4_text5": "Recht auf Widerspruch gegen eine Verarbeitung, die unserem berechtigten Interesse, einem öffentlichen Interesse oder einem Profiling dient, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen (Art. 21 DSGVO); erteilte Einwilligungen zur Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten jederzeit mit Wirkung für die Zukunft zu widerrufen (Art. 7 Abs. 3 DSGVO). Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen.",
        "dse_part4_text6": "Recht auf Beschwerde bei einer Aufsichtsbehörde, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt (Art. 77 DSGVO)",
        "dse_part4_text7": "Wenn du weitere Informationen zum Datenschutz wünschst, der Datenverarbeitung widersprechen möchtest, deine Daten berichtigen lassen möchtest oder deine Einwilligung widerrufen möchtest, sende bitte eine E-Mail an: peter.kabel@haw-hamburg.de"
    },
    "en": {
        "imprint": "Imprint",
        "part1_header": "LEGALLY RESPONSIBLE",
        "part1_name": "Hamburg University of Applied Sciences (HAW Hamburg)",
        "part1_country": "Germany",
        "part1_tel": "Phone",
        "part1_fax": "Fax",
        "part2_header": "LEGAL FORM AND LEGAL REPRESENTATION",
        "part2_text": "HAW Hamburg is a public corporation. It is legally represented by Prof. Dr. Micha Teuscher, President of HAW Hamburg, Berliner Tor 5, D-20099 Hamburg.",
        "part3_header": "COMPETENT SUPERVISORY AUTHORITY",
        "part3_text": "Authority for Science and Research of the Free and Hanseatic City of Hamburg Hamburger Straße 37, D-22083 Hamburg",
        "part4_header": "SALES TAX IDENTIFICATION NUMBER",
        "part4_text": "DE 246571598",
        "part5_header": "RESPONSIBLE FOR CONTENT AND PROJECT MANAGEMENT OF AIXDESIGN",
        "part5_haw": "c/o University of Applied Sciences Hamburg (HAW Hamburg)",
        "part5_haw_dept": "Department Design, Media and Information",
        "part5_country": "Germany",
        "part5_phone": "Phone",
        "part6_header": "RIGHTS OF USE/LICENSE",
        "part6_text": "Unless otherwise stated, the content of this website is freely available for further use under Creative Commons Zero (CC0).",
        "part6_text2": "aiXdesign is a project of the Hamburg Open Online University.",
        "dse_header": "PRIVACY POLICY",
        "dse_text": "This privacy policy informs about the processing of your personal data, which is collected and processed in the course of using this website.",
        "dse_part1_header": "DATA CONTROLLER IN THE SENSE OF THE EU DATA PROTECTION REGULATION (DSGVO) AND OTHER RELEVANT DATA PROTECTION REGULATIONS:",
        "dse_part2_header": "MATOMO",
        "dse_part2_text": "Our website uses Matomo, an open source program for web analytics, which is used for website optimization. Matomo uses \"cookies\", which are text files placed on your computer, to help the website analyze how users use the site. For this purpose, the usage information generated by the cookie (including your shortened IP address) is transmitted to our own server and stored for usage analysis purposes. Your IP address is immediately anonymized during this process, so that you as a user remain anonymous to us. The information generated by the cookie about your use of this website will not be passed on to third parties! If you do not agree to the storage and analysis of this data from your visit, then you can object to the storage and use below at any time by mouse click. In this case, a so-called opt-out cookie will be stored in your browser, which means that Matomo will not collect any session data. Please note: If you delete all your cookies in the browser, this will also delete the opt-out cookie and you may have to activate it again.",
        "dse_part3_header": "DATA COLLECTION WHEN ACCESSING THE WEBSITE",
        "dse_part3_text1": "Log files",
        "dse_part3_text2": "Each time a user accesses this website and each time a file is retrieved, data about this process is temporarily stored and processed in a log file.",
        "dse_part3_text3": "In detail, the following (log file) data is collected/stored about each access:",
        "dse_part3_text4": "IP address",
        "dse_part3_text5": "date and time of access",
        "dse_part3_text6": "accessed page",
        "dse_part3_text7": "transferred file",
        "dse_part3_text8": "operating system",
        "dse_part3_text9": "the browser used (type and version)",
        "dse_part3_text10": "name of the internet access provider",
        "dse_part3_text11": "This data is only used for the following purposes:",
        "dse_part3_text12": "statistical surveys",
        "dse_part3_text13": "optimization of the web offer",
        "dse_part3_text14": "Log file data is stored for security reasons for a maximum of 7 days and then deleted.",
        "dse_part3_text15": "Legal basis",
        "dse_part3_text16": "Data processing in the course of log file storage is based on Article 6(1)(f) DSGVO (legitimate interests).",
        "dse_part3_text17": "Data processing in connection with answering questions about AI/Design.",
        "dse_part3_text18": "On this website, there is an opportunity to answer (up to) 7 questions on the complex of topics 'Artificial Intelligence and Design'. Once you have entered one or more answers and clicked on 'Submit', an input box will appear. You will be asked to enter your profession, age and country of residence. You will also be asked for your e-mail address.",
        "dse_part3_text19": "The purpose of this data processing is to collect assessments from various professional fields on the topic of \"Artificial Intelligence and Design\" and to make them filterable (by professional field, age and country) for scientific purposes. Visitors of the website can view the answers of others or enter their own answers to the questions.",
        "dse_part3_text20": "Your e-mail address is used to give you access to the answers you have given and to contact you.",
        "dse_part3_text21": "Legal basis",
        "dse_part3_text22": "The data processing in connection with your information is based on Article 6 para. 1 p. 1 letter a DSGVO (your consent). The consent is given on a voluntary basis and can be revoked in whole or in part at any time with effect for the future. The revocation of your consent does not affect the lawfulness of the processing carried out up to that point.",
        "dse_part3_text23": "Use of the web analysis tool Matomo",
        "dse_part3_text24": "This website uses Matomo, an open source software for statistical analysis of visitor access. Matomo uses so-called cookies, text files that are stored on your computer and allow an analysis of your use of the website.",
        "dse_part3_text25": "The information generated by the cookies about your use of this website is stored on the server of this provider in Germany. You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this website.",
        "dse_part3_text26": "You can decide here whether a web analysis cookie may be stored in your browser to enable the website operator to collect and analyze various statistical data.",
        "dse_part3_text27": "If you wish to opt out, click the following link to place the Matomo deactivation cookie in your browser.",
        "dse_part3_text28": "Settings",
        "dse_part3_text29": "Duration of storage",
        "dse_part3_text30": "The data stored in connection with the use of our web offer will only be stored for the above-mentioned purposes and only as long as necessary for the respective purpose or required by law.",
        "dse_part3_text31": "Information you provide will be stored permanently for a specific purpose, subject to your revocation.",
        "dse_part4_header": "USERS OF THIS WEBSITE HAVE THE FOLLOWING RIGHTS:",
        "dse_part4_text1": "Right to information about the data we have stored about you (Art. 15 DSGVO).",
        "dse_part4_text2": "Right to correct incorrect or incomplete personal data (Art. 16 DSGVO)",
        "dse_part4_text3": "Right to erasure of stored personal data, unless the processing is necessary for the exercise of the right to freedom of expression and information, for compliance with a legal obligation, for reasons of public interest or for the establishment, exercise or defense of legal claims (Art. 17 DSGVO)",
        "dse_part4_text4": "Right to restrict the processing of personal data (Art. 18 DSGVO)",
        "dse_part4_text5": "Right to object to processing which serves our legitimate interest, a public interest or profiling, unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms, or the processing serves to assert, exercise or defend legal claims (Art. 21 DSGVO); to withdraw consent given for the collection, processing and use of your personal data at any time with effect for the future (Art. 7(3) DSGVO). This has the consequence that we may no longer continue the data processing based on this consent for the future.",
        "dse_part4_text6": "Right to lodge a complaint with a supervisory authority if you consider that the processing of personal data concerning you infringes the GDPR (Art. 77 GDPR).",
        "dse_part4_text7": "If you would like more information about data protection, to object to data processing, to have your data corrected or to withdraw your consent, please send an email to: peter.kabel[at]haw-hamburg.de"
    }
}
</i18n>
