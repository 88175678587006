<template>
    <div class="floating-dialog">
        <div style="position: relative">
            <div id="floating-dialog-imgs">
                <!--<img @click="minimize" src="../assets/minimize-icon.svg">-->
                <img @click="close" src="../assets/close-icon.svg">
            </div>

            <form @submit.prevent="register">
                <div class="content-sub-headline input-field-headline">{{ $t("header") }}</div>
                <div class="main-text">{{ $t("mainText") }}</div>

                <input required class="input-field input-field-popup" v-model="email" id="email" type="email" :placeholder="$t('email')">
                <input required class="input-field input-field-popup" v-model="firstName" id="firstName" type="text" :placeholder="$t('firstName')">
                <input required class="input-field input-field-popup" v-model="lastName" id="lastName" type="text" :placeholder="$t('lastName')">

                <input class="submit-button submit-button-popup" type="submit" :disabled="isDisabled" :value="$t('submitText')">
            </form>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from "vue";
import API from "@/service/API.ts";

export default Vue.extend({
    name: "FloatingNewsletterDialog",
    components: {},
    data() {
        return {
            email: "",
            firstName: "",
            lastName: ""
        }
    },
    computed: {
        isDisabled() {
            return this.email.trim() === "" || this.firstName.trim() === "" || this.lastName.trim() === "";
        }
    },
    methods: {
        close() {
            this.$store.commit("setFloatingNewsletterState", "minimized");
        },
        minimize() {
            this.$store.commit("setFloatingNewsletterState", "minimized");
        },
        async register() {
            try {
                const resp = await fetch(API.getBaseUrl() + "/subscribe", {
                    method: "POST",
                    body: "email=" + encodeURIComponent(this.email)
                        + "&first_name=" + encodeURIComponent(this.firstName)
                        + "&last_name=" + encodeURIComponent(this.lastName)
                        + "&lang=" + encodeURIComponent(this.$store.state.lang),
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Cache-Control': 'no-store'
                    }
                });

                const respJson = await resp.json();

                if (resp.status !== 200) {
                    console.log("newsletter failed: " + respJson.msg);
                }
            } catch (err) {
                console.log(err);
            }

            this.close();
            this.$emit("subscribed");
        }
    }
});

</script>

<style scoped>

.input-field-headline {
    margin-top: 0.5rem;
}

.floating-dialog {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 100;
    color: white;
    border: .1rem #539eff solid;
    box-shadow: #539eff 0 0 0.7rem;
    background: rgba(0, 0, 0, 0.9);
    padding: 1rem;
    box-sizing: border-box;
    margin: 2rem;
    max-width: 27rem;
}

#floating-dialog-imgs {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
}

#floating-dialog-imgs img {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

#floating-dialog-imgs img:nth-child(2) {
    padding: 0.2rem;
}

.main-text {
    margin-bottom: 2rem;
}

</style>

<i18n>
{
    "en": {
        "header": "Newsletter registration",
        "mainText": "Here you can register for our newsletter",
        "submitText": "Subscribe",
        "firstName": "First name",
        "lastName": "Last name",
        "email": "Email Adress"
    },
    "de": {
        "header": "Newsletter-Anmeldung",
        "mainText": "Hier kannst du dich zu unserem Newsletter anmelden",
        "submitText": "Anmelden",
        "firstName": "Vorname",
        "lastName": "Nachname",
        "email": "E-Mail-Adresse"
    }
}
</i18n>
