<template>
    <Dialog ref="dialog" maxWidth="70rem" contentWidth="100%">
        <div id="dialog-flow">
            <img :src="$props.img" alt="bg-image">
            <div id="event-headline">{{$props.headline}}</div>
            <div id="event-text">
                <div>{{$props.textLeft}}</div>
                <div>{{$props.textRight}}</div>
            </div>
        </div>
    </Dialog>
</template>

<script lang="ts">
import Vue from "vue";
import Dialog from "@/components/Dialog.vue";

export default Vue.extend({
    name: "EventDetailDialog",
    components: {Dialog},
    props: {
        img: String,
        headline: String,
        textLeft: String,
        textRight: String,
    },
    methods: {
        open() {
            this.$refs.dialog.open();
        },
        close() {
            this.$refs.dialog.close();
        }
    }
});

</script>

<style lang="scss" scoped>
#dialog-flow {
    img {
        height: 30rem;
        width: auto;
        max-width: 100%;
        object-fit: cover;
    }

    #event-headline {
        font-weight: bold;
        padding: 1rem;
        margin-top: 2rem;
    }

    #event-text {
        display: flex;
        flex-direction: row;

        div {
            padding: 1rem;
            flex: 1;
        }
    }
}
</style>
