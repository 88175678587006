export const COUNTRIES = [
  {
    "id": 276,
    "name": "Deutschland",
    "alpha2": "de",
    "alpha3": "deu",
    "name_en": "Germany"
  },
  {
    "id": 840,
    "name": "Vereinigte Staaten",
    "alpha2": "us",
    "alpha3": "usa",
    "name_en": "United States of America"
  },
  {
    "id": 826,
    "name": "Vereinigtes Königreich",
    "alpha2": "gb",
    "alpha3": "gbr",
    "name_en": "United Kingdom of Great Britain and Northern Ireland"
  },
  {
    "id": 36,
    "name": "Australien",
    "alpha2": "au",
    "alpha3": "aus",
    "name_en": "Australia"
  },
  {
    "id": 392,
    "name": "Japan",
    "alpha2": "jp",
    "alpha3": "jpn",
    "name_en": "Japan"
  },
  {
    "id": 752,
    "name": "Schweden",
    "alpha2": "se",
    "alpha3": "swe",
    "name_en": "Sweden"
  },
  {
    "id": 156,
    "name": "Volksrepublik China",
    "alpha2": "cn",
    "alpha3": "chn",
    "name_en": "China"
  },
  {
    "id": 643,
    "name": "Russland",
    "alpha2": "ru",
    "alpha3": "rus",
    "name_en": "Russian Federation"
  },
  {
    "id": 250,
    "name": "Frankreich",
    "alpha2": "fr",
    "alpha3": "fra",
    "name_en": "France"
  },
  {
    "id": 724,
    "name": "Spanien",
    "alpha2": "es",
    "alpha3": "esp",
    "name_en": "Spain"
  },
  {
    "id": 4,
    "name": "Afghanistan",
    "alpha2": "af",
    "alpha3": "afg",
    "name_en": "Afghanistan"
  },
  {
    "id": 818,
    "name": "Ägypten",
    "alpha2": "eg",
    "alpha3": "egy",
    "name_en": "Egypt"
  },
  {
    "id": 8,
    "name": "Albanien",
    "alpha2": "al",
    "alpha3": "alb",
    "name_en": "Albania"
  },
  {
    "id": 12,
    "name": "Algerien",
    "alpha2": "dz",
    "alpha3": "dza",
    "name_en": "Algeria"
  },
  {
    "id": 20,
    "name": "Andorra",
    "alpha2": "ad",
    "alpha3": "and",
    "name_en": "Andorra"
  },
  {
    "id": 24,
    "name": "Angola",
    "alpha2": "ao",
    "alpha3": "ago",
    "name_en": "Angola"
  },
  {
    "id": 28,
    "name": "Antigua und Barbuda",
    "alpha2": "ag",
    "alpha3": "atg",
    "name_en": "Antigua and Barbuda"
  },
  {
    "id": 226,
    "name": "Äquatorialguinea",
    "alpha2": "gq",
    "alpha3": "gnq",
    "name_en": "Equatorial Guinea"
  },
  {
    "id": 32,
    "name": "Argentinien",
    "alpha2": "ar",
    "alpha3": "arg",
    "name_en": "Argentina"
  },
  {
    "id": 51,
    "name": "Armenien",
    "alpha2": "am",
    "alpha3": "arm",
    "name_en": "Armenia"
  },
  {
    "id": 31,
    "name": "Aserbaidschan",
    "alpha2": "az",
    "alpha3": "aze",
    "name_en": "Azerbaijan"
  },
  {
    "id": 231,
    "name": "Äthiopien",
    "alpha2": "et",
    "alpha3": "eth",
    "name_en": "Ethiopia"
  },
  {
    "id": 44,
    "name": "Bahamas",
    "alpha2": "bs",
    "alpha3": "bhs",
    "name_en": "Bahamas"
  },
  {
    "id": 48,
    "name": "Bahrain",
    "alpha2": "bh",
    "alpha3": "bhr",
    "name_en": "Bahrain"
  },
  {
    "id": 50,
    "name": "Bangladesch",
    "alpha2": "bd",
    "alpha3": "bgd",
    "name_en": "Bangladesh"
  },
  {
    "id": 52,
    "name": "Barbados",
    "alpha2": "bb",
    "alpha3": "brb",
    "name_en": "Barbados"
  },
  {
    "id": 112,
    "name": "Belarus",
    "alpha2": "by",
    "alpha3": "blr",
    "name_en": "Belarus"
  },
  {
    "id": 56,
    "name": "Belgien",
    "alpha2": "be",
    "alpha3": "bel",
    "name_en": "Belgium"
  },
  {
    "id": 84,
    "name": "Belize",
    "alpha2": "bz",
    "alpha3": "blz",
    "name_en": "Belize"
  },
  {
    "id": 204,
    "name": "Benin",
    "alpha2": "bj",
    "alpha3": "ben",
    "name_en": "Benin"
  },
  {
    "id": 64,
    "name": "Bhutan",
    "alpha2": "bt",
    "alpha3": "btn",
    "name_en": "Bhutan"
  },
  {
    "id": 68,
    "name": "Bolivien",
    "alpha2": "bo",
    "alpha3": "bol",
    "name_en": "Bolivia (Plurinational State of)"
  },
  {
    "id": 70,
    "name": "Bosnien und Herzegowina",
    "alpha2": "ba",
    "alpha3": "bih",
    "name_en": "Bosnia and Herzegovina"
  },
  {
    "id": 72,
    "name": "Botswana",
    "alpha2": "bw",
    "alpha3": "bwa",
    "name_en": "Botswana"
  },
  {
    "id": 76,
    "name": "Brasilien",
    "alpha2": "br",
    "alpha3": "bra",
    "name_en": "Brazil"
  },
  {
    "id": 96,
    "name": "Brunei",
    "alpha2": "bn",
    "alpha3": "brn",
    "name_en": "Brunei Darussalam"
  },
  {
    "id": 100,
    "name": "Bulgarien",
    "alpha2": "bg",
    "alpha3": "bgr",
    "name_en": "Bulgaria"
  },
  {
    "id": 854,
    "name": "Burkina Faso",
    "alpha2": "bf",
    "alpha3": "bfa",
    "name_en": "Burkina Faso"
  },
  {
    "id": 108,
    "name": "Burundi",
    "alpha2": "bi",
    "alpha3": "bdi",
    "name_en": "Burundi"
  },
  {
    "id": 152,
    "name": "Chile",
    "alpha2": "cl",
    "alpha3": "chl",
    "name_en": "Chile"
  },
  {
    "id": 188,
    "name": "Costa Rica",
    "alpha2": "cr",
    "alpha3": "cri",
    "name_en": "Costa Rica"
  },
  {
    "id": 208,
    "name": "Dänemark",
    "alpha2": "dk",
    "alpha3": "dnk",
    "name_en": "Denmark"
  },
  {
    "id": 212,
    "name": "Dominica",
    "alpha2": "dm",
    "alpha3": "dma",
    "name_en": "Dominica"
  },
  {
    "id": 214,
    "name": "Dominikanische Republik",
    "alpha2": "do",
    "alpha3": "dom",
    "name_en": "Dominican Republic"
  },
  {
    "id": 262,
    "name": "Dschibuti",
    "alpha2": "dj",
    "alpha3": "dji",
    "name_en": "Djibouti"
  },
  {
    "id": 218,
    "name": "Ecuador",
    "alpha2": "ec",
    "alpha3": "ecu",
    "name_en": "Ecuador"
  },
  {
    "id": 384,
    "name": "Elfenbeinküste",
    "alpha2": "ci",
    "alpha3": "civ",
    "name_en": "Côte d'Ivoire"
  },
  {
    "id": 222,
    "name": "El Salvador",
    "alpha2": "sv",
    "alpha3": "slv",
    "name_en": "El Salvador"
  },
  {
    "id": 232,
    "name": "Eritrea",
    "alpha2": "er",
    "alpha3": "eri",
    "name_en": "Eritrea"
  },
  {
    "id": 233,
    "name": "Estland",
    "alpha2": "ee",
    "alpha3": "est",
    "name_en": "Estonia"
  },
  {
    "id": 748,
    "name": "Eswatini",
    "alpha2": "sz",
    "alpha3": "swz",
    "name_en": "Eswatini"
  },
  {
    "id": 242,
    "name": "Fidschi",
    "alpha2": "fj",
    "alpha3": "fji",
    "name_en": "Fiji"
  },
  {
    "id": 246,
    "name": "Finnland",
    "alpha2": "fi",
    "alpha3": "fin",
    "name_en": "Finland"
  },
  {
    "id": 266,
    "name": "Gabun",
    "alpha2": "ga",
    "alpha3": "gab",
    "name_en": "Gabon"
  },
  {
    "id": 270,
    "name": "Gambia",
    "alpha2": "gm",
    "alpha3": "gmb",
    "name_en": "Gambia"
  },
  {
    "id": 268,
    "name": "Georgien",
    "alpha2": "ge",
    "alpha3": "geo",
    "name_en": "Georgia"
  },
  {
    "id": 288,
    "name": "Ghana",
    "alpha2": "gh",
    "alpha3": "gha",
    "name_en": "Ghana"
  },
  {
    "id": 308,
    "name": "Grenada",
    "alpha2": "gd",
    "alpha3": "grd",
    "name_en": "Grenada"
  },
  {
    "id": 300,
    "name": "Griechenland",
    "alpha2": "gr",
    "alpha3": "grc",
    "name_en": "Greece"
  },
  {
    "id": 320,
    "name": "Guatemala",
    "alpha2": "gt",
    "alpha3": "gtm",
    "name_en": "Guatemala"
  },
  {
    "id": 324,
    "name": "Guinea",
    "alpha2": "gn",
    "alpha3": "gin",
    "name_en": "Guinea"
  },
  {
    "id": 624,
    "name": "Guinea-Bissau",
    "alpha2": "gw",
    "alpha3": "gnb",
    "name_en": "Guinea-Bissau"
  },
  {
    "id": 328,
    "name": "Guyana",
    "alpha2": "gy",
    "alpha3": "guy",
    "name_en": "Guyana"
  },
  {
    "id": 332,
    "name": "Haiti",
    "alpha2": "ht",
    "alpha3": "hti",
    "name_en": "Haiti"
  },
  {
    "id": 340,
    "name": "Honduras",
    "alpha2": "hn",
    "alpha3": "hnd",
    "name_en": "Honduras"
  },
  {
    "id": 356,
    "name": "Indien",
    "alpha2": "in",
    "alpha3": "ind",
    "name_en": "India"
  },
  {
    "id": 360,
    "name": "Indonesien",
    "alpha2": "id",
    "alpha3": "idn",
    "name_en": "Indonesia"
  },
  {
    "id": 368,
    "name": "Irak",
    "alpha2": "iq",
    "alpha3": "irq",
    "name_en": "Iraq"
  },
  {
    "id": 364,
    "name": "Iran",
    "alpha2": "ir",
    "alpha3": "irn",
    "name_en": "Iran (Islamic Republic of)"
  },
  {
    "id": 372,
    "name": "Irland",
    "alpha2": "ie",
    "alpha3": "irl",
    "name_en": "Ireland"
  },
  {
    "id": 352,
    "name": "Island",
    "alpha2": "is",
    "alpha3": "isl",
    "name_en": "Iceland"
  },
  {
    "id": 376,
    "name": "Israel",
    "alpha2": "il",
    "alpha3": "isr",
    "name_en": "Israel"
  },
  {
    "id": 380,
    "name": "Italien",
    "alpha2": "it",
    "alpha3": "ita",
    "name_en": "Italy"
  },
  {
    "id": 388,
    "name": "Jamaika",
    "alpha2": "jm",
    "alpha3": "jam",
    "name_en": "Jamaica"
  },
  {
    "id": 887,
    "name": "Jemen",
    "alpha2": "ye",
    "alpha3": "yem",
    "name_en": "Yemen"
  },
  {
    "id": 400,
    "name": "Jordanien",
    "alpha2": "jo",
    "alpha3": "jor",
    "name_en": "Jordan"
  },
  {
    "id": 116,
    "name": "Kambodscha",
    "alpha2": "kh",
    "alpha3": "khm",
    "name_en": "Cambodia"
  },
  {
    "id": 120,
    "name": "Kamerun",
    "alpha2": "cm",
    "alpha3": "cmr",
    "name_en": "Cameroon"
  },
  {
    "id": 124,
    "name": "Kanada",
    "alpha2": "ca",
    "alpha3": "can",
    "name_en": "Canada"
  },
  {
    "id": 132,
    "name": "Kap Verde",
    "alpha2": "cv",
    "alpha3": "cpv",
    "name_en": "Cabo Verde"
  },
  {
    "id": 398,
    "name": "Kasachstan",
    "alpha2": "kz",
    "alpha3": "kaz",
    "name_en": "Kazakhstan"
  },
  {
    "id": 634,
    "name": "Katar",
    "alpha2": "qa",
    "alpha3": "qat",
    "name_en": "Qatar"
  },
  {
    "id": 404,
    "name": "Kenia",
    "alpha2": "ke",
    "alpha3": "ken",
    "name_en": "Kenya"
  },
  {
    "id": 417,
    "name": "Kirgisistan",
    "alpha2": "kg",
    "alpha3": "kgz",
    "name_en": "Kyrgyzstan"
  },
  {
    "id": 296,
    "name": "Kiribati",
    "alpha2": "ki",
    "alpha3": "kir",
    "name_en": "Kiribati"
  },
  {
    "id": 170,
    "name": "Kolumbien",
    "alpha2": "co",
    "alpha3": "col",
    "name_en": "Colombia"
  },
  {
    "id": 174,
    "name": "Komoren",
    "alpha2": "km",
    "alpha3": "com",
    "name_en": "Comoros"
  },
  {
    "id": 180,
    "name": "Kongo, Demokratische Republik",
    "alpha2": "cd",
    "alpha3": "cod",
    "name_en": "Congo, Democratic Republic of the"
  },
  {
    "id": 178,
    "name": "Kongo, Republik",
    "alpha2": "cg",
    "alpha3": "cog",
    "name_en": "Congo"
  },
  {
    "id": 408,
    "name": "Korea, Nord",
    "alpha2": "kp",
    "alpha3": "prk",
    "name_en": "Korea (Democratic People's Republic of)"
  },
  {
    "id": 410,
    "name": "Korea, Süd",
    "alpha2": "kr",
    "alpha3": "kor",
    "name_en": "Korea, Republic of"
  },
  {
    "id": 191,
    "name": "Kroatien",
    "alpha2": "hr",
    "alpha3": "hrv",
    "name_en": "Croatia"
  },
  {
    "id": 192,
    "name": "Kuba",
    "alpha2": "cu",
    "alpha3": "cub",
    "name_en": "Cuba"
  },
  {
    "id": 414,
    "name": "Kuwait",
    "alpha2": "kw",
    "alpha3": "kwt",
    "name_en": "Kuwait"
  },
  {
    "id": 418,
    "name": "Laos",
    "alpha2": "la",
    "alpha3": "lao",
    "name_en": "Lao People's Democratic Republic"
  },
  {
    "id": 426,
    "name": "Lesotho",
    "alpha2": "ls",
    "alpha3": "lso",
    "name_en": "Lesotho"
  },
  {
    "id": 428,
    "name": "Lettland",
    "alpha2": "lv",
    "alpha3": "lva",
    "name_en": "Latvia"
  },
  {
    "id": 422,
    "name": "Libanon",
    "alpha2": "lb",
    "alpha3": "lbn",
    "name_en": "Lebanon"
  },
  {
    "id": 430,
    "name": "Liberia",
    "alpha2": "lr",
    "alpha3": "lbr",
    "name_en": "Liberia"
  },
  {
    "id": 434,
    "name": "Libyen",
    "alpha2": "ly",
    "alpha3": "lby",
    "name_en": "Libya"
  },
  {
    "id": 438,
    "name": "Liechtenstein",
    "alpha2": "li",
    "alpha3": "lie",
    "name_en": "Liechtenstein"
  },
  {
    "id": 440,
    "name": "Litauen",
    "alpha2": "lt",
    "alpha3": "ltu",
    "name_en": "Lithuania"
  },
  {
    "id": 442,
    "name": "Luxemburg",
    "alpha2": "lu",
    "alpha3": "lux",
    "name_en": "Luxembourg"
  },
  {
    "id": 450,
    "name": "Madagaskar",
    "alpha2": "mg",
    "alpha3": "mdg",
    "name_en": "Madagascar"
  },
  {
    "id": 454,
    "name": "Malawi",
    "alpha2": "mw",
    "alpha3": "mwi",
    "name_en": "Malawi"
  },
  {
    "id": 458,
    "name": "Malaysia",
    "alpha2": "my",
    "alpha3": "mys",
    "name_en": "Malaysia"
  },
  {
    "id": 462,
    "name": "Malediven",
    "alpha2": "mv",
    "alpha3": "mdv",
    "name_en": "Maldives"
  },
  {
    "id": 466,
    "name": "Mali",
    "alpha2": "ml",
    "alpha3": "mli",
    "name_en": "Mali"
  },
  {
    "id": 470,
    "name": "Malta",
    "alpha2": "mt",
    "alpha3": "mlt",
    "name_en": "Malta"
  },
  {
    "id": 504,
    "name": "Marokko",
    "alpha2": "ma",
    "alpha3": "mar",
    "name_en": "Morocco"
  },
  {
    "id": 584,
    "name": "Marshallinseln",
    "alpha2": "mh",
    "alpha3": "mhl",
    "name_en": "Marshall Islands"
  },
  {
    "id": 478,
    "name": "Mauretanien",
    "alpha2": "mr",
    "alpha3": "mrt",
    "name_en": "Mauritania"
  },
  {
    "id": 480,
    "name": "Mauritius",
    "alpha2": "mu",
    "alpha3": "mus",
    "name_en": "Mauritius"
  },
  {
    "id": 484,
    "name": "Mexiko",
    "alpha2": "mx",
    "alpha3": "mex",
    "name_en": "Mexico"
  },
  {
    "id": 583,
    "name": "Mikronesien",
    "alpha2": "fm",
    "alpha3": "fsm",
    "name_en": "Micronesia (Federated States of)"
  },
  {
    "id": 498,
    "name": "Moldau",
    "alpha2": "md",
    "alpha3": "mda",
    "name_en": "Moldova, Republic of"
  },
  {
    "id": 492,
    "name": "Monaco",
    "alpha2": "mc",
    "alpha3": "mco",
    "name_en": "Monaco"
  },
  {
    "id": 496,
    "name": "Mongolei",
    "alpha2": "mn",
    "alpha3": "mng",
    "name_en": "Mongolia"
  },
  {
    "id": 499,
    "name": "Montenegro",
    "alpha2": "me",
    "alpha3": "mne",
    "name_en": "Montenegro"
  },
  {
    "id": 508,
    "name": "Mosambik",
    "alpha2": "mz",
    "alpha3": "moz",
    "name_en": "Mozambique"
  },
  {
    "id": 104,
    "name": "Myanmar",
    "alpha2": "mm",
    "alpha3": "mmr",
    "name_en": "Myanmar"
  },
  {
    "id": 516,
    "name": "Namibia",
    "alpha2": "na",
    "alpha3": "nam",
    "name_en": "Namibia"
  },
  {
    "id": 520,
    "name": "Nauru",
    "alpha2": "nr",
    "alpha3": "nru",
    "name_en": "Nauru"
  },
  {
    "id": 524,
    "name": "Nepal",
    "alpha2": "np",
    "alpha3": "npl",
    "name_en": "Nepal"
  },
  {
    "id": 554,
    "name": "Neuseeland",
    "alpha2": "nz",
    "alpha3": "nzl",
    "name_en": "New Zealand"
  },
  {
    "id": 558,
    "name": "Nicaragua",
    "alpha2": "ni",
    "alpha3": "nic",
    "name_en": "Nicaragua"
  },
  {
    "id": 528,
    "name": "Niederlande",
    "alpha2": "nl",
    "alpha3": "nld",
    "name_en": "Netherlands"
  },
  {
    "id": 562,
    "name": "Niger",
    "alpha2": "ne",
    "alpha3": "ner",
    "name_en": "Niger"
  },
  {
    "id": 566,
    "name": "Nigeria",
    "alpha2": "ng",
    "alpha3": "nga",
    "name_en": "Nigeria"
  },
  {
    "id": 807,
    "name": "Nordmazedonien",
    "alpha2": "mk",
    "alpha3": "mkd",
    "name_en": "North Macedonia"
  },
  {
    "id": 578,
    "name": "Norwegen",
    "alpha2": "no",
    "alpha3": "nor",
    "name_en": "Norway"
  },
  {
    "id": 512,
    "name": "Oman",
    "alpha2": "om",
    "alpha3": "omn",
    "name_en": "Oman"
  },
  {
    "id": 40,
    "name": "Österreich",
    "alpha2": "at",
    "alpha3": "aut",
    "name_en": "Austria"
  },
  {
    "id": 626,
    "name": "Osttimor",
    "alpha2": "tl",
    "alpha3": "tls",
    "name_en": "Timor-Leste"
  },
  {
    "id": 586,
    "name": "Pakistan",
    "alpha2": "pk",
    "alpha3": "pak",
    "name_en": "Pakistan"
  },
  {
    "id": 585,
    "name": "Palau",
    "alpha2": "pw",
    "alpha3": "plw",
    "name_en": "Palau"
  },
  {
    "id": 591,
    "name": "Panama",
    "alpha2": "pa",
    "alpha3": "pan",
    "name_en": "Panama"
  },
  {
    "id": 598,
    "name": "Papua-Neuguinea",
    "alpha2": "pg",
    "alpha3": "png",
    "name_en": "Papua New Guinea"
  },
  {
    "id": 600,
    "name": "Paraguay",
    "alpha2": "py",
    "alpha3": "pry",
    "name_en": "Paraguay"
  },
  {
    "id": 604,
    "name": "Peru",
    "alpha2": "pe",
    "alpha3": "per",
    "name_en": "Peru"
  },
  {
    "id": 608,
    "name": "Philippinen",
    "alpha2": "ph",
    "alpha3": "phl",
    "name_en": "Philippines"
  },
  {
    "id": 616,
    "name": "Polen",
    "alpha2": "pl",
    "alpha3": "pol",
    "name_en": "Poland"
  },
  {
    "id": 620,
    "name": "Portugal",
    "alpha2": "pt",
    "alpha3": "prt",
    "name_en": "Portugal"
  },
  {
    "id": 646,
    "name": "Ruanda",
    "alpha2": "rw",
    "alpha3": "rwa",
    "name_en": "Rwanda"
  },
  {
    "id": 642,
    "name": "Rumänien",
    "alpha2": "ro",
    "alpha3": "rou",
    "name_en": "Romania"
  },
  {
    "id": 90,
    "name": "Salomonen",
    "alpha2": "sb",
    "alpha3": "slb",
    "name_en": "Solomon Islands"
  },
  {
    "id": 894,
    "name": "Sambia",
    "alpha2": "zm",
    "alpha3": "zmb",
    "name_en": "Zambia"
  },
  {
    "id": 882,
    "name": "Samoa",
    "alpha2": "ws",
    "alpha3": "wsm",
    "name_en": "Samoa"
  },
  {
    "id": 674,
    "name": "San Marino",
    "alpha2": "sm",
    "alpha3": "smr",
    "name_en": "San Marino"
  },
  {
    "id": 678,
    "name": "São Tomé und Príncipe",
    "alpha2": "st",
    "alpha3": "stp",
    "name_en": "Sao Tome and Principe"
  },
  {
    "id": 682,
    "name": "Saudi-Arabien",
    "alpha2": "sa",
    "alpha3": "sau",
    "name_en": "Saudi Arabia"
  },
  {
    "id": 756,
    "name": "Schweiz",
    "alpha2": "ch",
    "alpha3": "che",
    "name_en": "Switzerland"
  },
  {
    "id": 686,
    "name": "Senegal",
    "alpha2": "sn",
    "alpha3": "sen",
    "name_en": "Senegal"
  },
  {
    "id": 688,
    "name": "Serbien",
    "alpha2": "rs",
    "alpha3": "srb",
    "name_en": "Serbia"
  },
  {
    "id": 690,
    "name": "Seychellen",
    "alpha2": "sc",
    "alpha3": "syc",
    "name_en": "Seychelles"
  },
  {
    "id": 694,
    "name": "Sierra Leone",
    "alpha2": "sl",
    "alpha3": "sle",
    "name_en": "Sierra Leone"
  },
  {
    "id": 716,
    "name": "Simbabwe",
    "alpha2": "zw",
    "alpha3": "zwe",
    "name_en": "Zimbabwe"
  },
  {
    "id": 702,
    "name": "Singapur",
    "alpha2": "sg",
    "alpha3": "sgp",
    "name_en": "Singapore"
  },
  {
    "id": 703,
    "name": "Slowakei",
    "alpha2": "sk",
    "alpha3": "svk",
    "name_en": "Slovakia"
  },
  {
    "id": 705,
    "name": "Slowenien",
    "alpha2": "si",
    "alpha3": "svn",
    "name_en": "Slovenia"
  },
  {
    "id": 706,
    "name": "Somalia",
    "alpha2": "so",
    "alpha3": "som",
    "name_en": "Somalia"
  },
  {
    "id": 144,
    "name": "Sri Lanka",
    "alpha2": "lk",
    "alpha3": "lka",
    "name_en": "Sri Lanka"
  },
  {
    "id": 659,
    "name": "St. Kitts und Nevis",
    "alpha2": "kn",
    "alpha3": "kna",
    "name_en": "Saint Kitts and Nevis"
  },
  {
    "id": 662,
    "name": "St. Lucia",
    "alpha2": "lc",
    "alpha3": "lca",
    "name_en": "Saint Lucia"
  },
  {
    "id": 670,
    "name": "St. Vincent und die Grenadinen",
    "alpha2": "vc",
    "alpha3": "vct",
    "name_en": "Saint Vincent and the Grenadines"
  },
  {
    "id": 710,
    "name": "Südafrika",
    "alpha2": "za",
    "alpha3": "zaf",
    "name_en": "South Africa"
  },
  {
    "id": 729,
    "name": "Sudan",
    "alpha2": "sd",
    "alpha3": "sdn",
    "name_en": "Sudan"
  },
  {
    "id": 728,
    "name": "Südsudan",
    "alpha2": "ss",
    "alpha3": "ssd",
    "name_en": "South Sudan"
  },
  {
    "id": 740,
    "name": "Suriname",
    "alpha2": "sr",
    "alpha3": "sur",
    "name_en": "Suriname"
  },
  {
    "id": 760,
    "name": "Syrien",
    "alpha2": "sy",
    "alpha3": "syr",
    "name_en": "Syrian Arab Republic"
  },
  {
    "id": 762,
    "name": "Tadschikistan",
    "alpha2": "tj",
    "alpha3": "tjk",
    "name_en": "Tajikistan"
  },
  {
    "id": 834,
    "name": "Tansania",
    "alpha2": "tz",
    "alpha3": "tza",
    "name_en": "Tanzania, United Republic of"
  },
  {
    "id": 764,
    "name": "Thailand",
    "alpha2": "th",
    "alpha3": "tha",
    "name_en": "Thailand"
  },
  {
    "id": 768,
    "name": "Togo",
    "alpha2": "tg",
    "alpha3": "tgo",
    "name_en": "Togo"
  },
  {
    "id": 776,
    "name": "Tonga",
    "alpha2": "to",
    "alpha3": "ton",
    "name_en": "Tonga"
  },
  {
    "id": 780,
    "name": "Trinidad und Tobago",
    "alpha2": "tt",
    "alpha3": "tto",
    "name_en": "Trinidad and Tobago"
  },
  {
    "id": 148,
    "name": "Tschad",
    "alpha2": "td",
    "alpha3": "tcd",
    "name_en": "Chad"
  },
  {
    "id": 203,
    "name": "Tschechien",
    "alpha2": "cz",
    "alpha3": "cze",
    "name_en": "Czechia"
  },
  {
    "id": 788,
    "name": "Tunesien",
    "alpha2": "tn",
    "alpha3": "tun",
    "name_en": "Tunisia"
  },
  {
    "id": 792,
    "name": "Türkei",
    "alpha2": "tr",
    "alpha3": "tur",
    "name_en": "Turkey"
  },
  {
    "id": 795,
    "name": "Turkmenistan",
    "alpha2": "tm",
    "alpha3": "tkm",
    "name_en": "Turkmenistan"
  },
  {
    "id": 798,
    "name": "Tuvalu",
    "alpha2": "tv",
    "alpha3": "tuv",
    "name_en": "Tuvalu"
  },
  {
    "id": 800,
    "name": "Uganda",
    "alpha2": "ug",
    "alpha3": "uga",
    "name_en": "Uganda"
  },
  {
    "id": 804,
    "name": "Ukraine",
    "alpha2": "ua",
    "alpha3": "ukr",
    "name_en": "Ukraine"
  },
  {
    "id": 348,
    "name": "Ungarn",
    "alpha2": "hu",
    "alpha3": "hun",
    "name_en": "Hungary"
  },
  {
    "id": 858,
    "name": "Uruguay",
    "alpha2": "uy",
    "alpha3": "ury",
    "name_en": "Uruguay"
  },
  {
    "id": 860,
    "name": "Usbekistan",
    "alpha2": "uz",
    "alpha3": "uzb",
    "name_en": "Uzbekistan"
  },
  {
    "id": 548,
    "name": "Vanuatu",
    "alpha2": "vu",
    "alpha3": "vut",
    "name_en": "Vanuatu"
  },
  {
    "id": 862,
    "name": "Venezuela",
    "alpha2": "ve",
    "alpha3": "ven",
    "name_en": "Venezuela (Bolivarian Republic of)"
  },
  {
    "id": 784,
    "name": "Vereinigte Arabische Emirate",
    "alpha2": "ae",
    "alpha3": "are",
    "name_en": "United Arab Emirates"
  },
  {
    "id": 704,
    "name": "Vietnam",
    "alpha2": "vn",
    "alpha3": "vnm",
    "name_en": "Viet Nam"
  },
  {
    "id": 140,
    "name": "Zentral­afrikanische Republik",
    "alpha2": "cf",
    "alpha3": "caf",
    "name_en": "Central African Republic"
  },
  {
    "id": 196,
    "name": "Zypern",
    "alpha2": "cy",
    "alpha3": "cyp",
    "name_en": "Cyprus"
  }
]
