<template>
    <div id="modal" v-on:click.self="close" :class="{active: isDialogActive, light: lightMode}">
        <div id="modal-content" :style="{'max-width': ($props.maxWidth || '45rem')}">
            <div class="modal-content-wrapper" :style="{width: $props.contentWidth || '81%'}">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import Vue from "vue";

export default Vue.extend({
    name: "Dialog",
    props: {
        maxWidth: String,
        contentWidth: String,
        lightMode: Boolean,
    },
    data() {
        return {
            isDialogActive: false,
        }
    },
    methods: {
        open() {
            this.isDialogActive = true;
        },
        close() {
            this.isDialogActive = false;
        }
    }
});
</script>

<style scoped>


#modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    z-index: 999;
    transition: 0.1s ease-in-out;
    background-color: rgba(0, 0, 0, 0.32);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
}

#modal.light {
    background-color: rgba(0, 0, 0, 0.2);
}

#modal.active {
    opacity: 1;
    pointer-events: all;
}

#modal-content {
    display: inline-flex;
    justify-content: center;
    min-width: 28%;
    max-height: 90%;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, 0.85);
    color: white;
    border: .1rem white solid;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0.7rem;
}

#modal.light #modal-content {
    background-color: white;
    color: blue;
}

@media screen and (max-width: 50rem) {
    #modal-content {
        max-width: 90% !important;
    }
}

#modal-content::-webkit-scrollbar {
  width: 3px;
}

#modal-content::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
}

#modal-content::-webkit-scrollbar-thumb {
    background: #ffffff;
}

.modal-content-wrapper {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}

</style>
