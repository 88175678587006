import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '../i18n';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
      questions: [],
      currentQuestion: 0,
      lang: i18n.locale,
      user: {
          id: localStorage.userId || -1,
          age: localStorage.userAge || "",
          occupation: localStorage.userOccupation || "",
          occupationCategory: localStorage.userOccupationCategory || "",
          country: localStorage.userCountry || "",
          email: localStorage.userEmail || "",
          secret: localStorage.userSecret || "",
      },
      inVerticalCenter: true,
      trackingConsentGiven: false,
      infoImgs: [],
      bubbles: [],
      floatingNewsletterState: localStorage.getItem("floatingNewsletter") || "minimized",
      filter: {
          professionFilter: [],
          ageRange: [0, 100],
          countryFilter: [],
      },
      recipes: [],
      workshops: [],
  },
  mutations: {
      setQuestions(state, q) {
          state.questions = q;
      },
      setCurrentQuestion(state, q) {
          state.currentQuestion = q;
      },
      setLang(state, lang) {
          localStorage.setItem("customLang", lang);
          state.lang = lang;
          i18n.locale = lang;
      },
      updateUser(state, newUser) {
          state.user.age = newUser.age;
          state.user.occupation = newUser.occupation;
          state.user.occupationCategory = newUser.occupationCategory;
          state.user.country = newUser.country;
          state.user.email = newUser.email;
          state.user.id = newUser.id;
          state.user.secret = newUser.secret;

          localStorage.userId = state.user.id;
          localStorage.userEmail = state.user.email;
          localStorage.userAge = state.user.age;
          localStorage.userCountry = state.user.country;
          localStorage.userOccupation = state.user.occupation;
          localStorage.userOccupationCategory = state.user.occupationCategory;
          localStorage.userSecret = state.user.secret;
      },
      setInVerticalCenter(state, center) {
          state.inVerticalCenter = center;
      },
      setRecipes(state, recipes) {
          state.recipes = recipes;
      },
      setInfoImgs(state, imgs) {
          state.infoImgs = imgs;
      },
      setBubbles(state, bubbles) {
          state.bubbles = bubbles;
      },
      setFloatingNewsletterState(state, s) {
          localStorage.setItem("floatingNewsletter", s);
          state.floatingNewsletterState = s;
      },
      updateFilter(state, updateObj) {
          state.filter = updateObj;
      },
      setWorkshops(state, workshops) {
          state.workshops = workshops;
      },
      resetFilter(state) {
          state.filter = {
              professionFilter: [],
              ageRange: [0, 100],
              countryFilter: [],
          };
      }
  },
  actions: {
  },
  modules: {
  }
})
