<template>
    <div class="slide-wrapper">
        <div class="text-wrapper" v-if="!infoImgUrl">
            <div class="slide-guy">{{answer.occupation}}, {{answer.age}}</div>
            <div class="slide-text" @click="scrollActivated = true" :class="{'slide-text-with-scrollbar': scrollActivated}">
                <div v-if="$store.state.lang === 'de'">{{answer.answer_de}}</div>
                <div v-if="$store.state.lang !== 'de'">{{answer.answer_en}}</div>
            </div>
            <div class="bottom-info" v-if="answer.dateText"><div>{{countryName}}, {{answer.dateText}}</div></div>
            <div class="bottom-info" v-if="!answer.dateText"><div>{{countryName}}</div></div>
        </div>

        <router-link to="/events" class="info-img-wrapper" v-if="infoImgUrl">
            <img :src="infoImgUrl">
        </router-link>
    </div>
</template>

<script lang="ts">
import Vue from "vue";
import {COUNTRIES} from "../countries.ts";

export default Vue.extend({
    name: "Slide",
    props: {
        answer: Object,
        infoImgUrl: String,
    },
    data() {
        return {
            scrollActivated: false,
        }
    },
    computed: {
        countryName() {
            const country = COUNTRIES.find(c => c.alpha3 === this.answer.country);

            return country ? country[this.$store.state.lang === "de" ? "name" : "name_en"] : "Unbekannt";
        }
    }
})
</script>

<style scoped>
.slide-wrapper {
    height: 100%;
}

.text-wrapper {
    margin: 1.5rem;
    display: grid;
    grid-template-rows: auto;
    row-gap: .4rem;
    height: 299px;
}

.slide-text {
    grid-row: 2;
    height: 14rem;
    overflow-y: hidden;
    font-size: 1.1rem;
    font-weight: 600;
}

.slide-text div {
    width: 100%;
    height: 100%;
}

.slide-text::-webkit-scrollbar {
  width: 3px;
}

.slide-text::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
}

.slide-text::-webkit-scrollbar-thumb {
    background: #539eff;
}

.slide-text {
    -ms-overflow-style: none;
    scrollbar-width: thin;
    scrollbar-color: #539eff rgba(0, 0, 0, 0);
}

.slide-text-with-scrollbar {
    overflow-y: scroll;
}

.slide-guy {
    grid-row: 1;
    font-size: 1rem;
    word-break: break-word;
    font-weight: 300;
}

.bottom-info {
    grid-row: 3;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: .9rem;
    font-weight: 200;
    color: rgba(255, 255, 255, 0.7);
}

.info-img-wrapper, .info-img-wrapper img {
    height: 100%;
    width: 100%;
}

.info-img-wrapper img {
    object-fit: cover;
    object-position: center top;
}
</style>
