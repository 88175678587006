import Store from "@/store";
/*
export interface Bubble {
    img?: string;
    video?: string;
    linkHeaderDe: string;
    linkHeaderEn: string;
    link: string;
}*/

const CMS_HOST = "https://cms.aixdesign.space";

export class CMS {
    static async loadInfoImgs() {
        const resp = await fetch(CMS_HOST + "/werbecards");
        const data = await resp.json();

        const urls = [];
        let current = 1;
        while (data[`card${current}`]) {
            urls.push(CMS_HOST + data[`card${current}`].formats.small.url);
            current++;
        }

        Store.commit("setInfoImgs", urls);
    }

    static async loadWorkshops() {
        const resp = await fetch(CMS_HOST + "/workshops");
        const data = await resp.json();

        const workshops = [];
        for (const ws of data) {
            let videoUrl = "", videoType = "";

            if (ws.video) {
                videoUrl = CMS_HOST + ws.video.url;
                videoType = ws.video.mime;
            }

            workshops.push({
                id: ws.id,
                name: ws.name,
                mainText: ws.main_text,
                mainTextEn: ws.main_text_en,
                place: ws.place,
                recipeId: ws.recipe_id,
                videoUrl,
                videoType,
                workshopResults: ws.workshop_results.map(re => CMS_HOST + re.url)
            });
        }

        workshops.sort((a, b) => {
            return b.id - a.id;
        });

        Store.commit("setWorkshops", workshops);
    }

    static async loadRecipes() {
        const resp = await fetch(CMS_HOST + "/recipes");
        const data = await resp.json();

        const codeLevels = ["NoCode", "LowCode", "HighCode"];

        const recipes = [];
        for (const ws of data) {
            let counter = 0;

            let videoUrl = null, videoMime = null;

            if (ws.Preview_Video) {
                videoUrl = CMS_HOST + ws.Preview_Video.url;
                videoMime = ws.Preview_Video.mime;
            }

            recipes.push({
                id: ws.id,
                title: ws.Title,
                titleEn: ws.Title_en,
                desc: ws.Description,
                descEn: ws.Description_en,
                codeLevel: codeLevels.indexOf(ws.Code_Level),
                slug: ws.slug,
                video: videoUrl,
                videoMime,
                ingredients: ws.Ingredient.map(ingr => {
                    return {
                        text: ingr.Ingredient,
                        textEn: ingr.Ingredient_en,
                    };
                }),
                images: ws.Images.map(img => {
                    return CMS_HOST + img.url;
                }),
                instructions: ws.Instruction.map(inst => {
                    let mediaUrl = null, mediaMime = null;

                    if (inst.Image) {
                        mediaUrl = CMS_HOST + inst.Image.url;
                        mediaMime = inst.Image.mime;
                    }

                    if (inst.Type === "Text") {
                        counter++;
                    } else if (inst.Type === "Section") {
                        counter = 0;
                    }

                    return {
                        type: inst.Type,
                        content: inst.Content,
                        contentEn: inst.Content_en,
                        codeLang: inst.code_language,
                        media: mediaUrl,
                        mediaMime: mediaMime,
                        counter,
                    };
                })
            });
        }

        Store.commit("setRecipes", recipes);
    }

    static async loadBubbles() {
        const resp = await fetch(CMS_HOST + "/bubbles");
        const data = await resp.json();

        const bubbles = [];
        for (const bubble of data) {
            let obj = {
                linkHeaderDe: bubble.link_header_de,
                linkHeaderEn: bubble.link_header_en,
                link: bubble.link,
                aDuration2: Math.floor(3 + Math.random() * 4) + "s",
                aDuration: "80s",
                img: null,
                video: null,
                videoType: null,
            };

            if (bubble.image.mime.indexOf("image") === 0) {
                obj.img = CMS_HOST + bubble.image.url;
            } else if (bubble.image.mime.indexOf("video") === 0) {
                obj.video = CMS_HOST + bubble.image.url;
                obj.videoType = bubble.image.mime;
            }



            bubbles.push(obj);
        }

        //shuffle array (fisher-yates)
        for (let i = bubbles.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [bubbles[i], bubbles[j]] = [bubbles[j], bubbles[i]];
        }

        Store.commit("setBubbles", bubbles.slice(0, 13));
    }
}
