<template>
  <div class="navContainer">
    <slot>
      <NavLink to="/">Home</NavLink>
    </slot>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import NavLink from "./NavLink.vue";

export default Vue.extend({
  name: "Navigation",
  props: {

  },
  components: {
    NavLink,
  },
  data() {
    return {

    }
  },
  mounted() {
    this.$on("navClick", (data) => {
      console.log(data);
    });
  }
})
</script>

<style scoped>
.navContainer {
  min-height: 0;
}
</style>
