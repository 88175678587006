import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import VueMatomo from 'vue-matomo';
import * as Klaro from 'klaro/dist/klaro-no-css';
import {CMS} from "@/service/CMS.ts";
import 'vue-search-select/dist/VueSearchSelect.css';
import 'vue-slider-component/theme/default.css';
import "prismjs";
import "prismjs/themes/prism.css";
import VueSnip from "vue-snip";

Vue.config.productionTip = true;

Vue.use(VueSnip);

Vue.use(VueMatomo, {
    // Configure your matomo server and site by providing
    host: 'https://matomo.design.haw-hamburg.de',
    siteId: 15,

    // Changes the default .js and .php endpoint's filename
    // Default: 'matomo'
    trackerFileName: 'matomo',

    // Overrides the autogenerated tracker endpoint entirely
    // Default: undefined
    // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

    // Overrides the autogenerated tracker script path entirely
    // Default: undefined
    // trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',

    // Enables automatically registering pageviews on the router
    router: router,

    // Enables link tracking on regular links. Note that this won't
    // work for routing links (ie. internal Vue router links)
    // Default: true
    enableLinkTracking: true,

    // Require consent before sending tracking information to matomo
    // Default: false
    requireConsent: false,

    // Whether to track the initial page view
    // Default: true
    trackInitialView: true,

    // Run Matomo without cookies
    // Default: false
    disableCookies: false,

    // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
    // Default: false
    enableHeartBeatTimer: false,

    // Set the heartbeat timer interval
    // Default: 15
    heartBeatTimerInterval: 15,

    // Whether or not to log debug information
    // Default: false
    debug: false,

    // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
    // Default: undefined
    userId: undefined,

    // Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)
    // Default: undefined, example '*.example.com'
    cookieDomain: undefined,

    // Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
    // Default: undefined, example: '*.example.com'
    domains: undefined,

    // A list of pre-initialization actions that run before matomo is loaded
    // Default: []
    // Example: [
    //   ['API_method_name', parameter_list],
    //   ['setCustomVariable','1','VisitorType','Member'],
    //   ['appendToTrackingUrl', 'new_visit=1'],
    //   etc.
    // ]
    preInitActions: []
});

// we define a minimal configuration
const config = {
    acceptAll: true,
    default: true,
    lang: "de",
    translations: {
        zz: {
            privacyPolicy: "/imprint"
        },
        en: {
            matomo: {
                title: "Matomo",
                description: "Matomo is a simple, self-hosted analytics service.",
            },
            purposes: {
                analytics: "Analytics",
            }
        },
        de: {
            matomo: {
                title: "Matomo",
                description: "Matomo ist ein einfacher, selbstgehosteter Analytics-Service."
            },
            purposes: {
                analytics: "Analytics"
            }
        }
    },
    apps: [
        {
            name: "matomo",
            purposes: ["analytics"],
            callback: function(consent) {
                if (consent) {
                    console.log("Matomo consent given");
                    setTimeout(() => {
                        window.Piwik.getTracker().setConsentGiven();
                    }, 500);
                } else {
                    console.log("No matomo consent given");
                    setTimeout(() => {
                        window.Piwik.getTracker().forgetConsentGiven();
                    }, 500);
                }
            },
        },
    ],
};

// we assign the Klaro module to the window, so that we can access it in JS
window.klaro = Klaro;
window.klaroConfig = config;
// we set up Klaro with the config
Klaro.setup(config);

CMS.loadInfoImgs().catch(err => console.log(err));
CMS.loadBubbles().catch(err => console.log(err));
CMS.loadWorkshops().catch(err => console.log(err));
CMS.loadRecipes().catch(err => console.log(err));

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
