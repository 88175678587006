<template>
    <div class="content-container" :style="{opacity: loaded ? 1 : 0}">

        <div class="content-arranger">
            <div id="event-headline">
                <div>aiXdesign.space/</div><div>stats</div>
            </div>
        </div>

        <div class="content-arranger">
            <div>Total users: {{ totalUsers }}</div>
            <div>Total answers: {{ totalAnswers }}</div>
            <div>Avg. answers per user: {{ avgAnswers }}</div>
        </div>

        <div class="content-arranger">
            <div class="headline">Users per answered questions</div>
            <table>
                <tr>
                    <th>Answered questions</th>
                    <th>Users</th>
                </tr>

                <tr v-for="key in Object.keys(stats.usersPerAnswerCount)" :key="key">
                    <td>{{ key }}</td>
                    <td>{{ stats.usersPerAnswerCount[key] }}</td>
                </tr>
            </table>
        </div>

        <div class="content-arranger">
            <div class="headline">New users</div>
            <table>
                <tr>
                    <th>Time interval</th>
                    <th>New users</th>
                </tr>

                <tr>
                    <td>7 days</td>
                    <td>{{ stats.newUsers7day }}</td>
                </tr>

                <tr>
                    <td>1 day</td>
                    <td>{{ stats.newUsers1day }}</td>
                </tr>
            </table>
        </div>

        <div class="content-arranger">
            <div class="headline">Answers per question</div>
            <table>
                <tr>
                    <th>Question</th>
                    <th>Answers</th>
                </tr>

                <tr v-for="obj in stats.answersPerQuestion" :key="obj.id">
                    <td>{{ obj.text }}</td>
                    <td>{{ obj.count }}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>

import Vue from "vue";
import API from "@/service/API.ts";

export default Vue.extend({
    name: "Stats",
    components: {},
    data() {
        return {
            stats: {},
            loaded: false,
        };
    },
    methods: {
        async loadEvents() {
            try {
                const resp = await fetch(API.getBaseUrl() + "/stats", {});
                const obj = await resp.json();

                this.stats = obj;
            } catch (err) {
                console.log(err);
            }

            setTimeout(() => {
                this.loaded = true;
            }, 200);
        }
    },
    computed: {
        totalUsers() {
            return Object.keys(this.stats.usersPerAnswerCount).reduce((p, c) => {
                return p + this.stats.usersPerAnswerCount[c];
            }, 0);
        },
        totalAnswers() {
            return Object.keys(this.stats.usersPerAnswerCount).reduce((p, c) => {
                return p + this.stats.usersPerAnswerCount[c] * parseInt(c);
            }, 0);
        },
        avgAnswers() {
            return (this.totalAnswers / this.totalUsers).toFixed(2);
        }
    },
    created() {
        this.loadEvents();
    }
})
</script>

<style scoped>
.content-container {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.headline-arranger {
    margin-bottom: 3rem;
    flex-direction: column;
}

@media screen and (max-width: 1000rem) {
    .content-arranger {
        width: 75%;
    }
}

@media screen and (max-width: 40rem) {
    .content-arranger {
        width: 85%;
    }
}

#event-headline {
    margin-top: 3rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    font-size: 3.8rem;
}

@media screen and (max-width: 70rem) {
    #event-headline {
        flex-direction: column;
    }
}

@media screen and (max-width: 40rem) {
    #event-headline {
        font-size: 3rem;
        line-height: 3.4rem;
    }
}

@media screen and (max-width: 30rem) {
    #event-headline {
        font-size: 2.2rem;
        line-height: 2.7rem;
    }
}

.headline {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
}

table {
    border-collapse: collapse;
}

th, td{
    border: 1px solid white;
    margin: 0;
    padding: 0.2rem;
}

th {
    padding: 0.5rem;
}


</style>
