<template>
    <div>
        <div id="cursorOverlay">
            <div @click="slideUp" class="cursor-up"></div>
            <div @click="slideUp" class="cursor-up"></div>
            <div @click="slideUp" class="cursor-up"></div>

            <div @click="slideLeft" class="cursor-left"></div>
            <div class="no-click"></div>
            <div @click="slideRight" class="cursor-right"></div>

            <div @click="slideDown" class="cursor-down"></div>
            <div @click="slideDown" class="cursor-down"></div>
            <div @click="slideDown" class="cursor-down"></div>
        </div>

        <main :style="{opacity: questionsVisible > 0 ? 1: 0}" @wheel="handleMainScroll">
            <div class="shadow" id="top-shadow"></div>
            <div class="shadow" id="bottom-shadow"></div>
            <div class="shadow" id="left-shadow"></div>
            <div class="shadow" id="right-shadow"></div>

            <hooper
                class="questions"
                style="width: 100%; height: 100%"
                :items-to-show="itemsToShow"
                :center-mode="true"
                :infinite-scroll="true"
                :keys-control="false"
                v-if="questions.length > 0"
                :mouse-drag="false"
                ref="mainCarousel"
                @afterSlide="onSlide"
                :wheel-control="false">

                <slide class="question-wrapper" v-for="(question, index) in $store.state.questions" :index="index" :key="question.question_id">
                    <VerticalCarousel
                        :answers="question.answers"
                        :is-submitted="question.submitted"
                        :question="$store.state.lang === 'de' ? question.question_de : question.question_en"
                        :custom-answer="question.userAnswer"
                        :current="verticalPosition"
                        :disableTransition="disableVerticalTransition"
                        :info-img-position="question.infoCardPosition"
                        :info-img-index="index"
                        @sendAnswer="ans => sendAnswer(index, ans)"
                        @clickOnCard="(data) => clickOnCard(index, data.index, data.event)"
                        @updateAnswer="text => question.userAnswer = text"
                    ></VerticalCarousel>

                </slide>
            </hooper>

            <div id="down-distance"></div>

            <Dialog ref="userDataDialog">

                <div class="content-sub-headline input-field-headline">{{ $t("submit_dialog_header") }}</div>

                <label class="input-field-text" for="modal-occ-cat-input">{{ $t("input1") }}</label>
                <select id="modal-occ-cat-input" class="input-field input-field-popup" v-model="user.occupationCategory">
                    <option value="Design">Design</option>
                    <option value="Kreativwirtschaft">Kreativwirtschaft</option>
                    <option value="Tech">Tech</option>
                    <option value="Weiteres">Weiteres</option>
                </select>

                <div
                    style=""
                    :style="{minHeight: occDetailMaxHeight, height: occDetailMaxHeight}"
                    id="occDetailContainer"
                    ref="occDetailContainer">
                    <label class="input-field-text" for="modal-occ-input">{{ $t("input2") }}</label>
                    <select id="modal-occ-input" class="input-field input-field-popup" v-model="user.occupation">
                        <option v-for="occ in occupations[user.occupationCategory]" :value="occ" :key="`${occ}`">{{occ}}</option>
                    </select>
                </div>

                <label class="input-field-text" for="modal-age-input">{{ $t("input3") }}</label>
                <input id="modal-age-input" class="input-field input-field-popup" type="number" placeholder="Alter" v-model="user.age">

                <label class="input-field-text" for="modal-country-input">{{ $t("input4") }}</label>
                <select id="modal-country-input" class="input-field input-field-popup" v-model="user.country">
                    <option v-for="country of countries" :key="country.alpha3" :value="country.alpha3">{{ $store.state.lang === "de" ? country.name : country.name_en }}</option>
                </select>

                <label class="input-field-text" for="modal-email-input">{{ $t("input5") }}</label>
                <input id="modal-email-input" class="input-field input-field-popup" type="email" placeholder="E-Mail" v-model="user.email">

                <div id="dseNote">{{ $t("dse_note") }}</div>

                <router-link id="dseLink" target="_blank" to="/about">{{ $t("privacy_policy") }}</router-link>

                <div class="submit-button-wrapper">
                <input @click="submitUserInfo()" :disabled="isUserSubmitDisabled()" class="submit-button submit-button-popup" type="submit" :value="$t('submit')">
                </div>

            </Dialog>

            <Dialog ref="verificationInfoDialog">
                <div class="content-sub-headline input-field-headline">{{ $t("verification") }}</div>
                <div>{{ $t("email_note") }}</div>

                <input @click="$refs.verificationInfoDialog.close()"
                       class="submit-button submit-button-popup" type="submit" value="OK">
            </Dialog>

            <Dialog ref="editInfoDialog">
                <div class="content-sub-headline input-field-headline">{{ $t("edit_note_header") }}</div>
                <div>{{ $t("edit_note") }}</div>

                <input @click="$refs.editInfoDialog.close()"
                       class="submit-button submit-button-popup" type="submit" value="OK">
            </Dialog>

            <Dialog ref="confirmDialog">
                <div class="content-sub-headline input-field-headline">{{ $t("confirm_dialog_header") }}</div>
                <div>{{ $t("confirm_dialog_text") }}</div>

                <input @click="$refs.confirmDialog.close()"
                       class="submit-button submit-button-popup" type="submit" value="OK">
            </Dialog>

            <img :style="{opacity: bgVisible ? 1 : 0}" ref="bgImg" id="bg-img" src="../assets/UXD.svg">
        </main>
    </div>
</template>

<script>

// @ is an alias

import Vue from 'vue';
import * as luxon from "luxon";
import { Hooper, Slide } from 'hooper';
import VerticalCarousel from "@/components/VerticalCarousel";
import 'hooper/dist/hooper.css';
import {COUNTRIES} from "../countries.ts";
import API from "@/service/API.ts";
import Dialog from "@/components/Dialog";

export default Vue.extend({
    name: 'Home',
    components: {
        Dialog,
        Hooper,
        Slide,
        VerticalCarousel
    },

    data() {
        return {
            user: {
                id: this.$store.state.user.id,
                age: this.$store.state.user.age,
                occupation: this.$store.state.user.occupation,
                occupationCategory: this.$store.state.user.occupationCategory,
                country: this.$store.state.user.country,
                email: this.$store.state.user.email,
                secret: this.$store.state.user.secret,
            },
            currentQuestionIndex: -1,
            horizontalPosition: 0,
            verticalPosition: 0,
            disableVerticalTransition: false,
            bgTimeout: null,
            bgVisible: true,
            hoverTimeouts: {},
            questionsVisible: false,
            countries: COUNTRIES,
            scrollDelta: 0,
            scrollDeltaTimeout: null,
            occupations: {
                Design: ["Grafik-Design", "UX-Design", "UI-Design", "Mode-Design", "Produkt-Design", "Textil-Design",
                    "Industrie-Design", "Motion-Design", "Web-Design", "App-Design", "Architektur"],
                Kreativwirtschaft: ["Projektmanager", "Account Manager", "Creative Director", "Texter", "Geschäftsführer"],
                Tech: ["Front-End-Entwickler", "Projektmanager", "Back-End-Entwickler", "Full-Stack-Entwickler", "Geschäftsführer", "QA",
                    "UX-Design", "Forschung"],
                Weiteres: ["Kunst, Kultur, Gestaltung / Design",
                    "Medien, Marketing, Kommunikation",
                    "IT, Computer, Informatik",
                    "Architektur, Bau, Vermessung",
                    "Mode, Textil",
                    "Soziales, Bildung",
                    "Öffentlicher Dienst",
                    "Recht, Sicherheit",
                    "Personal, Verwaltung",
                    "Landwirtschaft, Umwelt, Tiere, Pflanzen",
                    "Gesundheit, Pflege, Therapie, Pharma",
                    "Körperpflege, Schönheit",
                    "Naturwissenschaften",
                    "Freizeitwirtschaft, Sport",
                    "Hotellerie, Gastronomie, Tourismus",
                    "Versicherung",
                    "Wirtschaft, Finanzen",
                    "Vertrieb, Verkauf, Einkauf",
                    "Ingenieurswesen",
                    "Handwerk, Fertigung, Produktion",
                    "Technik, Elektronik, Maschienenbau",
                    "Verkehr, Logistik"]
            },
            occupationsEnglish: {

            },
            occDetailMaxHeight: 0,
            cardClickBlocked: false,
        }
    },
    mounted() {
        document.body.style.overflow = "hidden";
    },
    async created() {
        let tempQuestions = [];

        if (this.$route.query.verify) {
            try {
                await fetch(API.getBaseUrl() + "/verify/" + encodeURIComponent(this.$route.query.verify), {
                    method: "POST",
                    body: "lang=" + encodeURIComponent(this.$store.state.lang),
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Cache-Control': 'no-store'
                    }
                });

                this.$refs.confirmDialog.open();
                await this.$router.replace("/");
            } catch (err) {
                console.log(err);
            }
        }

        if (this.$route.query.token && this.$route.query.token !== this.user.secret) {
            try {
                const response = await fetch(API.getBaseUrl() + "/user/" + encodeURIComponent(this.$route.query.token), {
                    headers: {
                        'Cache-Control': 'no-store',
                    }
                });
                const newUser = await response.json();

                localStorage.clear();

                this.user = {
                    id: newUser.uid,
                    age: newUser.age,
                    occupation: newUser.occupation,
                    occupationCategory: newUser.occupationCategory,
                    country: newUser.country,
                    email: newUser.email,
                    secret: this.$route.query.token,
                };

                for (const ans of newUser.answers) {
                    localStorage[`question_${ans.question_id}_answer`] = ans.answer;
                }

                console.log("new user id: " + newUser.uid);

                this.$store.commit("updateUser", this.user);
            } catch (err) {
                console.error("User udpate error: ");
                console.error(err);
            }
        }

        if (this.$route.query.token) {
            this.$refs.editInfoDialog.open();
            await this.$router.replace("/");
        }

        try {
            const response = await fetch(API.getBaseUrl() + "/data", {
                headers: {
                    'Cache-Control': 'no-store'
                }
            });
            const text = await response.json();

            tempQuestions = text.map(obj => obj.question);

            const infoCardPosition = 5 + Math.floor(Math.random() * 10);

            for (const questionObj of text) {
                const question = questionObj.question;

                Vue.set(question, "answers", []);
                Vue.set(question, "answerPool", []);
                Vue.set(question, "userAnswer", localStorage[`question_${question.question_id}_answer`] || "");
                Vue.set(question, "submitted", !!localStorage[`question_${question.question_id}_answer`] || false);
                Vue.set(question, "currentSlide", 0);
                Vue.set(question, "infoCardPosition", infoCardPosition);

                questionObj.answers.sort((a, b) => {
                    return luxon.DateTime.fromISO(a.date).toMillis() - luxon.DateTime.fromISO(b.date);
                });

                for (const ans of questionObj.answers) {
                    const dateObj = luxon.DateTime.fromISO(ans.date);
                    if (dateObj.isValid) {
                        ans.dateText = dateObj.toLocaleString(luxon.DateTime.DATE_FULL);
                    } else {
                        ans.dateText = null;
                    }

                    question.answerPool.push(ans);
                }
            }

            this.resetVerticalPosition();

        } catch (err) {
            console.log(err);
            tempQuestions = ["Error!" + " " + err];
        }

        this.$store.commit("setQuestions", tempQuestions);
        this.$store.commit("setCurrentQuestion", tempQuestions[0]);

        this.applyFilter();
    },
    beforeDestroy() {
        if (this.bgTimeout) {
            clearTimeout(this.bgTimeout);
        }

        document.body.style.overflow = "initial";
    },
    computed: {
        filter() {
            return this.$store.state.filter;
        },
        questions() {
            return this.$store.state.questions;
        },
        itemsToShow() {
            if (window.innerWidth > 1300) {
                return 3.7;
            } else if (window.innerWidth > 1000) {
                return 2.5;
            } else if (window.innerWidth > 700) {
                return 1.5;
            } else {
                return 1;
            }
        }
    },
    watch: {
        questions(qs) {
            if (qs.length > 0) {
                setTimeout(() => this.questionsVisible = true, 400);
            }
        },
        "user.occupationCategory"(cat) {
            if (cat) {
                this.occDetailMaxHeight = 6.4 + "rem";
            } else {
                this.occDetailMaxHeight = 0;
            }
        },
        verticalPosition(pos) {
            if (pos === 10) {
                this.$store.commit("setInVerticalCenter", true);
            } else {
                this.$store.commit("setInVerticalCenter", false);
            }
        },
        filter() {
            this.applyFilter();
            this.resetVerticalPosition();
        }
    },
    methods: {
        handleMainScroll(event) {
            this.scrollDelta += event.deltaY;
            if (this.scrollDeltaTimeout) clearTimeout(this.scrollDeltaTimeout);
            this.scrollDeltaTimeout = setTimeout(() => {
                this.scrollDelta = 0;
                this.scrollDeltaTimeout = null;
            }, 800);

            if (this.scrollDelta > 300) {
                this.slideDown();
                this.scrollDelta = 0;
            } else if (this.scrollDelta < -300) {
                this.slideUp();
                this.scrollDelta = 0;
            }
        },
        applyFilter() {
            const filter = this.$store.state.filter;

            for (const question of this.$store.state.questions) {
                let filteredAnswers = question.answerPool.filter(ans => {
                    let countryMatch = true;
                    if (filter.countryFilter.length > 0) {
                        countryMatch = false;
                        for (const country of filter.countryFilter) {
                            if (ans.country === country) {
                                countryMatch = true;
                            }
                        }
                    }

                    let professionMatch = true;
                    if (filter.professionFilter.length > 0) {
                        professionMatch = false;

                        for (const prof of filter.professionFilter) {
                            if (ans.occupationCategory === prof) {
                                professionMatch = true;
                            }
                        }
                    }

                    let ageMatch = ans.age >= filter.ageRange[0] && ans.age <= filter.ageRange[1];

                    return countryMatch && professionMatch && ageMatch;
                });

                if (filteredAnswers.length >= 20) {
                    filteredAnswers = filteredAnswers.splice(0, 20);
                } else {
                    const reorderedAnswers = [];
                    let nextDestIndex = -1;
                    let middle = 10;

                    for (let i = 0; i < 20; i++) {
                        reorderedAnswers[middle + nextDestIndex] = filteredAnswers[i] || {
                            age: 0,
                            answer: "",
                            answer_de: "",
                            answer_en: "",
                            answer_id: -1,
                            country: "",
                            date: "2021-02-22T07:52:33.000Z",
                            dateText: "February 22, 2021",
                            lang: "de",
                            occupation: "",
                            occupationCategory: "",
                            question_id: question.id,
                            uid: -1,
                            hidden: true,
                        };

                        if (nextDestIndex < 0) {
                            nextDestIndex = -nextDestIndex - 1;
                        } else {
                            nextDestIndex = -nextDestIndex - 2;
                        }

                    }

                    filteredAnswers = reorderedAnswers;
                }

                question.answers = filteredAnswers;
                //console.log("Question " + question.question_id + " answers: " + question.answers.length);
            }
        },
        resetVerticalPosition() {
            this.verticalPosition = 10;
        },
        onSlide(data) {
            this.horizontalPosition = data.currentSlide;
            this.$store.commit("setCurrentQuestion", this.horizontalPosition);
        },
        slideUp() {
            if (this.cardClickBlocked) return;
            this.blockCardClickEvent();

            if (this.verticalPosition === 0) {
                this.verticalPosition = -1;

                setTimeout(() => {
                    this.verticalPosition = 20;
                    this.disableVerticalTransition = true;

                    setTimeout(() => this.disableVerticalTransition = false, 10);
                }, 220);
            } else {
                this.verticalPosition--;
            }
        },
        slideDown() {
            if (this.cardClickBlocked) return;
            this.blockCardClickEvent();

            if (this.verticalPosition === 20) {
                this.verticalPosition = 21;

                setTimeout(() => {
                    this.verticalPosition = 0;
                    this.disableVerticalTransition = true;

                    setTimeout(() => this.disableVerticalTransition = false, 10);
                }, 220);


            } else {
                this.verticalPosition++;
            }
        },
        slideLeft() {
            if (this.cardClickBlocked) return;
            this.blockCardClickEvent();

            this.$refs.mainCarousel.slidePrev();
            this.$store.commit("setCurrentQuestion", this.horizontalPosition === 0 ? this.questions.length - 1 : this.horizontalPosition - 1);
        },
        slideRight() {
            if (this.cardClickBlocked) return;
            this.blockCardClickEvent();

            this.$refs.mainCarousel.slideNext();
            this.$store.commit("setCurrentQuestion", this.horizontalPosition === this.questions.length - 1 ? 0 : this.horizontalPosition + 1);
        },
        blockCardClickEvent() {
            this.cardClickBlocked = true;
            setTimeout(() => this.cardClickBlocked = false, 500);
        },
        clickOnCard(horzIndex, vertIndex, event) {
            if (this.cardClickBlocked) return;

            if (horzIndex !== this.horizontalPosition) {
                if (event.clientX > window.innerWidth / 2) {
                    this.slideRight();
                } else {
                    this.slideLeft();
                }
            }

            if (vertIndex !== this.verticalPosition) {
                if (event.clientY > window.innerHeight / 2) {
                    this.slideDown();
                } else {
                    this.slideUp();
                }
            }
        },
        isUserSubmitDisabled() {
            return !this.hasValidUserData();
        },
        hasValidUserData() {
            return this.user.age && this.user.email && this.user.occupationCategory && this.user.occupation && this.user.country;
        },
        sendAnswer(index, ansText) {
            if (ansText !== undefined) {
                this.$store.state.questions[index].userAnswer = ansText;
            }

            if (!localStorage.userId || !this.hasValidUserData()) {
                console.log("No valid user data found");

                this.$refs.userDataDialog.open();
                this.currentQuestionIndex = index;
            } else {
                let body = [
                    `question_id=${this.$store.state.questions[index].question_id}`,
                    `answer=${encodeURIComponent(this.$store.state.questions[index].userAnswer)}`,
                    `user_id=${encodeURIComponent(localStorage.userId)}`,
                    `token=${encodeURIComponent(this.$store.state.user.secret)}`,
                ];

                fetch(API.getBaseUrl() + '/answer', {
                    method: 'POST',
                    body: body.join("&"),
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Cache-Control': 'no-store'
                    }
                })
                    .then(response => response.text())
                    .then(result => {
                        console.log('Success:', result);
                        this.$store.state.questions[index].submitted = true;
                        localStorage[`question_${this.$store.state.questions[index].question_id}_answer`] = this.$store.state.questions[index].userAnswer;
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            }
        },
        submitUserInfo() {
            let body = "age=" + encodeURIComponent(this.user.age)
                + "&email=" + encodeURIComponent(this.user.email)
                + "&country=" + encodeURIComponent(this.user.country)
                + "&occupation=" + encodeURIComponent(this.user.occupation)
                + "&lang=" + encodeURIComponent(this.$store.state.lang)
                + "&occupationCategory=" + encodeURIComponent(this.user.occupationCategory);

            fetch(API.getBaseUrl() + '/user', {
                method: 'POST',
                body: body,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Cache-Control': 'no-store'
                }
            })
                .then(response => response.json())
                .then(result => {
                    console.log('Success:', result);
                    this.user.id = result.userId;
                    this.user.secret = result.secret;

                    this.$store.commit("updateUser", this.user);

                    this.$refs.userDataDialog.close();
                    setTimeout(() => this.$refs.verificationInfoDialog.open(), 500);

                    return this.sendAnswer(this.currentQuestionIndex);
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        },

        onMitmachen() {
          if (this.$router.currentRoute.path === "/") {
            this.resetVerticalPosition();
          }
        }
    },

});
</script>

<style scoped>

#root {
    height: 100vh;
}

html, body {
    overflow: hidden;
}

#occDetailContainer {
    transition: 0.2s ease-in-out;
    overflow: hidden;
    width: 100%;
    min-height: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#cursorOverlay {
    position: absolute;
    top: 7.9rem;
    left: 0;
    width: 100vw;
    height: calc(100vh - 7.9rem);

    display: grid;
    grid-template-rows: 150px auto 150px;
    grid-template-columns: 20vw auto 20vw;
}

@media screen and (max-width: 1000px) {
    #cursorOverlay {
        display: none;
    }
}

#cursorOverlay > div {
    z-index: 10;
}

#cursorOverlay > div.no-click {
    pointer-events: none;
    z-index: 0;
}

.cursor-up {
    cursor: url(../assets/arrow-up.svg), auto;
}

.cursor-down {
    cursor: url(../assets/arrow-down.svg), auto;
}

.cursor-left {
    cursor: url(../assets/arrow-left.svg), auto;
}

.cursor-right {
    cursor: url(../assets/arrow-right.svg), auto;
}

#down-distance {
    width: 100%;
    margin-bottom: 5rem;
}


#top-shadow {
    top: 6rem;
    position: fixed;
    width: 100%;
    height: 6rem;
    background-image: linear-gradient(rgba(0,0,0,1), rgba(0,0,0,0));
    z-index: 4;
    pointer-events: none;
}

#bottom-shadow {
    bottom: 0;
    position: fixed;
    width: 100%;
    height: 6rem;
    background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1));
    z-index: 4;
    pointer-events: none;
}

#left-shadow {
    left: 0;
    top: 0;
    position: fixed;
    height: 100%;
    width: 27rem;
    background-image: linear-gradient(to right, rgba(0,0,0,8), rgba(0,0,0,0));
    z-index: 4;
    pointer-events: none;
}

#right-shadow {
    right: 0;
    top: 0;
    position: fixed;
    height: 100%;
    width: 27rem;
    background-image: linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,8));
    z-index: 4;
    pointer-events: none;
}

@media screen and (max-width: 1000px) {
    .shadow {
        display: none;
    }
}

#content-position {
    margin-bottom: 8.5rem;
    width: 100%;
}

.question-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 2;
}

.send-interview-button {
    padding: 0.5rem 0.6rem 0.4rem 0.8rem;
    margin-bottom: 9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18rem;
    max-width: 100%;
    height: 2.8rem;
    font-size: 1.1rem;
    text-transform: uppercase;
    border: 0.13rem solid #0000ff;
    background-color: #0000ff;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.4s ease 0s;
    color: white;
    z-index: 5;
}

.send-interview-button:hover {
    background-color: black;
    color: #0000ff;
}

.hooper {
    padding-top: .5rem;
    padding-bottom: .5rem;
    min-width: 100%;
    margin: 0;
    height: 100%;
}

main {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

button {
    padding: 1rem 1.8rem;
    margin-top: 1rem;
    opacity: 1;
    transition: opacity ease-in-out 0.2s, background-color ease-in-out .2s;
    background-color: rgb(15, 124, 255);
    color: white;
    border-radius: 2.5rem;
    position: absolute;
    right: 10%;
    font-size: 1.8rem;
    border: none;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

button:hover {
    background-color: black;
}

button.hidden {
    opacity: 0;
}

#dseLink {
    margin-top: 1rem;
    margin-bottom: 1rem;
    align-self: start;
    font-size: 1.1rem;
    cursor: pointer;
    text-decoration: underline;
}

</style>

<i18n>
{
    "en": {
        "opinion": "Your opinion?",
        "email_note": "We sent you an email. Please click the link in the email to activate your answers and to change them later.",
        "dse_note": "By clicking the 'Submit' button, you agree to the publication of your details (with the exception of your email address) and your answers under license CC0 (free re-use by others) on this website. You can revoke your consent at any time with effect for the future.",
        "submit_dialog_header": "We ask you for some general information.",
        "verification": "Verification",
        "privacy_policy": "Privacy policy",
        "input1": "What field do you work in? ",
        "input2": "Please specify",
        "input3": "How old are you?",
        "input4": "Which country do you come from?",
        "input5": "What's your email?",
        "submit": "Submit",
        "edit_note": "You can edit your answers here by clicking the edit button in the cards",
        "edit_note_header": "How to edit your answers",
        "confirm_dialog_header": "Confirmation",
        "confirm_dialog_text": "Thank you for your registration! Keep our confirmation email. Through the link contained therein, you can edit your entries and unsubscribe from our newsletter at any time."
    },
    "de": {
        "opinion": "Deine Meinung?",
        "email_note": "Wir haben dir eine E-Mail geschickt. Bitte klicke den Link in der E-Mail, um deine Antworten freizuschalten und später zu wieder zu verändern.",
        "dse_note": "Mit Anklicken des Buttons 'Senden' erklärst du dich mit der Veröffentlichung deiner Angaben (mit Ausnahme deiner E-Mail-Adresse) und deiner Antworten unter der Lizenz CC0 (freie Weiternutzung durch andere) auf dieser Website einverstanden. Deine Einwilligung kannst du jederzeit mit Wirkung für die Zukunft widerrufen.",
        "submit_dialog_header": "Wir bitten dich noch um einige allgemeine Angaben.",
        "verification": "Verifizierung",
        "privacy_policy": "Datenschutzerklärung",
        "input1": "In welchem Feld arbeitest du?",
        "input2": "Als was genau?",
        "input3": "Wie alt bist du?",
        "input4": "Aus welchem Land kommst du?",
        "input5": "Wie lautet deine E-Mail?",
        "submit": "Senden",
        "edit_note_header": "Antworten bearbeiten",
        "edit_note": "Du kannst hier deine Antworten bearbeiten, indem du auf den Bearbeiten-Button in den Cards klickst",
        "confirm_dialog_text": "Vielen Dank für Deine Registrierung! Bewahre unsere Bestätigungs-Email auf. Durch den darin enthaltenen Link kannst Du jederzeit Deine Einträge bearbeiten und dich von unserem Newsletter abmelden.",
        "confirm_dialog_header": "Bestätigung"
    }
}
</i18n>
