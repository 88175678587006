<template>
<h1>Not Found</h1>
</template>

<script>
export default {
    name: "NotFound"
}
</script>

<style scoped>

h1 {
    color: white;
}

</style>
